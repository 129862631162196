<template>
  <v-dialog overflow-hidden v-model="apnListDialog" persistent max-width="600px" scrollable>
    <DialogCard :modalIcon="'radio-tower'" :modalTitle="'APN List'">
      <template #header>
        <v-btn icon x-small @click="close_apn_list_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <v-text-field ref="profileName" class="form-text-box" outlined dense label="Profile Name" placeholder="Profile Name" v-model="profileName" :rules="mixinCommonNameRule()" autocomplete="new-password"></v-text-field>

          <v-text-field ref="apnName" class="form-text-box" outlined dense label="APN Name" placeholder="APN Name" v-model="apnName" :rules="mixinApnNameRule()" autocomplete="new-password"></v-text-field>

          <v-select ref="pdpType" class="form-text-box" outlined dense label="PDP Type" :items="mixinGetPDPList()" v-model="pdpType"></v-select>

          <v-select ref="authType" class="form-text-box" outlined dense label="Auth Type" :items="mixinGetAuthList()" v-model="authType"></v-select>

          <v-text-field ref="username" class="form-text-box" outlined dense label="Username" placeholder="Username" v-model="username" autocomplete="new-password"></v-text-field>

          <v-text-field ref="password" class="form-text-box" outlined dense label="Password" placeholder="Password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" v-model="password" autocomplete="new-password"></v-text-field>

          <v-text-field ref="inactiveTime" class="form-text-box" outlined dense label="Inactive Time" placeholder="Inactive Time" v-model="inactiveTime" type="number" :rules="mixinInactiveTimeRule()" autocomplete="new-password" hide-spin-buttons></v-text-field>

          <v-select ref="ipAllocation" class="form-text-box" outlined dense label="IP Allocation" :items="mixinGetIPList()" v-model="ipAllocation"></v-select>

        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_apn_list_dialog()">Cancel</v-btn>
        <v-btn small @click="save_apn_list()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js'
import { mapMutations } from "vuex";
import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';

export default {
  mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
  components: {
    DialogCard,
  },
  props: {
    apnListDialog: Boolean,
    apnListDialogMode: Object,
    apnList: Array
  },

  data() {
    return {
      profileName: "",
      apnName: "",
      pdpType: 1,
      authType: 0,
      username: "",
      password: "",
      inactiveTime: 0,
      ipAllocation: 0,

      showPassword: false
    };
  },
  watch: {
    apnListDialog: {
      handler(newVal) {
        if (newVal) {
          this.initApnListDialog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initApnListDialog() {
      const mode = this.apnListDialogMode.mode;
      const item = this.apnListDialogMode.item;

      if(mode === "add"){
        this.profileName = "";
        this.apnName = "";
        this.pdpType = 1;
        this.authType = 0;
        this.username = "";
        this.password = "";
        this.inactiveTime = 0;
        this.ipAllocation = 0;
      }
      else if(mode === "edit") {
        this.profileName = this.mixinIsDefinedAndNotNull(item.ProfileName) ? item.ProfileName : "";
        this.apnName = this.mixinIsDefinedAndNotNull(item.APNName) ? item.APNName : "";
        this.pdpType = this.mixinIsDefinedAndNotNull(item.PDPType) ? item.PDPType : 1;
        this.authType = this.mixinIsDefinedAndNotNull(item.AuthType) ? item.AuthType : 0;
        this.username = this.mixinIsDefinedAndNotNull(item.UserName) ? item.UserName : "";
        this.password = this.mixinIsDefinedAndNotNull(item.Password) ? item.Password : "";
        this.inactiveTime = this.mixinIsDefinedAndNotNull(item.InactiveTime) ? item.InactiveTime : 0;
        this.ipAllocation = this.mixinIsDefinedAndNotNull(item.IPAllocation) ? item.IPAllocation : 0;
      }

    },
    save_apn_list() {
      if(!(this.$refs.form && this.$refs.form.validate())){
        this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
        return;
      }
      const mode = this.apnListDialogMode.mode;
      const item = {
        Index: mode === "add" ? (this.apnList.length + 1) : this.apnListDialogMode.item.Index,
        Active: mode === "add" ? 0 : this.apnListDialogMode.item.Active,
        ProfileName: this.profileName,
        APNName: this.apnName,
        PDPType: this.pdpType,
        AuthType: this.authType,
        InactiveTime: this.inactiveTime,
        IPAllocation: this.ipAllocation
      }

      if(this.username) {
        item.UserName = this.username;
      }

      if(this.password) {
        item.Password = this.password;
      }

      const dialogObj = {
        status: 1,
        mode: mode,
        item: item
      }
      if(mode === "edit") dialogObj.index = this.apnListDialogMode.index;
      
      // Set item to send back to ApnInfo.vue component for edit or add
      this.$emit("close", dialogObj);
    },
    //method invokes on close actions
    close_apn_list_dialog() {
      this.$emit("close", { status: 0 });
    },
  },
};
</script>