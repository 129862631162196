import { render, staticRenderFns } from "./BarChartCard.vue?vue&type=template&id=10b676ed&scoped=true&"
import script from "./BarChartCard.vue?vue&type=script&lang=js&"
export * from "./BarChartCard.vue?vue&type=script&lang=js&"
import style0 from "./BarChartCard.vue?vue&type=style&index=0&id=10b676ed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b676ed",
  null
  
)

export default component.exports