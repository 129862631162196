const state = {
  initialAdCheck: true
}

const getters = {
  // This is used for both dashboard info and router count
  getterGetInitialAdCheck: (state) => {
      return state.initialAdCheck;
  }
}

const mutations = {
  mutationSetInitialAdCheck: (state, payload) => {
      state.initialAdCheck = payload;
  }
}

export default {
  state,
  getters,
  mutations
}