<template>
  <div style="display: grid; height: 100%">
    <v-card-text class="pa-6">
      <v-form ref="form">
        <div v-if="licenseInformation" style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 1rem">

          <p><b>License Name</b></p>
          <p>{{ licenseInformation.license_name }}</p>

          <p><b>License Type</b></p>
          <p>{{ licenseInformation.license_type }}</p>

          <p><b>License Period</b></p>
          <p>{{ licenseInformation.license_period }} month{{ licenseInformation.license_period > 1 ? "s" : "" }}</p>

          <p><b>Days of Notification</b></p>
          <p>{{ licenseInformation.days_of_notification }}</p>

          <p><b>Router</b></p>
          <p>{{ licenseInformation.router_id }}</p>

          <p><b>License Key</b></p>
          <p>{{ licenseInformation.license_key }}</p>

        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-spacer></v-spacer>
      <v-btn class="button tabledata--text" @click="open_end_sign_up_dialog()">Done</v-btn>
    </v-card-actions>
    <EndSignUpDialog :endSignUpDialog="endSignUpDialog" @close="close_end_sign_up_dialog" />
  </div>
</template>

<script>
import EndSignUpDialog from "@/components/SignUp/EndSignUpDialog.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    EndSignUpDialog
  },
  data() {
    return {
      endSignUpDialog: false
    };
  },
  computed: {
    ...mapGetters(["getterGetLicenseInformation"]),
    licenseInformation: {
      get(){
        return this.getterGetLicenseInformation;
      }
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    ...mapActions(["actionResetSignUpInfo"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    open_end_sign_up_dialog() {
      this.endSignUpDialog = true;
    },
    close_end_sign_up_dialog() {
      this.endSignUpDialog = false;
    },
  },
};
</script>

<style scoped>
</style>
