<template>
  <v-dialog overflow-hidden v-model="resetConfigDialog" persistent :max-width="deviceResetList.length && deviceResetList[0].errorReason ? '700px' : '600px'">
    <DialogCard v-if="resetConfigDialog" :modalIcon="'cog-refresh'" :modalTitle="'Reset Device Configuration'">
      <template #header>
        <v-btn icon x-small @click="close_reset_config_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <p class="py-2 red--text font-weight-bold">※ Configuration reset will revert device settings to its default values. Note that this is not allowed for devices that belong to a device group.</p>
        <div class="reset-table">
          <v-row class="grey darken-2 white--text py-2 gx-3" no-gutters>
            <v-col class="pl-4" :cols="deviceResetList[0].errorReason ? 4 : 6">Device Name</v-col>
            <v-col :cols="deviceResetList[0].errorReason ? 4 : 6">Device Serial Number</v-col>
            <v-col v-if="deviceResetList[0].errorReason" cols="4">Fail Reason</v-col>
          </v-row>
          <div class="scrollable">
            <div v-for="device in deviceResetList" :key="device.router_id">
              <v-row class="py-2 gx-3" no-gutters>
                <v-col class="pl-4" :cols="device.errorReason ? 4 : 6">{{ device.router_name }}</v-col>
                <v-col class="font-weight-light" :cols="device.errorReason ? 4 : 6">{{ device.router_id }}</v-col>
                <v-col class="red--text text-darken-1" v-if="device.errorReason" cols="4">{{ device.errorReason }}</v-col>
              </v-row>
              <v-divider class="divider"></v-divider>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_reset_config_dialog()">Cancel</v-btn>
        <v-btn small :loading="loadingResetConfirm" @click="confirm_config_reset()" class="button tabledata--text mr-2">{{ deviceResetList[0].errorReason ? "Try Reset Again" : "Confirm Reset" }}</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { resetRouterConfigs } from "@/services";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    DialogCard,
  },
  props: {
    resetConfigDialog: Boolean,
    selectedRecords: Array,
  },
  data() {
    return {
      deviceResetList: [],
      loadingResetConfirm: false,
    };
  },
  watch: {
    resetConfigDialog: {
      handler(newVal) {
        // fill in with new list
        // the array should be mapped with spread operator per each item to copy the item and avoid direct refrencing
        if (newVal) {
          this.deviceResetList = [];
          this.deviceResetList = this.selectedRecords.map((record) => ({ ...record }));
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
    getUserId() {
      return this.getterGetUserInfo.user.user_id;
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    close_reset_config_dialog() {
      this.$emit("close");
    },
    async confirm_config_reset() {
      try {
        this.loadingResetConfirm = true;
        const response = await resetRouterConfigs({
          requester: this.getUserId,
          routers: this.filterResetDevices(),
        });
        this.finalizeReset(response);
      } catch (error) {
        this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
      } finally {
        this.loadingResetConfirm = false;
      }
    },
    filterResetDevices() {
      return this.deviceResetList.map((device) => {
        return device.router_id;
      });
    },
    finalizeReset(response) {
      const responseMessage = response.message;
      if (responseMessage === "Failure" || responseMessage === "Partial success") {
        // filter out all devices that have failed to reset from the response and create a new array
        let resetFailedDevices = response.output.filter((device) => !device.result).map((device) => device.id);
        // find out if all have failed to reset or partially failed to reset
        responseMessage === "Failure" ? this.triggerSnackBar("error", "All devices failed to reset") : this.triggerSnackBar("warning", "Some devices failed to reset");
        // filter out devices that successfully reset from the original reset list
        this.deviceResetList = this.deviceResetList.filter((device) => {
          return resetFailedDevices.some((deviceId) => deviceId === device.router_id);
        });
        // Add in reasons for failed reset per item
        this.deviceResetList.forEach((device) => {
          const failedReason = response.reason.find((item) => item.id === device.router_id);
          device.errorReason = failedReason.note;
        });
      } else if (responseMessage === "Success") {
        this.triggerSnackBar("success", "All devices you've selected has been reset");
        this.close_reset_config_dialog();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-table {
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  overflow: hidden;
}
.scrollable {
  height: 400px;
  overflow-y: scroll;
}

.divider {
  border-top: 1px solid #bdbdbd;
}
</style>
