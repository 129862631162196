<template>
  <Teleport to="#app">
    <v-slide-x-transition>
      <div v-show="impersonationPanel" ref="impersonationPanel" class="impersonation-panel">
        <v-card color="#262626">
          <v-card-title class="impersonation-history">
            <v-icon color="white" class="mr-2">mdi-account-switch</v-icon>
            <span class="font-weight-dark white--text">Impersonation History</span>
            <v-spacer></v-spacer>
            <v-btn icon x-small @hover.stop @click="close_impersonation_panel">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="impersonation-content-wrapper">
              <div class="impersonation-content">
                <transition-group name="fade">
                  <div class="impersonation-list mb-2" name="fade" key="root">
                    <v-chip class="ma-2 white--text" color="green" @click="actionEndImpersonation(); close_impersonation_panel();">
                      <v-icon left color="white">mdi-{{ getIconByRole("Root") }}</v-icon>
                      {{ getterGetOriginalUserEmail }}
                    </v-chip>
                    <v-icon color="white" class="mx-1">mdi-chevron-right</v-icon>
                  </div>
                  <div class="impersonation-logs" name="fade" v-for="(item, index) in getterGetImpersonationArray" :key="item.id">
                    <div class="impersonation-list mb-2">
                      <v-chip class="ma-2 white--text" :color="getColorByIndex(index)" @click="actionBackTrackImpersonation({ id: item.id, backPath: item.backPath })">
                        <v-icon left color="white">mdi-{{ getIconByRole(item.userInfo.user.user_role) }}</v-icon>
                        {{ item.userEmail }}
                      </v-chip>
                      <v-icon v-if="index !== getterGetImpersonationArray.length - 1" color="white" class="mx-1">mdi-chevron-right</v-icon>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-x-transition>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Teleport from "vue2-teleport";

export default {
  components: {
    Teleport,
  },
  props: {
    impersonationPanel: Boolean,
  },
  mounted() {
    this.makeDialogMovable();
  },
  beforeDestroy() {
    this.removeDialogMovableEventListeners();
  },
  watch: {
    impersonationPanel: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.screenResizeHandler();
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetImpersonationArray", "getterGetOriginalUserEmail"]),
  },
  methods: {
    ...mapActions(["actionBackTrackImpersonation", "actionEndImpersonation"]),
    close_impersonation_panel() {
      this.$emit("close");
    },
    // Script below makes the dialog movable
    makeDialogMovable() {
      const d = {};

      const mouseDownHandler = (event) => {
        if (!this.$refs.impersonationPanel) return;
        const closestDialog = this.$refs.impersonationPanel;
        if (event.button === 0 && closestDialog != null && event.target.classList.contains("impersonation-history")) {
          d.el = closestDialog;
          d.mouseStartX = event.clientX;
          d.mouseStartY = event.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      };

      const mouseMoveHandler = (event) => {
        if (!d.el) return;
        d.el.style.left = Math.min(Math.max(d.elStartX + event.clientX - d.mouseStartX, 0), window.innerWidth - d.el.getBoundingClientRect().width) + "px";
        d.el.style.top = Math.min(Math.max(d.elStartY + event.clientY - d.mouseStartY, 0), window.innerHeight - d.el.getBoundingClientRect().height) + "px";
      };

      const mouseUpHandler = () => {
        if (!d.el) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      };

      document.addEventListener("mousedown", mouseDownHandler);
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      window.addEventListener("resize", this.screenResizeHandler);

      this.removeDialogMovableEventListeners = () => {
        document.removeEventListener("mousedown", mouseDownHandler);
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
        window.removeEventListener("resize", this.screenResizeHandler);
      };
    },
    screenResizeHandler() {
      const dialog = this.$refs.impersonationPanel;
      if (dialog) {
        dialog.style.left = `${window.innerWidth - 820}px`;
        dialog.style.top = "10px";
      }
    },
    getColorByIndex(number) {
      const colors = ["red", "blue", "purple", "orange", "gray", "pink"];
      const index = Math.abs(number) % 6;
      return colors[index];
    },
    getIconByRole(role) {
      const icons = {
        Root: "home-account",
        SU_ADMIN: "account-star", // Super Admin
        C_ADMIN: "account-key", // Admin
        C_ADMIN_1: "account-supervisor", // Org Admin
        SP_ADMIN: "account-question", // Support
        P_ADMIN: "handshake", // Partner
        G_M_ADMIN: "account-group", // Group Manager
        INDIVIDUAL: "account",
      };
      return icons[role];
    },
  },
};
</script>

<style scoped>
.impersonation-panel {
  display: block;
  position: fixed;
  z-index: 9999;
}

.impersonation-history {
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 300ms ease-in-out;
}
.impersonation-history:hover {
  background-color: rgb(64, 64, 64);
  transition: background 300ms ease-in-out;
}
.impersonation-content-wrapper {
  height: 150px;
  width: 750px;
}
.impersonation-content {
  box-sizing: border-box;
  position: absolute;
  padding: 1rem;
  height: 150px;
  width: 750px;
  overflow-y: auto;
}
.impersonation-content > * {
  display: flex;
  flex-wrap: wrap;
}
</style>
