<template>
  <v-card flat class="parent-card">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <div ref="canvasContainer" class="canvas-container mb-5" :style="{ height: mergedOptions.height }">
        <canvas ref="canvas" @mousemove="handleMouseMove" @mouseleave="tooltip.show = false" @click="handleClick"></canvas>
        <div v-if="tooltip.show" class="tooltip" :style="{ top: tooltip.y + 'px', left: tooltip.x + 'px' }">
          <div class="d-flex align-center mb-1"><span class="tooltip-color-mark mr-1" :style="{ backgroundColor: tooltip.color }"></span>{{ tooltip.subItemName }}</div>
          <div class="d-flex justify-end align-baseline">
            <span class="mr-1" style="font-size: 1.2rem; font-weight: 600">{{ tooltip.count }}</span> Devices
          </div>
        </div>
      </div>
      <div class="pl-5 grey--text text--darken-1 text-caption">Latest Version By Devices: </div>
      <div class="legend-container">
        <div v-for="(item, index) in legendArray" :key="item.legendName + index">
          <div class="legend-item">
            <div class="legend-color" :style="{ backgroundColor: item.color }"></div>
            <div class="legend-label">{{ item.legendName }}</div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-fade-transition>
      <div v-if="loading" class="load-cover">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
    </v-fade-transition>
  </v-card>
</template>

<script>
// Chart js does not support the necessary tools to fit the current requirement and therefore, this component is made from vanilla javascript and HTML canvas element
export default {
  props: {
    title: String,
    data: Object,
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: {
      handler(){
        this.resizeCanvas();
      }
    }
  },
  data() {
    return {
      tooltip: {
        show: false,
        text: "",
        x: 0,
        y: 0,
      },
      tooltipData: [],
      legendArray: [],

      colorMap: {}, // Stores generated colors
      bars: [], // Stores bar positions for hover/click detection
      hoveredBar: null // Stores currently hovered bar
    };
  },
  computed: {
    mergedOptions() {
      return {
        width: this.options.width ? this.options.width : "100%",
        height: this.options.height ? this.options.height : "auto",
        fontSize: this.options.fontSize ? this.options.fontSize : 14,
        fontFamily: this.options.fontFamily ? this.options.fontFamily : "Roboto",
        barHeight: this.options.barHeight ? this.options.barHeight : 30,
        barSpacing: this.options.barSpacing ? this.options.barSpacing : 15,
        blockPadding: this.options.blockPadding ? this.options.blockPadding : 0,
        inlinePadding: this.options.inlinePadding ? this.options.inlinePadding : 10,
        labelSpace: this.options.labelSpace ? this.options.labelSpace : 70
      };
    },
  },
  mounted() {
    this.resizeCanvas(); // Initial setup
    window.addEventListener("resize", this.resizeCanvas);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    resizeCanvas() {
      const parent = this.$refs.canvasContainer;
      const canvas = this.$refs.canvas;
      if (!canvas) return;

      // Handle width
      if (typeof this.mergedOptions.width === "number") {
        canvas.width = this.mergedOptions.width;
      } else if (typeof this.mergedOptions.width === "string" && this.mergedOptions.width.includes("%")) {
        canvas.width = (parseFloat(this.mergedOptions.width) / 100) * parent.getBoundingClientRect().width;
      }

      let canvasHeight = Object.keys(this.data).length * (this.mergedOptions.barHeight + this.mergedOptions.barSpacing) + this.mergedOptions.blockPadding * 2;
      canvas.height = canvasHeight;

      this.drawChart();
    },
    drawChart() {
      // empty out array when rerendering
      this.tooltipData = [];
      this.legendArray = [];

      const parent = this.$refs.canvasContainer;
      const canvas = this.$refs.canvas;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");

      // initialize Canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const itemList = Object.keys(this.data);
      let yOffset = this.mergedOptions.blockPadding;

      itemList.forEach((itemName, index) => {
        const subItemList = this.data[itemName];
        // Start bars after labels. 이건 혹시나 바꿀 의양이 있으면 다음 사람이 알아서... 동적 계산은 복잡해서 안함
        let xOffset = this.mergedOptions.inlinePadding + this.mergedOptions.labelSpace;
        // const baseColor = this.generateRowColors()[itemName];
        const baseColor = this.generateRowColors(index);

        // Draw Device Model Label
        ctx.fillStyle = "#000";
        ctx.font = `${this.mergedOptions.fontSize}px ${this.mergedOptions.fontFamily}`;
        ctx.fillText(itemName, this.mergedOptions.inlinePadding, yOffset + this.mergedOptions.barHeight / 2 + this.mergedOptions.fontSize / 2);

        // Get item total count for width calculation
        // Set row bar length. All bar length are the same
        const itemTotalCount = Object.values(subItemList).reduce((acc, val) => (acc += val), 0);
        const rowBarLength = parent.getBoundingClientRect().width - (this.mergedOptions.labelSpace + (2 * this.mergedOptions.inlinePadding));

        let shadeIndex = 0;
        const subItemKeys = Object.keys(subItemList);

        // Draw bar
        Object.entries(subItemList).forEach(([subItemName, count]) => {
          const subItemWidth = (count / itemTotalCount) * rowBarLength;
          const color = this.getShade(baseColor, shadeIndex, subItemKeys.length);

          ctx.fillStyle = color;
          ctx.fillRect(xOffset, yOffset, subItemWidth, this.mergedOptions.barHeight);

          this.legendArray.push({ color, legendName: subItemName });

          let tooltip = { itemName, subItemName, count, color, x: xOffset, y: yOffset, width: subItemWidth, height: this.mergedOptions.barHeight };
          // Store bar position for tooltip
          this.tooltipData.push(tooltip);

          xOffset += subItemWidth; // Move to next bar position
          shadeIndex++;
        });

        yOffset += this.mergedOptions.barHeight + this.mergedOptions.barSpacing; // Move to next row
      });
    },

    handleMouseMove(event) {
      const rect = this.$refs.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      let foundTooltip = false;

      this.tooltipData.forEach(({ itemName, subItemName, count, color, x, y, width, height }) => {
        if (mouseX >= x && mouseX <= x + width && mouseY >= y && mouseY <= y + height) {
          this.tooltip = {
            show: true,
            itemName,
            subItemName,
            count,
            color,
            x: event.clientX + 10,
            y: event.clientY + 10,
          };
          foundTooltip = true;
        }
      });

      if (!foundTooltip) this.tooltip.show = false;
    },
    handleClick(event) {
      if (!this.tooltip.show) return;
      this.$emit("click", {
        itemName: this.tooltip.itemName,
        subItemName: this.tooltip.subItemName,
        count: this.tooltip.count
      });
    },
    generateRowColors(index) {
      const colorPalette = ["#598FF9", "#9BBCFB", "#6C61EA", "#B5AFF4", "#D71C7A", "#E777AF", "#FA5918", "#FC9B74", "#FCAB2E", "#FDD496"];
      return colorPalette[index % colorPalette.length];
    },
    getShade(baseColor, shadeIndex, totalShades) {
      const [r, g, b] = this.hexToRgb(baseColor);
      const factor = (totalShades / 2 - shadeIndex) * 0.1; // Adjust brightness
      return `rgb(${Math.min(255, r + r * factor)}, ${Math.min(255, g + g * factor)}, ${Math.min(255, b + b * factor)})`;
    },
    hexToRgb(hex) {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    },
  },
};
</script>

<style scoped>
.parent-card {
  border: solid 1px #e5e5ea;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.canvas-container {
  max-height: 150px;
  min-height: 150px;
  padding-block: 1rem;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.tooltip {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  white-space: nowrap;
  min-width: 130px;
  z-index: 9999;
}
.tooltip-color-mark {
  display: inline-block;
  width: 0.75rem;
  border-radius: 50%;
  aspect-ratio: 1;
}
.legend-container {
  margin: 0.75rem 1rem 1rem 1rem;
  max-height: 65px;
  display: grid;
  justify-content: center;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.legend-item {
  display: flex;
  align-items: center;
  width: fit-content;
}
.legend-color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.5rem;
}
.legend-label {
  font-size: 0.75rem;
  width: fit-content;
}

/* Chrome, Safari */
.legend-container::-webkit-scrollbar,
.canvas-container::-webkit-scrollbar {
  display: none;
}

.load-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  display: grid;
  place-items: center;
}
</style>
