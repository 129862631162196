export const list_all_organization = /* GraphQL */ `
  query List_all_organization($input: ListAllOrganizationInput) {
    list_all_organization(input: $input)
  }
`;

export const get_all_product_model = /* GraphQL */ `
  query Get_all_product_model($input: GetAllProductModelInput) {
    get_all_product_model(input: $input)
  }
`;