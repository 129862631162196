import Vue from 'vue';

const state = {
  formValidationStates: {}
}

const mutations = {
  mutationUpdateFormValidation(state, { formId, valid }) {
    Vue.set(state.formValidationStates, formId, valid);
    // console.log("From validation.js store");
    // console.log("validation states: ")
    // console.log(state.formValidationStates);
  },
  mutationResetFormValidation: (state) => {
    state.formValidationStates = {};
  }
}

const getters = {
  getterFormIsAllValid(state){
    return Object.values(state.formValidationStates).every(value => value);
  },
  getterGetValidationStates(state){
    return state.formValidationStates;
  }
}

export default {
  state,
  mutations,
  getters
}