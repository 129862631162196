<template>
  <div class="d-flex flex-row align-center">
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 23 36" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M9.77415 0C9.13734 0 8.62109 0.516244 8.62109 1.15306V2.60097C8.62109 2.68425 8.62992 2.76548 8.6467 2.84375H2.30612C1.03249 2.84375 0 3.87624 0 5.14987V33.6962C0 34.9698 1.03249 36.0023 2.30612 36.0023H20.6939C21.9675 36.0023 23 34.9698 23 33.6962V5.14987C23 3.87624 21.9675 2.84375 20.6939 2.84375H14.3449C14.3617 2.76548 14.3705 2.68425 14.3705 2.60097V1.15306C14.3705 0.516243 13.8542 0 13.2174 0H9.77415ZM2.30612 4.34375H20.6939C21.1391 4.34375 21.5 4.70466 21.5 5.14987V33.6962C21.5 34.1414 21.1391 34.5023 20.6939 34.5023H2.30612C1.86091 34.5023 1.5 34.1414 1.5 33.6962V5.14987C1.5 4.70466 1.86091 4.34375 2.30612 4.34375Z"
        fill="#BEBFC2" />
      <g :transform="batteryTransform">
        <rect x="3" y="5" width="17" :height="batteryHeight" rx="1" ry="1" :fill="batteryColor" />
      </g>
    </svg>
    <h1 class="ml-6">
      {{ deviceBatteryLevel }}%
    </h1>
  </div>
</template>

<script>

const normalColor = "#0195D2";
const warnColor = "#EFAF13";
const alertColor = "#E81309";

export default {
  props: {
    deviceBatteryLevel: Number,
  },
  computed: {
    batteryHeight() {
      return 26 * (this.deviceBatteryLevel / 100) + 1;
    }, 
    batteryTransform() {
      return `translate(0, ${28 - this.batteryHeight})`;
    }, 
    batteryColor() {
      // if (this.deviceBatteryLevel >= 50) return normalColor;
      // if (this.deviceBatteryLevel >= 15) return warnColor;
      // return alertColor;

      return normalColor;
    } 
  }
};
</script>