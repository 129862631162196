<template>
  <!-- popup for device detail  -->
  <v-dialog @keydown.esc="close_device_details_dialog" v-model="deviceDetailsDialog" overflow-hidden persistent fullscreen>
    <DeviceDetailsAppBar @close="close_device_details_dialog" @openConfigs="open_device_configs_dialog"
      :deviceName="getDeviceName" :idString="idString" :isUnlicensed="isUnlicensed"
      :validDevice="!!getDeviceModel" :refreshDeviceDetails="refresh_device_details" />

    <v-main style="background-color: #F7F7F7">
      <DeviceDetailsHeader />
      <div class="mx-auto px-2" style="max-width: var(--detail-dialog-max-width)">
        <DeviceStatusCard class="main-grid-card" />
        <v-row class="mx-auto" no-gutters > <!-- Two Columns on Bigger Screens, One Column on Small Screens-->
          <v-col cols="12" md="6" class="d-flex flex-column">
            <DeviceInfoCard class="main-grid-card" />
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <WifiInfo class="main-grid-card  ml-md-2" />
            <DeviceWANStatus class="main-grid-card  ml-md-2" />
          </v-col>
        </v-row>
        <DeviceLocation v-if="shouldShowLocation(getDeviceModel)" :deviceLocation="getDeviceLocation" class="main-grid-card mx-auto" />
        <ConnectedDeviceHistory class="main-grid-card mx-auto"/>
      </div>
    </v-main> 
  </v-dialog>
</template>

<script>
import DeviceDetailsAppBar from "./DeviceDetailsComponents/DeviceDetailsAppBar.vue";
import DeviceDetailsHeader from "./DeviceDetailsComponents/DeviceDetailsHeader.vue";
import DeviceInfoCard from "./DeviceDetailsComponents/DeviceInfoCard.vue";
import DeviceStatusCard from "./DeviceDetailsComponents/DeviceStatusCard.vue";
import DeviceWANStatus from "./DeviceDetailsComponents/DeviceWANStatus.vue";
import ConnectedDeviceHistory from "./DeviceDetailsComponents/ConnectedDeviceHistory.vue";
import WifiInfo from "./DeviceDetailsComponents/WifiInfo.vue";
import DeviceLocation from "./DeviceDetailsComponents/DeviceLocation.vue";


import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mapGetters, mapActions, mapMutations } from "vuex";


export default {
  mixins: [router_model_utils],
  props: {
    deviceDetailsDialog: Boolean,
    singleRouterInfo: Object,
  },
  components: {
    DeviceDetailsAppBar,
    DeviceDetailsHeader,
    ConnectedDeviceHistory,
    WifiInfo,
    DeviceInfoCard,
    DeviceStatusCard,
    DeviceLocation,
    DeviceWANStatus,
  },
  watch: {
    deviceDetailsDialog: {
      handler(){
        this.mutationSetCurrentDeviceUserEmail(this.singleRouterInfo.user_email_id);
      },
      immediate: true
    }
  },
  // data: () => ({
  //   fakeDeviceLocation: {
  //     latitude: 32.772376,
  //     longitude: -117.202167,
  //     lastUpdated: new Date()
  //   },
  // }),
  computed: {
    ...mapGetters(["getterGetDeviceConfigurations", "getterGetCurrentUserRole", "getterGetUserInfo"]),
    getDeviceName() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_name : "";
    },
    getDeviceModel() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_model : "";
    },
    getDeviceIMEI() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.imei_number : "";
    },
    getDeviceID() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_id : "";
    },
    isUnlicensed() {
      const licenseStatus = this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.license_status : "";
      return licenseStatus !== "trialing" && !licenseStatus.includes("rial") && licenseStatus.toLowerCase() !== "active";
    },
    // Return null value if the location is not available
    // Replace the map component with "No GPS Data" icon
    getDeviceLocation() {
      if (!this.getterGetDeviceConfigurations.router_details.router_gps || !this.getterGetDeviceConfigurations.router_details.router_gps.Latitude || !this.getterGetDeviceConfigurations.router_details.router_gps.Longitude) return {};
      return {
        'latitude': this.getterGetDeviceConfigurations.router_details.router_gps.Latitude,
        'longitude': this.getterGetDeviceConfigurations.router_details.router_gps.Longitude,
        'lastUpdated': this.getterGetDeviceConfigurations.router_details.router_gps.LastUpdated,
      };
    },
    isBusinessRouter() {
      return this.getterGetDeviceConfigurations.router_details.is_individual_router === false;
    },
    idString() {
      return this.isMobileDevice(this.getDeviceModel) ? this.getDeviceIMEI : this.getDeviceID;
    },
  },

  methods: {
    ...mapActions(["actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar", "mutationSetCurrentDeviceUserEmail"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    // --------------- Refresh Page ----------------
    async refresh_device_details() {
      this.mutationOpenProgressBar();
      try {
        await this.actionGetSingleDeviceInfo(this.getDeviceID);
        await this.actionGetDeviceConfiguration(this.getDeviceID);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.mutationCloseProgressBar();
      }
    },

    // ----------------- configuration -------------------
    open_device_configs_dialog() {
      this.$emit("openConfigs", this.singleRouterInfo);
    },
    close_device_details_dialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.v-dialog {
  overflow-y: visible !important;
}

.main-grid-card {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: solid 1px #E5E5EA;
  flex-grow: 1;
}
</style>

<style>
* {
  --detail-dialog-max-width: 88rem;
}
</style>
