<template>
  <CardGroupSlot v-if="groupDiffs" :CardGroupTitle="'Main and Guest Wi-Fi'" :isPreview="isPreview">
    <template #body>
      <transition-group name="fade">
        <div class="history-list my-2" v-for="diff in groupDiffs" :key="diff.path">
          <SingleCard type="single" :diff="diff" :propertyNameTable="mixinFieldNames" />
        </div>
      </transition-group>
    </template>
  </CardGroupSlot>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import CardGroupSlot from "../CardComponents/CardGroupSlot.vue";
import SingleCard from "../CardComponents/SingleCard.vue";

export default {
  mixins: [mixin_config_history_utils],
  props: {
    groupDiffs: Array,
    isPreview: Boolean
  },
  components: {
    SingleCard,
    CardGroupSlot,
  },
};
</script>
