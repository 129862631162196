<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 mt-2 mb-4" flat>
        <v-toolbar-title class="d-none d-md-block mr-3" style="text-overflow: clip; overflow: visible">Business Devices</v-toolbar-title>

        <v-text-field clearable single-line append-icon="mdi-magnify blue--text" @click:clearable="search = ''" v-model="search" color="blue" label="Search" style="max-width: 220px; min-width: 150px" hide-details> </v-text-field>

        <FilterButtonMenu class="ml-2" v-model="filters" :modelOptions="modelOptions" :organizationOptions="organizationOptions" />

        <ColumnButtonMenu class="ml-2" :allHeaders="allHeaders" v-model="headers" />

        <v-spacer></v-spacer>

        <v-slide-group style="min-width: 240px" multiple show-arrows active-class center-active>
          <v-slide-item key="0">
            <v-btn :disabled="selectedRecordsModels.length === 0" class="mx-2 text-none" color="#0195D2" @click="open_assign_to_group_dialog(selectedRecords)" outlined><v-icon>mdi-plus</v-icon>Add To Group</v-btn>
          </v-slide-item>
          <v-slide-item key="1">
            <v-btn :disabled="selectedRecordsModels.length === 0" class="mx-2 text-none" color="#0195D2" @click="open_reset_config_dialog" outlined><v-icon>mdi-cog-refresh</v-icon>Reset Config</v-btn>
          </v-slide-item>
          <v-slide-item key="2">
            <v-btn :disabled="selectedRecords.length === 0" class="mx-2 text-none" color="#0195D2" @click="export_device_list()" outlined><v-icon>mdi-export-variant</v-icon>Export</v-btn>
          </v-slide-item>
          <v-slide-item key="3">
            <v-btn :disabled="selectedRecords.length === 0" class="mx-2 text-none" color="#DA1201" @click="open_delete_device_dialog" outlined><v-icon>mdi-delete</v-icon>Delete</v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-toolbar>

      <vue-json-to-csv ref="csvExport" class="csv-export" :labels="csvHeaders" csv-title=" List" :json-data="selectedRecords" />

      <!-- Start of the Table rows -->
      <v-card class="tablePadding" flat>
        <v-card-text class="pt-0">
          <v-data-table
            dense
            show-select
            v-model="selectedRecords"
            item-key="router_id"
            class="elevation-1 dtwidth caption device-table"
            :headers="headers"
            :search="search"
            :items="filteredDeviceList"
            :items-per-page="10"
            :item-class="() => 'device-row'"
            :loading="isTableLoading"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
            :no-data-text="isTableLoading ? 'Loading items...' : 'No devices available'"
            :no-results-text="`No matching results for '${search}'`"
            item-style="cursor: pointer"
            @click:row="open_device_details_dialog"
            @toggle-select-all="toggleSelectAll">

            <template v-slot:[`item.router_name`]="{ item }">
              <span>{{ item.router_name }}</span>
            </template>

            <template v-slot:[`item.license_status`]="{ item }">
              <span :style="{ color: item.license_status === 'trialing' || item.license_status.toLowerCase() === 'active' ? 'green' : 'red' }">{{ capitalizeFirstLetter(item.license_status) }}</span>
            </template>

            <template v-slot:[`item.time_of_day`]="{ item }">
              <SuspendInternetButton v-if="item.time_of_day" class="mr-2" :scheduleList="item.time_of_day.ScheduleList" @click="open_suspend_internet_dialog(item)" />
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.expire_date`]="{ item }">
              <span class="mr-2" v-html="formatDateWithTime(item.expire_date)"></span>
            </template>

            <template v-slot:[`item.config_updated_on`]="{ item }">
              <v-tooltip v-if="item.config_updated_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="item.indicate_color">
                    <span class="caption">{{ mixinConvertUTCToDate(item.config_updated_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="item.indicate_color === 'red'">The device has never connected to the server</span>
                <span v-else-if="item.indicate_color === 'orange'">The device has yet to receive the latest configuration from the server</span>
                <span v-else-if="item.indicate_color === 'green'">The configuration is up to date</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.last_reported_on`]="{ item }">
              <v-tooltip v-if="item.last_reported_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="mixinGetTimeDifference(item.last_reported_on) >= 1 ? 'grey' : '#4CAF50'">
                    <span class="caption">{{ mixinConvertUTCToDate(item.last_reported_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="mixinGetTimeDifference(item.last_reported_on) >= 1">Last reported a day or more ago</span>
                <span v-else>Last reported within 24hrs</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.mac_address`]="{ item }">
              <span>{{ item.mac_address ? item.mac_address : "-" }}</span>
            </template>

            <template v-slot:[`item.imei_number`]="{ item }">
              <span>{{ item.imei_number && item.imei_number != "N/A" ? item.imei_number : "-" }}</span>
            </template>

            <template v-slot:[`item.msisdn`]="{ item }">
              <span>{{ item.msisdn && item.msisdn != "N/A" ? item.msisdn : "-" }}</span>
            </template>

            <template v-slot:[`item.organization_name`]="{ item }">
              <span>{{ item.organization_name ? item.organization_name : "-" }}</span>
            </template>

            <template v-slot:[`item.partner_info`]="{ item }">
              <span>{{ item.partner_info ? item.partner_info.user_full_name : "-" }}</span>
            </template>

            <template v-slot:[`item.carrier_info`]="{ item }">
              <span>{{ item.carrier_info ? item.carrier_info : "-" }}</span>
            </template>

            <template v-slot:[`item.signal`]="{ item }">
              <SignalBar v-if="shouldShowSignal(item.router_model)" :signal="item.signal" />
              <span v-else class="ml-1"> - </span>
            </template>

            <template v-slot:[`item.usage`]="{ item }">
              <span>{{ item.usage ? item.usage : "0.0" }}</span>
            </template>

            <template v-slot:[`item.router_description`]="{ item }">
              <span :class="{ 'ml-1': !item.router_description }">
                {{ item.router_description ? item.router_description : "-" }}
              </span>
            </template>

            <template v-slot:[`item.data_usage`]="{ item }">
              <span :class="{ 'ml-1': !item.data_usage }">
                {{ item.data_usage ? item.data_usage : "-" }}
              </span>
            </template>

            <template v-slot:[`item.firmware_version`]="{ item }">
              <span :class="{ 'ml-1': !item.firmware_version }">
                {{ item.firmware_version ? item.firmware_version : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_group`]="{ item }">
              <span :class="{ 'ml-1': !item.router_group }">
                {{ item.router_group ? item.router_group : "-" }}
              </span>
            </template>

            <template v-slot:[`item.add_ons`]="{ item }">
              <span>
                {{ item.add_ons ? item.add_ons : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_id`]="{ item }">
              <span>{{ item.router_id.split("/")[1] }}</span>
            </template>

            <template v-slot:[`item.is_individual_router`]="{ item }">
              <span class="caption">
                <span v-if="!item.is_individual_router" class="caption orange--text">Business</span>
                <span v-else class="caption green--text">Individual</span>
              </span>
            </template>

            <!-- Actions dropdown list -->
            <template v-slot:[`item.Actions`]="{ item }">
              <div ref="actions">
                <v-menu bottom left content-class="menu-offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item @click="open_device_details_dialog(item)">
                      <v-list-item-title>Device Details</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="superAdminCheck" @click="open_edit_device_dialog(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="open_configs_dialog(item)" :class="{ disabled: item.license_status.toLowerCase() !== 'active' && item.license_status !== 'trialing' }">
                      <v-list-item-title>Configuration</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="!partnerAdminCheck && !individualUserCheck" @click="open_remote_command_dialog(item)">
                      <v-list-item-title>Remote Command</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:[`item.online_status`]="{ item }">
              <span class="caption" :class="item.online_status == 'ONLINE' ? 'green--text text--darken-3' : 'red--text text--darken-2'">
                {{ item.online_status == "ONLINE" ? "Online" : "Offline" }}
              </span>
            </template>

            <!-- Footer -->
            <template v-slot:footer.prepend>
              <div v-if="isTableLoading" class="d-flex align-center">
                <LoadingIcon class="mr-3 px-2" />
              </div>
              <div v-else class="d-flex align-center">
                <v-btn class="mr-3" icon text>
                  <v-icon @click="handle_refresh_list()" dark size="30" color="cyan" id="refresh">mdi-refresh</v-icon>
                </v-btn>
                <span>Last updated: {{ mixinGetLastUpdatedTime }}</span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-main>

    <router-view></router-view>

    <DeviceDetailsDialog :deviceDetailsDialog="deviceDetailsDialog" :singleRouterInfo="singleRouterInfo" @openConfigs="open_configs_dialog" @close="close_device_details_dialog" />

    <RemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :singleRouterInfo="singleRouterInfo" @close="close_remote_command_dialog"></RemoteCommandDialog>

    <AssignToGroupDialog :assignToGroupDialog="assignToGroupDialog" :selectedDevices="selectedDevices" :groupModel="groupModel" :deviceOrganization="deviceOrganization" @close="close_assign_to_group_dialog" />

    <EditADeviceDialog :editDeviceDialog="editDeviceDialog" :editDeviceInfo="editDeviceInfo" @clicked="close_edit_device_dialog" />

    <DeleteDevicesDialog :deleteRouterDialog="deleteRouterDialog" :selectedRecords="selectedRecords" @close="close_delete_device_dialog" />

    <ResetDeviceConfigsDialog :resetConfigDialog="resetConfigDialog" :selectedRecords="selectedRecords" @close="close_reset_config_dialog" />

    <DeviceConfigDialog :deviceConfigDialog="deviceConfigDialog" :isGroupConfig="false" :singleRouterInfo="singleRouterInfo" @close="close_configs_dialog" />

    <SuspendInternetDialog v-if="singleRouterInfo.router_model !== 'FX20'" :suspendInternetDialog="suspendInternetDialog" :singleRouterInfo="singleRouterInfo" :scheduleList="singleRouterInfo.time_of_day ? singleRouterInfo.time_of_day.ScheduleList : []" @close="close_suspend_internet_dialog" />
  </div>
</template>

<script>
import DeviceDetailsDialog from "@/components/Devices/DeviceDialogs/DeviceDetailsDialog/DeviceDetailsDialog.vue";
import RemoteCommandDialog from "@/components/Devices/DeviceDialogs/RemoteCommandDialog.vue";
import EditADeviceDialog from "@/components/Devices/DeviceDialogs/EditADeviceDialog.vue";
import DeviceConfigDialog from "@/components/DeviceConfigurations/DeviceConfigDialog.vue";

import SuspendInternetDialog from "@/components/Devices/SharedComponents/SuspendInternetDialog.vue";
import AssignToGroupDialog from "@/components/Devices/DeviceDialogs/AssignToGroupDialog.vue";
import DeleteDevicesDialog from "@/components/Devices/DeviceDialogs/DeleteDevicesDialog.vue";
import ResetDeviceConfigsDialog from "@/components/Devices/DeviceDialogs/ResetDeviceConfigsDialog.vue";
import VueJsonToCsv from "vue-json-to-csv";
import LoadingIcon from "@/components/LoadingIcon.vue";
import SignalBar from "@/components/Devices/SharedComponents/SignalBar.vue";
import SuspendInternetButton from "@/components/Devices/SharedComponents/SuspendInternetButton.vue";
import FilterButtonMenu from "@/components/Devices/SharedComponents/FilterButtonMenu.vue";
import ColumnButtonMenu from "@/components/Devices/SharedComponents/ColumnButtonMenu.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils";

import { getDeviceList, requestQueryToRouter } from "@/services";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  mixins: [router_model_utils, mixin_time_utils],
  components: {
    EditADeviceDialog,
    VueJsonToCsv,
    DeviceDetailsDialog,
    RemoteCommandDialog,
    AssignToGroupDialog,
    DeleteDevicesDialog,
    LoadingIcon,
    ResetDeviceConfigsDialog,
    DeviceConfigDialog,
    SuspendInternetDialog,
    SuspendInternetButton,
    SignalBar,
    FilterButtonMenu,
    ColumnButtonMenu,
  },
  data() {
    return {
      isMounted: false,
      deviceConfigDialog: false,

      //DeviceDetailsDialog Component Variables
      deviceDetailsDialog: false,
      singleRouterInfo: {},

      //RemoteCommand Component Variables
      remoteCommandDialog: false,

      //AddDevice Component Variables
      selectedDevices: [],
      selectedRecordsModels: [],
      assignToGroupDialog: false,
      groupModel: "",
      deviceOrganization: "",

      //DeviceEdit Component Variables
      editDeviceDialog: false,
      editDeviceInfo: {},

      //DeleteRouters Component Variables
      routerID: [],
      deleteRouterDialog: false,

      // ResetConfig Component Variables
      resetConfigDialog: false,

      search: "",

      // This is for select boxes at the top for filtering
      onlineItems: [
        { value: "ONLINE", text: "ONLINE" },
        { value: "OFFLINE", text: "OFFLINE" },
      ],

      filters: {
        status: "",
        model: "",
        organization: "",
      },

      fetchedDevices: [],
      last_evaluated_key: {},
      selectedRecords: [],
      isTableLoading: false, // Flag for v-data-table loading

      tableHeaderFilterDialog: false,

      suspendInternetDialog: false,

      csvHeaders: {
        router_model: { title: "Model" },
        online_status: { title: "Status" },
        last_reported_on: { title: "Last Reported" },
        router_id: { title: "Device SN" },
        imei_number: { title: "IMEI" },
        license_status: { title: "Subscription" },
        expire_date: { title: "Renewal Date" },
        msisdn: { title: "MSISDN" },
        carrier_info: { title: "Carrier" },
        firmware_version: { title: "Device Version" },
        config_updated_on: { title: "Config Updated" },
        router_name: { title: "Device Name" },
        router_description: { title: "Description" },
        partner_name: { title: "Partner" },
        organization_name: { title: "Organization" },
        router_group: { title: "Group" },
        data_usage: { title: "Usage(GB)" },
        signal: { title: "Signal" },
      },

      allHeaders: {
        Status: { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "online_status" },
        DeviceName: { text: "Device Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        Subscription: { text: "Subscription", align: "start", sortable: true, class: "headerColor white--text ", value: "license_status" },
        MSISDN: { text: "MSISDN", sortable: true, class: "headerColor white--text ", value: "msisdn" },
        InternetAccess: { text: "Internet Access", align: "start", sortable: true, class: "headerColor white--text", value: "time_of_day" },
        ExpirationDate: { text: "Expiration Date", align: "start", sortable: true, class: "headerColor white--text", value: "expire_date" },
        ConfigUpdated: { text: "Config Updated", sortable: true, class: "headerColor white--text ", value: "config_updated_on" },
        LastReported: { text: "Last Reported", sortable: true, class: "headerColor white--text ", value: "last_reported_on" },
        Model: { text: "Model", align: "start", sortable: true, class: "headerColor white--text ", value: "router_model" },
        DeviceSN: { text: "Device SN", sortable: true, class: "headerColor white--text ", value: "router_id" },
        DeviceType: { text: "Device Type", sortable: true, class: "headerColor white--text ", value: "is_individual_router" },
        Email: { text: "Email", sortable: true, class: "headerColor white--text ", value: "user_email_id" },
        Carrier: { text: "Carrier", sortable: true, class: "headerColor white--text ", value: "carrier_info" },
        Partner: { text: "Partner", sortable: true, class: "headerColor white--text ", value: "partner_info" },
        Organization: { text: "Organization", sortable: true, class: "headerColor white--text ", value: "organization_name" },
        DeviceVersion: { text: "Device Version", sortable: true, class: "headerColor white--text ", value: "firmware_version" },
        Group: { text: "Group", sortable: true, class: "headerColor white--text ", value: "router_group" },
        Usage: { text: "Usage(GB)", sortable: true, class: "headerColor white--text ", value: "data_usage" },
        Signal: { text: "Signal", sortable: true, class: "headerColor white--text ", value: "signal" },
        Actions: { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      },
      headers: [],
    };
  },

  // --------------------------Watch----------------------------------
  watch: {
    //Gene 6/27/23 Method to disable add to group when two models are selected
    selectedRecords() {
      var modelArray = this.selectedRecords.map((obj) => obj.router_model);
      this.selectedRecordsModels = [...new Set(modelArray)];
    },
  },

  // --------------------------Mounted----------------------------------
  // isMounted flag will be used to stop querying device list to the database once the component becomes unmounted
  // This is will prevent endless chain of queries when navigating in between the pages quickly
  mounted() {
    this.isMounted = true;
    this.filters.model = this.$route.params.model ? this.$route.params.model : "";
    this.handle_refresh_list();
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  // --------------------------Computed----------------------------------
  computed: {
    ...mapGetters(["getterGetCustomerAdminDashboardInfo", "getterGetUserInfo", "getterGetCurrentUserRole", "getterGetDeviceConfigurations"]),
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    superAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";
    },
    partnerAdminCheck() {
      return this.getterGetCurrentUserRole === "P_ADMIN";
    },
    individualUserCheck() {
      return this.getterGetCurrentUserRole === "INDIVIDUAL";
    },
    modelOptions() {
      const modelNames = this.fetchedDevices.reduce((acc, device) => {
        acc[device.router_model] = true;
        return acc;
      }, {});
      return Object.keys(modelNames);
    },
    organizationOptions() {
      const organizationNames = this.fetchedDevices.reduce((acc, device) => {
        acc[device.organization_name] = true;
        return acc;
      }, {});
      return Object.keys(organizationNames).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
    filteredDeviceList() {
      return this.fetchedDevices.filter((device) => {
        const isMatchingModel = this.filters.model ? device.router_model === this.filters.model : true;
        const isMatchingOrganization = this.filters.organization ? device.organization_name === this.filters.organization : true;
        const isMatchingOnlineStatus = this.filters.status ? device.online_status === this.filters.status : true;

        return isMatchingModel && isMatchingOrganization && isMatchingOnlineStatus;
      });
    },
    loadingText() {
      return this.isTableLoading ? "Loading..." : "No Organizations Available";
    },
  },

  // --------------------------Methods----------------------------------
  methods: {
    async open_configs_dialog(item) {
      this.singleRouterInfo = item;
      if (item.license_status.toLowerCase() === "active" || item.license_status === "trialing") {
        try {
          this.mutationOpenProgressBar();
          await this.actionGetSingleDeviceInfo(item.router_id);
          await this.actionGetDeviceConfiguration(item.router_id);
        } catch (err) {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        } finally {
          this.deviceConfigDialog = true;
          this.mutationCloseProgressBar();
        }
      } else {
        this.triggerSnackBar("error", "Renew subscription to enable configuraiton");
      }
    },
    close_configs_dialog() {
      this.deviceConfigDialog = false;
    },
    ...mapActions(["actionGetSuperAdminDashBoardDetails", "actionGetCustomerAdminDashBoardDetails", "actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    // ------------- Suspend Snternet ---------------------
    open_suspend_internet_dialog(item) {
      this.singleRouterInfo = item;
      this.suspendInternetDialog = true;
    },
    close_suspend_internet_dialog(val) {
      this.suspendInternetDialog = false;
    },

    // ------------- Methods used in this form only ---------------
    //query call to fetch the router list to store
    async fetchAllDevices() {
      this.isTableLoading = true;

      // Fetch up to 10 devices in the first query
      let queryObj = {
        user_role: this.getCurrentUserRole,
        category: "BUSINESS",
        count: 100,
      };

      try {
        // Note that `fetchedDevices` is different function from `this.fetchDevices`
        const fetchedDevices = await getDeviceList(queryObj);
        this.fetchedDevices = fetchedDevices.items;
        this.last_evaluated_key = fetchedDevices.last_evaluated_key;
        // Continue fetching the next set of devices if there are any
        this.load_more_router_data(queryObj);
        this.mixinResetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        // this.isTableLoading = false;
      }
    },
    async load_more_router_data(queryObj) {
      queryObj.count = 500; // Fetch up to 500 devices per query after initial query
      while (this.last_evaluated_key && this.isMounted) {
        queryObj.last_evaluated_key = this.last_evaluated_key;
        try {
          const fetchedDevices = await getDeviceList(queryObj);
          this.fetchedDevices = this.fetchedDevices.concat(fetchedDevices.items);
          this.last_evaluated_key = fetchedDevices.last_evaluated_key;
        } catch (err) {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        }
      }
      this.isTableLoading = false;
    },
    async handle_refresh_list() {
      if (this.isTableLoading) {
        return; // Exit the method if refresh is already in progress
      }
      this.fetchedDevices = [];
      await this.fetchAllDevices();
    },

    // --------- FilterHeader Component related --------------
    filter_headers(filteredHeaders) {
      if (filteredHeaders == 0) {
        this.tableHeaderFilterDialog = false;
      } else {
        this.headers = filteredHeaders;
        this.tableHeaderFilterDialog = false;
      }
    },

    // -------------- Group Related ------------------
    async open_assign_to_group_dialog(selectedRecords) {
      const selectedDeviceOrg = selectedRecords[0].customer_id;
      if (this.selectedRecordsModels.length !== 1) {
        this.triggerSnackBar("error", "Different device models cannot be added to the same group!");
        return;
      }
      if (!selectedRecords.every((device) => device.customer_id === selectedDeviceOrg)) {
        this.triggerSnackBar("error", "The selected devices must be of the same organization!");
        return;
      }
      this.deviceOrganization = selectedDeviceOrg;
      selectedRecords.forEach((device) => {
        this.selectedDevices.push(device.router_id);
      });
      this.groupModel = selectedRecords[0].router_model;
      this.assignToGroupDialog = true;
    },
    close_assign_to_group_dialog(val) {
      this.assignToGroupDialog = false;
      this.deviceOrganization = "";
      this.selectedRecords = [];
      this.selectedDevices = [];
      if (val === 1) {
        setTimeout(() => {
          this.handle_refresh_list();
        }, 3000);
      }
    },

    // ------------ Device Delete related --------------------
    open_delete_device_dialog() {
      this.routerID = [];
      this.selectedRecords.forEach((element) => {
        this.routerID.push({
          ID: element.router_id,
          name: element.router_name,
        });
      });
      this.deleteRouterDialog = true;
    },
    //method invokes device delete action
    close_delete_device_dialog(deleteObj) {
      if (deleteObj.status === 0) {
        this.deleteRouterDialog = false;
      } else if (deleteObj.status === 1) {
        this.selectedRecords = [];
        this.deleteRouterDialog = false;
        this.handle_refresh_list();
      }
    },

    // ------------------ Reset Router --------------------
    open_reset_config_dialog() {
      this.resetConfigDialog = true;
    },

    close_reset_config_dialog() {
      this.resetConfigDialog = false;
    },

    // ---------------- CSV related -------------------------
    // Another seemingly useless code that will never reach its condition
    //method to validate if not item is slected on export action
    export_device_list() {
      if (this.selectedRecords.length == 0) return this.triggerSnackBar("error", "Please select at least one item");
      this.$refs.csvExport.$el.click();
    },

    // --------------- Row Dialog Related ----------------
    async open_device_details_dialog(item) {
      this.mutationOpenProgressBar();

      try {
        this.singleRouterInfo = item;
        await requestQueryToRouter(item.router_id);
        await this.actionGetSingleDeviceInfo(item.router_id);
        await this.actionGetDeviceConfiguration(item.router_id);
        this.deviceDetailsDialog = true;
      } catch (err) {
        // Do not show any errors
      } finally {
        this.mutationCloseProgressBar();
      }
    },

    close_device_details_dialog() {
      this.deviceDetailsDialog = false;
    },

    // -------------- Device Edit related -------------------
    // opens the device edit dialog
    // "editDeviceInfo" = selected information data from <v-data-table>.
    // The "router_id" and "customer_id" are used to fetch an appropriate list of organization and filter it down.
    async open_edit_device_dialog(selectedDeviceInfo) {
      this.editDeviceInfo = selectedDeviceInfo;
      this.editDeviceDialog = true;
    },
    // method to validate the data that invoked from child component
    // "clickStatus" meaning:
    // 0 = The user just closed the dialog without any changes
    // 1 = The user closed the dialog after pressing save async request. Information on the local device list also has to change
    // in order to sync up with the changed data
    close_edit_device_dialog(editObj) {
      if (editObj.status === 0) {
        this.editDeviceDialog = false;
      } else if (editObj.status === 1) {
        this.editDeviceDialog = false;
        // search for edited device from router list and update information locally
        this.fetchedDevices.forEach((device) => {
          if (device.router_id === editObj.router_id) {
            device.router_activated = editObj.router_activated;
            if (!device.is_individual_router) {
              device.customer_id = editObj.customer_id;
              device.organization_name = editObj.organization_name;
            }
          }
        });
      }
    },

    // ----------- Remote Command --------------
    open_remote_command_dialog(item) {
      this.singleRouterInfo = item;
      this.remoteCommandDialog = true;
    },
    close_remote_command_dialog() {
      this.remoteCommandDialog = false;
    },

    // ------------------------- Misc ----------------------------
    // method to indicate the colorcode
    // These can be included in mixin later
    capitalizeFirstLetter(str) {
      //Remove once all devices convert from trialing to "Free Trial"
      if (str === "trialing") {
        return "Free Trial";
      }
      return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    formatDateWithTime(dateString) {
      // Create a new Date object from the input date string
      const currentDate = new Date();
      const endDate = new Date(dateString);
      //Calculate days remaining
      const msDifference = endDate - currentDate;
      const millisecondsInADay = 86400000;
      const daysRemaining = Math.floor(msDifference / millisecondsInADay);
      // Extract the month, day, and year from the Date object
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
      const day = String(endDate.getDate()).padStart(2, "0");
      const year = endDate.getFullYear();
      // Extract the hours, minutes, and seconds from the Date object
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      // Return the formatted date string in MM/DD/YYYY HH:MM:SS format
      // return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} <i class="grey--text">(${daysRemaining} days remaining)</i>`;
      return `${month}/${day}/${year} <i class="grey--text">(${daysRemaining} days remaining)</i>`;
    },
    toggleSelectAll(item) {
      if (item.value) {
        this.selectedRecords = this.filteredDeviceList;
      } else {
        this.selectedRecords = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-offset {
  transform: translateY(10px) translateX(-20px) !important;
}

.caption.green--text.text--darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}

.search-bar ::v-deep .mdi-close {
  color: grey !important;
}

.device-table ::v-deep .device-row {
  cursor: pointer;
}

.csv-export {
  display: none;
}
</style>