<template>
  <v-dialog v-model="rowDialog" persistent fullscreen class="dialog-card" overflow-hidden>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-card>
      <v-toolbar class="toolbarBack" dense :style="{ 'background-color': getHexToHex(singleOrganizationInfo.color_code) }">
        <v-toolbar-title class="white--text">Organization: {{ singleOrganizationInfo.organization_name }} </v-toolbar-title>
        <v-spacer />
        <div class="white--text mr-4">Created On: {{ mixinConvertUTCToDate(singleOrganizationInfo.organization_created_on) }}</div>
        <v-btn @click="closeDialog" icon class="mr-2"><v-icon class="white--text">mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-container style="background-color: white" class="mx-0 px-0 container-width">
        <v-container class="py-0 px-0 mx-1 container-width mb-3">
          <v-row no-gutters class="ml-4 mt-0">
            <v-col cols="5">
              <span>
                <b> Organization Code :</b>
                {{ singleOrganizationInfo.organization_code }}
              </span>
            </v-col>
            <v-col cols="4">
              <span>
                <b> Status :</b>
                <span :class="[singleOrganizationInfo.organization_status === 'ACTIVE' ? ['blue--text', 'text--darken-2', 'ml-1', 'bold-status'] : ['grey--text', 'text--darken-1', 'ml-1', 'bold-status']]"> {{ singleOrganizationInfo.organization_status == "ACTIVE" ? "Active" : "Inactive" }}</span>
              </span>
            </v-col>
            <v-col cols="3">
              <span>
                <b> Email :</b>
                {{ singleOrganizationInfo.user_email_id }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="ml-4 mt-1">
            <v-col cols="5">
              <span>
                <b> Partner :</b>
                {{ singleOrganizationInfo.partner_name }}
              </span>
            </v-col>
            <v-col cols="3">
              <span>
                <b> City :</b>
                <span :class="singleOrganizationInfo.organization_city == null ? 'grey--text italic' : ''">
                  {{ singleOrganizationInfo.organization_city == null ? "No City Available" : singleOrganizationInfo.organization_city }}
                </span>
              </span>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-row class="mt-3 mb-n2">
          <v-select :items="listOptions" outlined v-model="listType" label="List Type" class="filter-width ml-7 mt-2 mr-0"></v-select>
          <v-select v-if="listType == 'Devices'" class="filter-width mt-n3 ml-6 filter filter-group" color="black" clearable style="font-size: 12px" v-model="groupFilter" label="Group" :items="groupList" :class="{ 'overflow-limit': groupFilter && groupFilter.length > 20 }" @click:clearable="get_status_value(groupFilter, 'group')" @input="get_status_value(groupFilter, 'group')">
            <template #item="{ item }">
              <v-container class="filter-font" :class="{ 'unassigned-border mx-0': item == 'Unassigned Devices' }"> {{ item }}</v-container>
            </template>
          </v-select>
          <v-select v-else class="filter-width ml-6 mt-n3 filter" color="black" clearable style="font-size: 12px" v-model="statusFilter" label="Status" :items="statusList" @click:clearable="get_status_value(statusFilter, 'group')" @input="get_status_value(statusFilter, 'status')">
            <template #item="{ item }">
              <span class="filter-font"> {{ item }}</span>
            </template>
          </v-select>
          <v-select class="filter-width ml-4 mt-n3 filter" color="black" clearable style="font-size: 12px" v-model="modelFilter" label="Model" :items="modelList" @click:clearable="get_status_value(modelFilter, 'model')" @input="get_status_value(modelFilter, 'model')">
            <template #item="{ item }">
              <span class="filter-font"> {{ item }}</span>
            </template>
          </v-select>

          <div v-if="listType == 'Devices'">
            <v-btn v-if="selectedRecords.length > 0 && selectedRecordsModels.length == 1" class="button ml-6 mr-2 mt-3" @click="open_organization_group_dialog" dark>Assign To Group </v-btn>

            <v-tooltip v-else-if="selectedRecordsModels.length > 1" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn class="ml-6 mr-2 mt-3 button custom-disabled">Assign To Group</v-btn>
                </span>
              </template>
              <span>Cannot add two different model types to the same group</span>
            </v-tooltip>

            <v-btn v-else class="ml-6 mr-2 mt-3 button custom-disabled">Assign To Group </v-btn>

            <v-btn v-if="selectedRecords.length > 0" class="button mr-2 mt-3" dark @click="remove_devices()"> Remove From Group </v-btn>

            <v-btn v-else class="button mr-2 mt-3 custom-disabled"> Remove From Group </v-btn>
            <v-btn v-if="selectedRecords.length > 0 && $store.getters.getterGetCurrentUserRole != 'SP_ADMIN'" class="button mr-2 mt-3" @click="open_delete_device_dialog" dark>Delete</v-btn>
            <v-btn v-else class="mr-2 button custom-disabled mt-3">Delete</v-btn>
          </div>

          <div v-if="listType == 'Groups'">
            <v-btn class="button ml-6 mr-2 mt-3" @click="open_create_group_dialog()" dark>Create Group </v-btn>

            <v-btn v-if="selectedRecords.length > 0" class="button mr-2 mt-3" dark @click="delete_group(selectedRecords)"> Delete Group </v-btn>

            <v-btn v-else class="button mr-2 mt-3 custom-disabled"> Delete Group </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="container-wrapper">
            <v-text-field dense clearable append-icon="mdi-magnify blue--text" @click:clearable="clear_value" v-model="search" class="search-bar mt-5 mr-8" color="blue" label="Search" single-line style="max-width: 220px"> </v-text-field>
          </div>
        </v-row>
        <v-card-text class="py-2">
          <v-data-table
            dense
            v-model="selectedRecords"
            :single-select="singleSelect"
            :item-key="listType == 'Devices' ? 'serial_no' : 'group_name'"
            show-select
            :search="search"
            :headers="listType == 'Devices' ? deviceHeaders : groupHeaders"
            :items="listType == 'Devices' ? tableDeviceData : tableGroupData"
            class="elevation-1 mt-0"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100, 500, -1],
            }"
            :loading="loading1"
            :items-per-page="10"
          >
            <template v-slot:[`item.router_group_added_on`]="{ item }">
              <span>{{ mixinConvertUTCToDate(item.router_group_added_on) }}</span>
            </template>
            <template v-slot:header.group_status="{ header }">
              <span class="ml-2">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.group_name`]="{ item }">
              <span :class="{ 'no-group': item.group_name.length == 0 }">{{ item.group_name.length > 0 ? item.group_name : "No group assigned" }}</span>
            </template>
            <template v-slot:[`item.registered_routers`]="{ item }">
              <span class="">{{ item.registered_routers }}</span>
            </template>
            <template v-slot:[`item.group_model`]="{ item }">
              <span class="">{{ item.group_model }}</span>
            </template>
            <template v-slot:[`item.cellular_usage`]="{ item }">
              <span class="">{{ item.cellular_usage }}</span>
            </template>
            <template v-slot:[`item.wan_usage`]="{ item }">
              <span class="">{{ item.wan_usage }}</span>
            </template>
            <template v-slot:[`item.total_data`]="{ item }">
              <span class="">{{ item.total_data }}</span>
            </template>
            <template v-slot:[`item.router_name`]="{ item }">
              <span class="ml-2">{{ item.router_name }}</span>
            </template>
            <template v-slot:header.router_name="{ header }">
              <span class="ml-2">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.group_status`]="{ item }">
              <span v-if="item.group_status == 'INACTIVE'" class="grey--text text--darken-1 caption ml-2 bold-status">Inactive</span>
              <span v-else class="blue--text text--darken-2 caption ml-2 bold-status">Active</span>
            </template>
            <template v-slot:footer>
              <div>
                <LoadingIcon v-if="loading1 == true" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>

                <span v-if="loading1 == true" style="position: absolute" class="ml-10 mt-5"> Loading organization data... </span>

                <span v-if="loading1 == false">
                  <v-icon @click="refresh_list()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                  <span style="position: absolute" class="ml-11 pl-0 mt-5"> Last updated: {{ mixinGetLastUpdatedTime }} </span>
                </span>
              </div>
            </template>
          </v-data-table>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-container>
    </v-card>

    <AssignToOrganizationGroupDialog :organizationGroupDialog="organizationGroupDialog" :selectedDevices="selectedRecords" :groupModel="selectedRecordsModels[0]" :deviceOrganization="singleOrganizationInfo.customer_id" @close="close_organization_group_dialog" />

    <UnassignGroup v-if="unassignGroupDialog == true" :selectedRecords="selectedRecords" :unassignGroupDialog="unassignGroupDialog" @closeDialog="close_dialog" @errorMsg="errorMsg"></UnassignGroup>

    <CreateGroupDialog :createGroupDialog="createGroupDialog" :productList="productList" :organizationList="organizationList" @close="close_create_group_dialog" />

    <DeleteGroup v-if="deleteGroupDialog == true" :deleteGroupDialog="deleteGroupDialog" :selectedRecords="selectedRecords" :tableDeviceData="tableDeviceData" @clicked="close_dialog"> </DeleteGroup>

    <DeleteDevicesDialog :deleteRouterDialog="deleteRouterDialog" :selectedRecords="selectedRecords" @close="close_delete_device_dialog" />
  </v-dialog>
</template>

<script>

import AssignToOrganizationGroupDialog from "./OrganizationDialogs/AssignToOrganizationGroupDialog.vue";
import UnassignGroup from "@/components/Configuration/UnassignGroup.vue";
import SnackBar from "@/components/SnackBar.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import CreateGroupDialog from "@/components/Groups/GroupDialogs/CreateGroupDialog.vue";
import DeleteGroup from "@/components/Configuration/DeleteGroup.vue";
import DeleteDevicesDialog from "@/components/Devices/DeviceDialogs/DeleteDevicesDialog.vue";

import { mixin_time_utils } from "@/mixins/timeUtils";
import { mapMutations } from "vuex";

import { getProductList } from "@/services";

export default {
  mixins: [mixin_time_utils],

  props: {
    rowDialog: Boolean,
    singleOrganizationInfo: Object,
    groupData: Array,
    deviceData: Array,
    groupList: Array,
    modelList: Array,
    loadingData: Boolean,
  },
  computed: {
    orgDetails() {
      return [
        {
          label: this.singleOrganizationInfo.organization_name,
          value: this.singleOrganizationInfo.customer_id,
          selectedOrg: true,
        },
      ];
    },
  },

  components: {
    AssignToOrganizationGroupDialog,
    SnackBar,
    UnassignGroup,
    LoadingIcon,
    CreateGroupDialog,
    DeleteGroup,
    DeleteDevicesDialog,
  },

  watch: {
    listType() {
      this.modelFilter = "";
      this.groupFilter = "";
      this.statusFilter = "";
      this.modelInput = null;
      this.groupInput = null;
      this.statusInput = null;
      this.selectedRecords = [];
      this.selectedRecordsModels = [];
      if (this.listType == "Devices") {
        this.tableDeviceData = [...this.deviceData];
      } else {
        this.tableGroupData = [...this.groupData];
      }
    },

    rowDialog() {
      this.modelFilter = "";
      this.groupFilter = "";
      this.statusFilter = "";
      this.modelInput = null;
      this.groupInput = null;
      this.statusInput = null;
      this.selectedRecords = [];
      this.selectedRecordsModels = [];
      if (this.listType == "Devices") {
        this.tableDeviceData = [...this.deviceData];
      } else {
        this.tableGroupData = [...this.groupData];
      }
    },

    selectedRecords() {
      var modelArray = this.selectedRecords.map((obj) => obj.group_model);
      this.selectedRecordsModels = [...new Set(modelArray)];
    },

    deviceData() {
      if (this.deviceData.length > 0) {
        const outdatedIds = this.selectedRecords.map((router) => router.router_id);
        this.tableDeviceData = [...this.deviceData];
        this.selectedRecords = this.tableDeviceData.filter((device) => outdatedIds.includes(device.router_id));
      }
    },

    groupData() {
      if (this.loadingData == false) {
        this.tableGroupData = [...this.groupData];
        this.loading1 = false;
      }
    },
  },
  data() {
    return {
      tableDeviceData: [...this.deviceData],
      tableGroupData: [...this.groupData],
      search: "",
      loading1: false,
      groupHeaders: [
        { text: "Status", sortable: true, value: "group_status", class: "headerColor white--text " },
        { text: "Group Name", value: "group_name", class: "headerColor white--text ", sortable: true },
        { text: "Assigned Devices", sortable: true, value: "registered_routers", class: "headerColor white--text " },
        { text: "Model", sortable: true, value: "group_model", class: "headerColor white--text " },
        { text: "Created On", value: "router_group_added_on", class: "headerColor white--text ", sortable: true }
      ],
      deviceHeaders: [
        { text: "Name", value: "router_name", class: "headerColor white--text ", sortable: true },
        { text: "Group", sortable: true, value: "group_name", class: "headerColor white--text " },
        { text: "Model", value: "group_model", class: "headerColor white--text ", sortable: true },
        { text: "Serial No.", sortable: true, value: "serial_no", class: "headerColor white--text " }
      ],
      listOptions: ["Devices", "Groups"],
      listType: "Devices",
      selectedRecords: [],
      singleSelect: false,
      groupFilter: "",
      modelFilter: "",
      statusFilter: "",
      groupInput: null,
      modelInput: null,
      statusInput: null,
      statusList: ["Active", "Inactive"],
      search: "",
      selectedRecordsModels: [],
      devicePopup: false,
      SnackBarComponent: {},
      unassignGroupDialog: false,
      createGroupDialog: false,
      productModels: [],
      deleteGroupDialog: false,
      deleteRouterDialog: false,
      routerID: [],

      productList: [],
      organizationList: [],

      organizationGroupDialog: false
    };
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    open_organization_group_dialog() {
      this.organizationGroupDialog = true;
    },
    close_organization_group_dialog() {
      this.organizationGroupDialog = false;
    },

    closeDialog() {
      this.$emit("closeDialog");
    },

    errorMsg(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },

    //method to return filter params on input
    get_status_value(data, category) {
      if (category == "group") {
        if (data == "Unassigned Devices") {
          this.groupInput = "";
        } else {
          this.groupInput = data;
        }
      }
      if (category == "model") {
        this.modelInput = data;
      }
      if (category == "status") {
        if (data == "Active") {
          this.statusInput = "ACTIVE";
        } else if (data == "INACTIVE") {
          this.statusInput = "INACTIVE";
        } else {
          this.statusInput = data;
        }
      }
      this.filterCheck = true;
      this.get_routers_list();
    },

    //query call to fetch the data on filter option
    async get_routers_list() {
      this.filterLoading = false;
      this.filterCheck = false;
      this.isLoading = false;
      this.loadingTable = false;
      if (this.listType == "Devices") {
        var response = this.deviceData;
      } else {
        var response = this.groupData;
      }
      var filteredData = [];
      response.forEach((element) => {
        if (this.modelInput != null && element.group_model != this.modelInput) {
          return;
        }
        if (this.groupInput != null && element.group_name != this.groupInput) {
          return;
        }
        if (this.statusInput != null && element.group_status != this.statusInput) {
          return;
        }
        filteredData.push(element);
      });

      if (this.listType == "Devices") {
        this.tableDeviceData = filteredData;
      } else {
        this.tableGroupData = filteredData;
      }

      this.$forceUpdate();
    },

    clear_value() {
      this.search = "";
    },

    async close_dialog(val) {
      let action = null;
      let snackBarText = "";
      let dialogToClose = null;
      if (val === 0) {
        this.devicePopup = false;
        this.unassignGroupDialog = false;
        this.deleteGroupDialog = false;
      } else if (val === 1) {
        action = "removeDevices";
        snackBarText = "Devices Removed Successfully";
        dialogToClose = "unassignGroupDialog";
      } else if (val === 2) {
        action = "mapDevices";
        snackBarText = "Devices Mapped Successfully";
        dialogToClose = "devicePopup";
      } else if (val == 3) {
        action = "deleteGroups";
        snackBarText = "Groups Deleted Successfully";
        dialogToClose = "deleteGroupDialog";
      }
      if (dialogToClose !== null) {
        this[dialogToClose] = false;
      }

      if (action) {
        this.loading1 = true;
        this.$emit("refreshList", this.singleOrganizationInfo);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: snackBarText,
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },

    async refresh_list() {
      this.loading1 = true;
      this.$emit("refreshList", this.singleOrganizationInfo);
      this.mixinResetStartTime();
    },

    remove_devices() {
      this.unassignGroupDialog = true;
    },

    delete_group(selectedGroups) {
      this.deleteGroupDialog = true;
    },
    //Method to update "last updated" time

    getHexToHex(item) {
      if (item == "#7030A0") {
        return "#7030A0";
      } else if (item == "#FFC000") {
        return "#ED6E05";
      } else if (item == "#002060") {
        return "#AC1010";
      } else if (item == "#28637C") {
        return "#D41694";
      } else if (item == "#0070c0") {
        return "#0070C0";
      } else if (item == "#2CCAFF") {
        return "#0D3D79";
      } else if (item == "#00B050") {
        return "#01631D";
      } else if (item == "#92D050") {
        return "#44A336";
      }
    },

    // ----------Create group dialog------------
    async open_create_group_dialog() {
      this.mutationOpenProgressBar();
      try{
        this.organizationList = [ { text: this.singleOrganizationInfo.organization_name, value: this.singleOrganizationInfo.customer_id } ];
        const productList = await getProductList();
        productList.forEach((product) => {
          this.productList.push(product.product_sku);
        });
        this.createGroupDialog = true;
      } catch (err){
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.mutationCloseProgressBar();
      }
    },
    close_create_group_dialog(val) {
      this.createGroupDialog = false;
      // refresh the group list
      if(val === 1){
        this.refresh_list();
      }
    },

    // ------------ Device Delete related --------------------
    open_delete_device_dialog() {
      this.routerID = [];
      this.selectedRecords.forEach((element) => {
        this.routerID.push({
          ID: element.router_id,
          name: element.router_name,
        });
      });
      this.deleteRouterDialog = true;
    },
    //method invokes device delete action
    close_delete_device_dialog(deleteObj) {
      if (deleteObj.status === 0) {
        this.deleteRouterDialog = false;
      } else if(deleteObj.status === 1) {
        this.tableDeviceData = this.tableDeviceData.filter(device => !deleteObj.deviceIdList.some(id => id === device.router_id));
        this.selectedRecords = [];
        this.deleteRouterDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.container-width {
  max-width: 100% !important;
}
.v-dialog {
  overflow-y: auto;
}
.dialog-card {
  max-height: 100%;
  overflow: hidden;
}

.filter-width {
  width: 100%;
  max-width: 160px;
  margin-left: 16px;
}

.filter-width >>> .v-input__slot {
  min-height: 0px;
  height: 52px;
}
.filter-width >>> .v-input__append-inner {
  margin-top: 12px;
}
.filter-width >>> .v-input__control {
  height: 52px;
}

.italic {
  font-style: italic;
}
.no-group {
  color: #0195d2;
  font-style: italic;
}
.bold-status {
  font-weight: 500;
}

.filter >>> .v-select__selection {
  margin-bottom: -4px;
  overflow: visible;
}
.filter >>> .v-label.theme--light {
  font-size: 16px;
  margin-top: 6px;
}
.filter.overflow-limit >>> .v-select__selection {
  overflow: hidden;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}
.search-bar >>> .mdi-close {
  color: grey !important;
}
.container-wrapper .search-bar >>> .v-input__slot label,
.container-wrapper .search-bar >>> .v-input__slot input {
  margin-bottom: 4px;
}

.v-toolbar >>> .v-toolbar__content {
  padding-right: 0px;
}

.unassigned-border {
  border-bottom: 2px solid #0195d2;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  position: absolute;
  left: 0px;
  text-align: center;
}
.filter-font.container {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  left: 0px;
}
.button.custom-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: white;
  background-color: rgb(145, 145, 145) !important;
}

.button.v-btn {
  height: 34px;
  width: auto;
  font-size: 14px;
  text-transform: none;
}
</style>
