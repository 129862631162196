<template>
  <div>
    <v-main>
      <!-- tool bar to diaply title and add action -->
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title class="mt-1">Bulk Registers</v-toolbar-title>
        <v-spacer></v-spacer>
        <ImportExcel @uploaded="validateExcelFileUpload" />
      </v-toolbar>


      <ExcelValidation :validData="validData" :invalidData="invalidData" :organizationList="organizationList" :countryList="countryList" :modelList="modelList"/>

    </v-main>
  </div>
</template>
<script>
import ImportExcel from "@/components/BulkUpload/ImportExcel.vue";
import ExcelValidation from "@/components/BulkUpload/ExcelValidation.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
// import { does_subscription_exist } from "@/graphql/queries/stripeQueries.js";

export default {
  mixins: [mixin_form_validation_utils],
  components: {
    ImportExcel,
    ExcelValidation,
  },
  data() {
    return {
      validData: [],
      invalidData: [],
      organizationList: [],
      countryList: [],
      modelList: [],
    };
  },
  created() {},
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    //method to validate the empty record
    // async validate_add_data(results, header) {

    //   try {
    //     this.validation_data(results, header);
        //Check if user/organization already contains subscription or not
        // const doesSubscriptionExist = await API.graphql(
        //   graphqlOperation(does_subscription_exist, {
        //     input: {
        //       organization_list: this.organizationList[0],
        //       model: this.modelList[0],
        //       license_country: this.countryList[0],
        //       acc_type: "business",
        //     },
        //   })
        // );
        // this.subscriptionData = JSON.parse(
        //   doesSubscriptionExist.data.does_subscription_exist
        // );
      // } catch (error) {
      //   this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
        // if (error.errors[0].message.includes("does not exist in stripe")) {}
      // } finally {
      //   this.excelUploadStatus = true;
      // }

    // },
    radioBandRule(otherBand){
      return [
        value => !!value || 'Wi-Fi Radio Band: You must have Radio Bands for both Main and Guest Wi-Fi or enter neither.',
        value => /^[0-2]$/.test(value) || "Radio band must be numbers between 0~2.",
        value => value !== otherBand || "The Main Wi-Fi Band and Guest Wi-Fi Band cannot be the same."
      ];
    },
    validateExcelFileUpload(excelDeviceList) {
      this.invalidData = [];
      this.validData = [];

      if (excelDeviceList.length == 0) return this.triggerSnackBar("error", "The data cannot be empty.");
      
      this.modelList = [];
      this.countryList = [];
      this.organizationList = [];

      excelDeviceList.forEach((uploadedData, index) => {
        let errorRow = { ...uploadedData };
        let isRowValid = true;

        const ModelCheck = this.mixinScriptValidation(uploadedData.Model, this.mixinModelNameRule());
        if (!ModelCheck.valid) {
          errorRow.Model = `(${uploadedData.Model}) ${ModelCheck.message}`
          isRowValid = false;
        }

        const IMEICheck = this.mixinScriptValidation(uploadedData.IMEI, this.mixinIMEIRule());
        if (!IMEICheck.valid) {
          errorRow.IMEI = `(${uploadedData.IMEI}) ${IMEICheck.message}`
          isRowValid = false;
        }

        const FIDCheck = this.mixinScriptValidation(uploadedData.FID, this.mixinIMEIRule());
        if (!FIDCheck.valid) {
          errorRow.FID = `(${uploadedData.FID}) ${FIDCheck.message}`
          isRowValid = false;
        }

        if(!uploadedData.ProductName) {
          errorRow.ProductName = `You must provide product name`;
          isRowValid = false;
        }
        if(!uploadedData.LicenseType) {
          errorRow.LicenseType = `You must provide license type`;
          isRowValid = false;
        }
        if(!uploadedData.LicenseName) {
          errorRow.LicenseName = `You must provide license name`;
          isRowValid = false;
        }

        // Main Wi-Fi validation
        // Note that neither Main Wi-Fi nor Guest Wi-Fi are mandatory. There can be either one or the other or none
        const hasMainWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasMainWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasMainWiFiSSID || hasMainWiFiPassword) {
          const mainWifiSSIDCheck = this.mixinScriptValidation(uploadedData.MainWiFiSSID, this.mixinWifiNameRule());
          if (!mainWifiSSIDCheck.valid) {
            errorRow.MainWiFiSSID = `(${uploadedData.MainWiFiSSID}) ${mainWifiSSIDCheck.message}`;
            isRowValid = false;
          }
          const mainWifiPasswordCheck = this.mixinScriptValidation(uploadedData.MainWiFiPassword, this.mixinWifiPasswordRule());
          if (!mainWifiPasswordCheck.valid) {
            errorRow.MainWiFiPassword = `(${uploadedData.MainWiFiPassword}) ${mainWifiPasswordCheck.message}`;
            isRowValid = false;
          }
        }

        // Guest Wi-Fi Validation
        const hasGuestWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasGuestWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasGuestWiFiSSID || hasGuestWiFiPassword) {
          const guestWifiSSIDCheck = this.mixinScriptValidation(uploadedData.GuestWiFiSSID, this.mixinWifiNameRule());
          if (!guestWifiSSIDCheck.valid) {
            errorRow.GuestWiFiSSID = `(${uploadedData.GuestWiFiSSID}) ${guestWifiSSIDCheck.message}`;
            isRowValid = false;
          }
          const guestWifiPasswordCheck = this.mixinScriptValidation(uploadedData.GuestWiFiPassword, this.mixinWifiPasswordRule());
          if (!guestWifiPasswordCheck.valid) {
            errorRow.GuestWiFiPassword = `(${uploadedData.GuestWiFiPassword}) ${guestWifiPasswordCheck.message}`;
            isRowValid = false;
          }
        }

        const hasMainWiFiRadioBand = uploadedData.hasOwnProperty("MainWiFiRadioBand");
        const hasGuestWiFiRadioBand = uploadedData.hasOwnProperty("GuestWiFiRadioBand");
        if (hasMainWiFiRadioBand || hasGuestWiFiRadioBand) {
          const mainWiFiRadioBandCheck = this.mixinScriptValidation(uploadedData.MainWiFiRadioBand, this.radioBandRule(uploadedData.GuestWiFiRadioBand));
          if (!mainWiFiRadioBandCheck.valid) {
            errorRow.MainWiFiRadioBand = `(${uploadedData.MainWiFiRadioBand}) ${mainWiFiRadioBandCheck.message}`;
            isRowValid = false;
          }
          const guestWiFiRadioBandCheck = this.mixinScriptValidation(uploadedData.GuestWiFiRadioBand, this.radioBandRule(uploadedData.MainWiFiRadioBand));
          if (!guestWiFiRadioBandCheck.valid) {
            errorRow.GuestWiFiRadioBand = `(${uploadedData.GuestWiFiRadioBand}) ${guestWiFiRadioBandCheck.message}`;
            isRowValid = false;
          }
        }


        // Admin password
        const adminPasswordCheck = this.mixinScriptValidation(uploadedData.RouterAdminPassword, this.mixinRouterAdminPasswordRule());
        if (!adminPasswordCheck.valid) {
          errorRow.RouterAdminPassword = `(${uploadedData.RouterAdminPassword}) ${adminPasswordCheck.message}`;
          isRowValid = false;
        }

        isRowValid ? this.validData.push(uploadedData) : this.invalidData.push(errorRow);

        // This is only for verifying whethere there are two or more oraganizations, models, or countries in each respective lists
        if (uploadedData.Organization != undefined && !this.organizationList.includes(uploadedData.Organization)) {
          this.organizationList.push(uploadedData.Organization);
        }
        if (!this.modelList.includes(uploadedData.Model)) {
          this.modelList.push(uploadedData.Model);
        }
        if (!this.countryList.includes(uploadedData.LicenseCountry)) {
          this.countryList.push(uploadedData.LicenseCountry);
        }
      });

      if(this.invalidData.length > 0) {
        this.triggerSnackBar("error", "The excel file you've uploaded has some errors. Click on invalid tab for more information.");
      }
    },
  },
};
</script>
