<template>
  <v-dialog overflow-hidden v-model="endSignUpDialog" persistent max-width="600px">
    <DialogCard v-if="endSignUpDialog" :modalIcon="'text-box-check-outline'" :modalTitle="'Follow Instructions'">
      <template #header>
        <v-btn icon x-small @click="close_end_sign_up_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <div>
          <v-img aspect-ratio="1.5" contain src="@/assets/Restartyourrouter.png"> </v-img>
        </div>
      </template>

      <template #footer>
        <v-btn small @click="back_to_login()" class="button tabledata--text mr-2">Ok</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mapMutations } from "vuex";

export default {
  mixins: [mixin_routing_utils],
  components: {
    DialogCard,
  },
  props: {
    endSignUpDialog: Boolean,
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close actions
    close_end_sign_up_dialog() {
      this.$emit("close", 0);
    },
    // Note that the sign up form reset action will be performed in router gaurd
    // Whenever the user returns from /SignUp page, the router guard will automatically reset the signup vuex states
    back_to_login() {
      this.mixinChangeRoute("/");
    },
  },
};
</script>
