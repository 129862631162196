<template>
  <v-dialog overflow-hidden v-model="manageGroupDevicesDialog" persistent max-width="600px">
    <DialogCard :modalIcon="'group'" :modalTitle="`Manage Devices: ${groupInfo.group_name}`">
      <template #header>
        <v-btn icon x-small @click="close_manage_group_devices_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-row class="mt-4" no-gutters>
          <v-col cols="4">
            <p class="grey--text font-weight-bold ml-5" style="font-size: 1.05rem; line-height: 40px; vertical-align: middle; margin-bottom: 0">
              Model:<span class="font-weight-medium ml-2">{{ groupInfo.group_model }}</span>
            </p>
          </v-col>
          <v-col cols="6">
            <v-select v-model="selectedUngroupedDevice" label="Select a device to add" no-data-text="No Devices Available" :items="ungroupedDevices" outlined dense hide-details>
              <template #label v-if="loadingGroupDeviceDetails">
                <i class="fas fa-spinner fa-spin ml-0" style="color: #949494"></i>
                <span class="ml-2">Loading</span>
              </template>
              <template #item="{ item }" v-else>
                <span class="filter-font"> {{ item.text }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="2">
            <v-btn class="button tabledata--text" small :loading="loadingGroupDeviceDetails" @click="add_device_to_group()">Add</v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-5" no-gutters>
          <v-col cols="7"></v-col>
          <v-col cols="5">
            <v-text-field clearable append-icon="mdi-magnify blue--text" single-line @click:clearable="search = ''" v-model="search" label="Search" hide-details dense></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-3" no-gutters>
          <v-col>
            <v-data-table :headers="headers" :items="groupedDevices" :search="search" height="200px" :items-per-page="5" class="elevation-0 ml-1" dense :loading="loadingGroupDeviceDetails" :no-data-text="loadingGroupDeviceDetails ? 'Loading items...' : 'No devices available'" :no-results-text="`No matching results for '${search}'`">
              <template slot="item.Actions" slot-scope="props">
                <v-btn small text class="blue--text" outlined @click="remove_device_from_group(props.item)">Remove</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_manage_group_devices_dialog()">Cancel</v-btn>
        <v-btn small @click="setGroupDevices()" class="button tabledata--text mr-2" :loading="loadingSaveGroupDevices" :disabled="loadingGroupDeviceDetails">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { getGroupedDevices, getUngroupedDevices, addDevicesToAGroup, removeDevicesFromAGroup } from "@/services";
import { mapMutations } from "vuex";

export default {
  components: {
    DialogCard,
  },
  props: {
    manageGroupDevicesDialog: Boolean,
    groupInfo: Object,
  },
  data() {
    return {
      // Grouping and ungrouping of devices fetched from the server
      groupedDevices: [],
      ungroupedDevices: [],
      // Original array of fetched device grouping
      originalGroupedDevices: [],
      originalUngroupedDevices: [],
      // List of devices that will be sent to the server for final confirmation
      addedDevices: [],
      removedDevices: [],
      selectedUngroupedDevice: null,
      headers: [
        { text: "Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        { text: "Serial", align: "start", sortable: true, class: "headerColor white--text begin-column-margin", value: "router_id" },
        { text: "Actions", sortable: true, class: "headerColor white--text end-column-margin", value: "Actions" },
      ],
      search: "",
      loadingGroupDeviceDetails: false,
      loadingSaveGroupDevices: false,
    };
  },
  watch: {
    manageGroupDevicesDialog: {
      async handler(newValue) {
        this.groupedDevices = [];
        this.ungroupedDevices = [];
        this.originalGroupedDevices = [];
        this.originalUngroupedDevices = [];
        this.addedDevices = [];
        this.removedDevices = [];
        if (newValue) {
          await this.getGroupDeviceDetails();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // local 변수 초기화
    resetDialog() {},
    // close the dialog without any changes
    close_manage_group_devices_dialog() {
      this.$emit("close", { status: 0 });
      this.resetDialog();
    },
    add_device_to_group() {
      if (!this.selectedUngroupedDevice) {
        this.triggerSnackBar("error", "Please select a device to add");
      } else {
        this.setChangedGroupState(this.selectedUngroupedDevice, this.originalGroupedDevices, this.ungroupedDevices, this.removedDevices, this.addedDevices);
        this.moveDevice(this.selectedUngroupedDevice, this.ungroupedDevices, this.groupedDevices);
        this.selectedUngroupedDevice = null;
      }
    },
    remove_device_from_group(deviceToRemove) {
      this.setChangedGroupState(deviceToRemove.router_id, this.originalUngroupedDevices, this.groupedDevices, this.addedDevices, this.removedDevices);
      this.moveDevice(deviceToRemove.router_id, this.groupedDevices, this.ungroupedDevices);
      this.selectedUngroupedDevice = null;
    },
    setChangedGroupState(router_id, originalArray, referenceArray, fromArray, toArray) {
      const itemToAdd = referenceArray.find((item) => item.router_id === router_id);
      let matchingIndex = fromArray.findIndex((item) => item === router_id);
      if (matchingIndex !== -1) {
        fromArray.splice(matchingIndex, 1);
      }
      if (!originalArray.some((item) => item.router_id === router_id)) {
        toArray.push(itemToAdd.router_id);
      }
    },
    moveDevice(router_id, fromArray, toArray) {
      let matchingIndex = -1;
      let matchingItem = null;
      fromArray.forEach((item, index) => {
        if (item.router_id === router_id) {
          matchingIndex = index;
          matchingItem = item;
        }
      });
      if (matchingIndex !== -1) {
        fromArray.splice(matchingIndex, 1);
        toArray.unshift(matchingItem);
      }
    },
    async getGroupDeviceDetails() {
      this.loadingGroupDeviceDetails = true;
      try {
        const fetchedUngroupedDevices = await getUngroupedDevices({ customer_id: this.groupInfo.customer_id });
        // Set ungrouped devices
        this.ungroupedDevices = fetchedUngroupedDevices
          .filter((device) => this.groupInfo.group_model === device.router_id.split("/")[0])
          .map((device) => {
            return {
              router_name: device.router_name,
              router_id: device.router_id,
              text: `${device.router_name} (${device.router_id})`,
              value: device.router_id,
            };
          });
        // Make copy of the original for comparison
        this.originalUngroupedDevices = this.ungroupedDevices.map((item) => ({ ...item }));

        const fetchedGroupedDevices = await getGroupedDevices({ router_group_id: this.groupInfo.router_group_id, customer_id: this.groupInfo.customer_id });
        // Set grouped devices
        this.groupedDevices = fetchedGroupedDevices.map((device) => {
          return {
            router_name: device.router_name,
            router_id: device.router_id,
            text: `${device.router_name} (${device.router_id})`,
            value: device.router_id,
          };
        });
        // Make copy of the original for comparison
        this.originalGroupedDevices = this.groupedDevices.map((item) => ({ ...item }));
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingGroupDeviceDetails = false;
      }
    },
    async setGroupDevices() {
      this.loadingSaveGroupDevices = true;
      let addToGroupResponse = null;
      let removeFromGroupResponse = null;
      let groupingChanged = false;
      try {
        if(this.addedDevices.length > 0){
          addToGroupResponse = await addDevicesToAGroup({
            customer_id: this.groupInfo.customer_id,
            router_group_id: this.groupInfo.router_group_id,
            router_ids: this.addedDevices
          });
          groupingChanged = true;
        }
        if(this.removedDevices.length > 0) {
          removeFromGroupResponse = await removeDevicesFromAGroup({
            router_id_array: this.removedDevices
          });
          groupingChanged = true;
        }
        if(!groupingChanged) throw new Error("No changes have been made to your group. Make sure that you make changes.");
        if(removeFromGroupResponse && removeFromGroupResponse.failure.length > 0) throw new Error("Some of the devices may have failed ungrouping");
        this.triggerSnackBar("success", "Device grouping has changed successfully");
        this.$emit("close", { status: 1, router_group_id: this.groupInfo.router_group_id});
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingSaveGroupDevices = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.field-height {
  height: 2rem;
}
</style>
