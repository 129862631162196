<template>
  <v-dialog v-model="updateDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
    <v-card v-if="updateDialog" tile flat>
      <v-card-title class="bannercolor" style="padding: 14px 24px">
        <span class="text-heading-5 white--text">User Profile</span>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="close_dialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col cols="12">
        <v-container fluid class="pt-0">
          <AccountInfo />
          <!-- <v-btn style="margin-top: 2rem;" @click="mutationClearAllReminderDate">Re-Enable All Expiration Reminders</v-btn> -->
        </v-container>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountInfo from "@/components/UpdateProfileInfo/AccountInfo.vue";

export default {
  props: {
    updateDialog: Boolean,
  },
  components: {
    AccountInfo,
  },
  data() {
    return {
      drawer: null,
      detailsCheck: 0,
    };
  },
  methods: {
    // ...mapMutations(["mutationClearAllReminderDate"]),
    //method invokes on close action
    close_dialog() {
      this.$emit("close");
    },
  },
};
</script>
