<template>
  <!-- popup to edit the devices  -->
  <v-dialog overflow-hidden v-model="editDeviceDialog" persistent max-width="600px">
    <DialogCard v-if="editDeviceDialog" :modalIcon="'pencil'" :modalTitle="'Edit Device'">
      <template #header>
        <v-btn icon x-small @click="close_device_edit_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-row no-gutters>
          <p class="pb-3 grey--text font-weight-bold">
            Partner Name:
            <span style="margin-left: 0.5rem">
              <span class="font-weight-medium" v-if="!loadingOrgList">{{ partnerName ? partnerName : "No partner" }}</span>
              <v-progress-circular v-else indeterminate color="blue lighten-2" size="20"></v-progress-circular>
            </span>
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field dense v-model="productSku" label="Product Sku " outlined style="max-width: 300px" readonly></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field class="ml-2" dense v-model="serialNum" label="Serial Number" outlined readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6">
            <v-select label="Activate" style="max-width: 300px" v-model="activatedStatus" :items="['Activated', 'Deactivated']" outlined dense required></v-select>
          </v-col>
          <v-col v-if="!editDeviceInfo.is_individual_router" cols="6">
            <v-select class="ml-2" label="Organization(Account)" dense v-model="organization" :items="organizationItems" :menu-props="{ bottom: true, offsetY: true }" item-text="label" item-value="value" outlined style="max-width: 300px">
              <template #label v-if="loadingOrgList">
                <i class="fas fa-spinner fa-spin ml-0 mr-1" style="color: #949494"></i>
                <span> Organization </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_device_edit_dialog()">Cancel</v-btn>
        <v-btn small :loading="loadingSaveDeviceEdit" @click="update_device_edit()" class="button tabledata--text mr-2" :disabled="loadingOrgList">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>
<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { setDeviceDetails, getOrganizationList } from "@/services";
import { mapMutations } from "vuex";

export default {
  components: {
    DialogCard,
  },
  props: {
    editDeviceDialog: Boolean,
    editDeviceInfo: Object,
  },
  data() {
    return {
      partnerName: "",
      serialNum: "",
      productSku: "",
      userEmail: "",
      organization: "",
      activatedStatus: "",

      organizationList: [],
      organizationItems: [],
      loadingOrgList: false,

      loadingSaveDeviceEdit: false,
      // This is only for temporary use
      partnerId: "",
    };
  },
  watch: {
    editDeviceDialog: {
      async handler(newValue, oldValue) {
        if (newValue) {
          this.productSku = this.editDeviceInfo.router_model;
          this.serialNum = this.editDeviceInfo.router_id.split("/")[1];
          this.activatedStatus = this.editDeviceInfo.router_activated;
          this.organization = this.editDeviceInfo.customer_id;
          if (!this.editDeviceInfo.is_individual_router) {
            await this.getOrganization();
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // local 변수 초기화
    resetDialog() {
      this.organizationList = [];
      this.organizationItems = [];
      this.partnerName = "";
      this.partnerId = "";
    },
    //method invokes on close action
    close_device_edit_dialog() {
      this.$emit("clicked", { status: 0 });
      this.resetDialog();
    },

    async getOrganization() {
      try {
        this.loadingOrgList = true;
        // This query should be used if all the backend data tables are fixed
        // this.organizationList = await getOrganizationList({ organization_status: "ACTIVE", partner_id: this.editDeviceInfo.partner_id });
        this.organizationList = await getOrganizationList({ organization_status: "ACTIVE" });

        // Find current router/device's organization and retrieve partner id and name
        const currentOrg = this.organizationList.find((org) => org.customer_id === this.editDeviceInfo.customer_id);

        // set current partner name and current partner id
        this.partnerId = currentOrg.partner_id;
        this.partnerName = currentOrg.partner_name;
        // filter all organizations that is under the current partner
        this.organizationList = this.organizationList.filter((org) => org.partner_id === this.partnerId);

        this.organizationItems = this.organizationList.map((organziation) => {
          return {
            label: organziation.organization_name,
            value: organziation.customer_id,
          };
        });
        // console.log(JSON.parse(JSON.stringify(this.organizationList)));
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingOrgList = false;
      }
    },

    //mutation call for editing the device
    async update_device_edit() {
      this.loadingSaveDeviceEdit = true;

      // async data sent to the server
      let deviceEditData = {
        router_id: this.editDeviceInfo.router_id,
        router_name: this.editDeviceInfo.router_name,
        router_description: this.editDeviceInfo.router_description ? this.editDeviceInfo.router_description : "",
        router_activated: this.activatedStatus,
      };

      //data sent back up to the parent to change the local device list
      let parentEditData = {
        status: 1,
        router_id: this.editDeviceInfo.router_id,
        router_activated: this.activatedStatus,
      };

      // If the device has grouping
      if (!this.editDeviceInfo.is_individual_router) {
        // async data
        deviceEditData.customer_id = this.organization;
        deviceEditData.partner_id = this.partnerId;
        // parent data
        let selectedOrganization = this.organizationItems.find((item) => item.value === this.organization);
        parentEditData.customer_id = this.organization;
        parentEditData.organization_name = selectedOrganization.label;
      }

      // If it is an individual router but still somehow has partner_id
      if (this.editDeviceInfo.partner_id) {
        deviceEditData.partner_id = this.editDeviceInfo.partner_id;
      }
      try {
        await setDeviceDetails(deviceEditData);
        this.triggerSnackBar("success", "Update Successful");
        // send back changed data to the parent
        this.$emit("clicked", parentEditData);
        this.resetDialog();
      } catch (error) {
        this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
      } finally {
        this.loadingSaveDeviceEdit = false;
      }
    },
  },
};
</script>
