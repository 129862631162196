<template>
  <v-card flat>
    <v-card-title> Connected Device History </v-card-title>
    <v-card-text>
      <v-data-table class="connected-table dtwidth" :mobile-breakpoint="0" :headers="routerDeviceData" :items="getDeviceDHCPClients" :sort-by="['updated_on']" :sort-desc="[true]" :footer-props="{ itemsPerPageText: 'Items per page' }" dense>
        <template v-slot:[`item.host_name`]="{ item }">
          <span class="caption" :class="item.connected ? 'primary-blue--text' : 'grey--text'">{{ item.host_name }}</span>
        </template>
        <template v-slot:[`item.updated_on`]="{ item }">
          <span class="caption">{{ convert_date(item.updated_on) }}</span>
        </template>
        <template v-slot:[`item.ip_address`]="{ item }">
          <span class="caption">{{ item.ip_address }}</span>
        </template>

        <template v-slot:[`item.interface_type`]="{ item }">
          <v-chip x-small :color="item.interface_type == 'Wireless' ? 'orange' : 'primary'" class="white--text my-2">
            {{ item.interface_type == "Wireless" ? "Wi-Fi" : "Ethernet" }}
          </v-chip>
        </template>
        <template v-slot:[`item.mac_address`]="{ item }">
          <span class="caption" v-text="item.mac_address ? item.mac_address : '-'"></span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    routerDeviceData: [
      { text: "Device Name", value: "host_name", class: "black--text" },
      { text: "Last Connected", value: "updated_on", class: "black--text" },
      { text: "IP Address", value: "ip_address", class: "black--text" },
      { text: "MAC Address", value: "mac_address", class: "black--text" },
      { text: "Interface", value: "interface_type", class: "black--text" },
    ],
  }),
  computed: {
    ...mapGetters([ 'getterGetDeviceDHCPClients' ]),
    getDeviceDHCPClients() {
      return this.getterGetDeviceDHCPClients;
    }
  },
  methods: {
    // method to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(
          d.getDate()
        )}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },
  },
};
</script>