export const create_stripe_subscription = /* GraphQL */ `
  mutation create_stripe_subscription($input: CreateStripeSubscriptionInput) {
    create_stripe_subscription(input: $input)
  }
`;
export const create_customer_portal_session = /* GraphQL */ `
  mutation create_customer_portal_session($input: CreateStripeCustomerPortalInput) {
    create_customer_portal_session(input: $input)
  }
`;
export const delete_stripe_customer = /* GraphQL */ `
  mutation delete_stripe_customer($input: StripeDeleteCustomerInput!) {
    delete_stripe_customer(input: $input)
  }
`;
export const update_stripe_subscription = /* GraphQL */ `
  mutation update_stripe_subscription($input: UpdateStripeSubscriptionInput!) {
    update_stripe_subscription(input: $input)
  }
`;

export const remove_device_from_subscription = /* GraphQL */ `
  mutation remove_device_from_subscription($input: RemoveDeviceFromSubscriptionInput!) {
    remove_device_from_subscription(input: $input)
  }
`;
