<template>
  <div>
    <v-card class="justify-center">
      <v-card-title>Multi-Factor Authentication</v-card-title>
      <v-card-text class="card-text">
        <div>
          <div class="justify-center"><b>Step 1</b></div>
          <div class="justify-center mt-2">You will need to set up a Time-Based One-Time Password application on your Mobile phone such <u>Google Authenticator</u> or <u>Microsoft Authenticator</u>.</div>
          <div class="justify-center mt-2"><b>Step 2</b></div>
          <div class="justify-center mt-2">You have 2 options for setting up your TOTP for your JEXtream account:</div>
          <li class="justify-center mt-4 ml-8"><b>Option 1</b></li>
          <div class="justify-center mt-2 ml-8">If your TOTP application supports QR code scanning, scan this image</div>
          <v-card width="300px" class="mt-2 ml-8">
            <v-card>
              <v-card-title> </v-card-title>
              <qr-code class="ml-12 pb-7" :text="qrString" :size="200" color="#ffffff" bg-color="#000000"> </qr-code>
            </v-card>
          </v-card>
          <li class="justify-center mt-4 ml-8"><b>Option 2</b></li>
          <div class="justify-center mt-2 ml-8">If QR code scanning is not supported, enter the configuration key shown below manually:</div>
          <v-row class="justify-start mt-2 ml-8" no-gutters>
            <v-col>
              <v-text-field dense v-model="secretKey" style="max-width: 300px" label="Key" outlined></v-text-field>
            </v-col>
          </v-row>
          <div class="justify-center mt-0"><b>Step 3</b></div>
          <div class="justify-center mt-2">Enter an authentication code below and click the Finish button to complete MFA application.</div>
          <v-row class="justify-start mt-2" no-gutters>
            <v-col>
              <v-text-field dense v-model="authCode" style="max-width: 300px" label="Authentication" outlined @keyup.enter="final_step()"></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="justify-start ml-n4">
            <v-btn small depressed outlined color="white" class="blue--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="cancel()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="final_step()"> Finish </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import { mapMutations } from "vuex";

export default {
  props: {
    qrString: String,
    secretKey: String,
  },
  data() {
    return {
      authCode: "",
      loading: false,
    };
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on cancel actions to move to previous step
    cancel() {
      this.$emit("cancel");
    },
    //method invokes on finish action to complete mfa
    async final_step() {
      this.loading = true;
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyTotpToken(user, this.authCode);
        await Auth.setPreferredMFA(user, "TOTP");
        this.$emit("mfaSuccess", true);
      } catch(error){
        this.triggerSnackBar("error", "The authentication code did not match")
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.theme--light.v-card > .card-text.v-card__text {
  color: black;
}
</style>
