<template>
  <v-form ref="form" class="pa-5">
    <v-select ref="activeProfileIndex" class="form-text-box" outlined dense label="Active Profile Index" :items="activeProfileList" v-model="activeProfileIndex" @input="mixinUpdateFormField('connections.apnInfo.activeProfileIndex', `${configPath}.ActiveProfileIndex`, activeProfileIndex)"></v-select>

    <v-select ref="attachedProfileIndex" class="form-text-box" outlined dense label="Attached Profile Index" :items="attachedProfileList" v-model="attachedProfileIndex" @input="mixinUpdateFormField('connections.apnInfo.attachedProfileIndex', `${configPath}.AttachedProfileIndex`, attachedProfileIndex)"></v-select>

    <v-row class="mb-4" no-gutters>
      <v-col cols="10">
        <v-text-field ref="apnList" class="list-proxy-text-field" v-model="apnList" hidden></v-text-field>
      </v-col>
      <v-col class="d-flex justify-center" cols="2">
        <v-btn small @click="open_apn_list_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
      </v-col>
    </v-row>

    <v-data-table show-select single-select dense :items="apnList" :headers="apnListHeaders">

      <template v-slot:item.data-table-select="{ item, index }">
        <v-switch :input-value="item.Active" @click.stop="change_apn_active_state(item, index)" hide-details style="margin: auto;"></v-switch>
      </template>

      <template v-slot:[`item.Active`]="{ item }">
        <v-chip small dark :color="item.Active === 1 ? 'green' : 'red'">{{ item.Active === 1 ? "Active" : "Inactive" }}</v-chip>
      </template>

      <template v-slot:[`item.AuthType`]="{ item }">
        {{ mixinAPNAuthTypeReturn(item.AuthType) }}
      </template>

      <template v-slot:[`item.PDPType`]="{ item }">
        {{ mixinPDPTypeReturn(item.PDPType) }}
      </template>

      <template v-slot:[`item.Actions`]="{ item, index }">
        <v-menu bottom left content-class="menu-offset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="open_apn_list_dialog('edit', item, index)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteFromList(item, index)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

    </v-data-table>
    <!-- other form fields -->
    <ApnListDialog :apnListDialog="apnListDialog" :apnListDialogMode="apnListDialogMode" :apnList="apnList" @close="close_apn_list_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import ApnListDialog from "../SubDialogs/ApnListDialog.vue";
import DeleteDialog from "../../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';


export default {
  mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
  components: {
    ApnListDialog,
    DeleteDialog
  },
  data() {
    return {
      apnListHeaders: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "Active" },
        { text: "Profile Name", sortable: true, class: "headerColor white--text ", value: "ProfileName" },
        { text: "APN Name", sortable: true, class: "headerColor white--text ", value: "APNName" },
        { text: "Authentication Type", sortable: true, class: "headerColor white--text ", value: "AuthType" },
        { text: "PDP Type", sortable: true, class: "headerColor white--text ", value: "PDPType" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      activeProfileList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      attachedProfileList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],

      activeProfileIndex: -1,
      attachedProfileIndex: -1,
      apnList: [], // your table data
      // tableValid: true,
      configPath: "configuration_module.APNInfo.Value",

      apnListDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      apnListDialogMode: {},

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initApnList();
  },
  watch: {
    // The apnList is artificially bound to v-text-field for a proper form validation
    // Since 
    apnList: {
      handler(newVal) {
          // There are cases where APNInfo object doesn't even exist in the config
          let hasAPNInfo = this.getterGetNewDeviceConfigurations.configuration_module.hasOwnProperty("APNInfo");
          this.$nextTick(()=> {
            if(hasAPNInfo || this.apnList.length) this.mixinUpdateFormField('connections.apnInfo.apnList', `${this.configPath}.ProfileList`, this.apnList);
          })
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"])
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // ActiveProfileIndex AttachedProfileIndex
    initApnList() {
      const defaultAPNInfo = this.getterGetNewDeviceConfigurations.configuration_module.APNInfo ? this.getterGetNewDeviceConfigurations.configuration_module.APNInfo.Value : {};

      this.activeProfileIndex = defaultAPNInfo.hasOwnProperty("ActiveProfileIndex") ? defaultAPNInfo.ActiveProfileIndex : -1;
      this.attachedProfileIndex = defaultAPNInfo.hasOwnProperty("AttachedProfileIndex") ? defaultAPNInfo.AttachedProfileIndex : -1;
      this.apnList = defaultAPNInfo.hasOwnProperty("ProfileList") ? [...defaultAPNInfo.ProfileList] : [];
      this.$nextTick(() => {
        this.mixinValidateForm('apnInfo', 'connections');
      });
    },
    apnListValidationRule() {
      return [
        v => v.length > 0 || "The APN List must have at least one item"
      ]
    },
    change_apn_active_state(item, itemIndex){
      this.apnList.forEach((listItem, listIndex) => {
        listItem.Active = (itemIndex === listIndex) && item.Active === 0 ? 1 : 0
      })
    },
    deleteFromList(item, index){
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.apnList.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.apnList.forEach((listItem, index) => {
          listItem.Index = index + 1;
        })
      }
    },
    // a method for both apn edit and add
    open_apn_list_dialog(mode, item, index){
      this.apnListDialog = true;
      this.apnListDialogMode = {
        mode
      }
      if(mode === "edit") {
        this.apnListDialogMode.item = item;
        this.apnListDialogMode.index = index;
      } 
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_apn_list_dialog(dialogObj){
      if(dialogObj.status === 1){
        if(dialogObj.mode === "edit"){
          this.$set(this.apnList, dialogObj.index, dialogObj.item);
        } else if(dialogObj.mode === "add") {
          this.apnList.push(dialogObj.item);
        }
      }
      this.apnListDialog = false;
    }
  },
}
</script>

<style scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
