<template>
  <v-snackbar
    v-model="snackBarProperties.visibility"
    :color="snackBarProperties.color"
    :timeout="snackBarProperties.timeout"
    :style="{ bottom: snackBarProperties.bottomPosition + 'px' }"
    right
    :height="snackBarProperties.height"
    width="400"
    @input="removeSnackBarItem">
    <v-row no-gutters>
      <v-col class="d-flex justify-center align-center" cols="3">
        <v-icon style="display: block" size="40">{{ snackBarProperties.icon }}</v-icon>
      </v-col>
      <v-col cols="9" style="width: 270px">
        <v-row class="content-top" no-gutters>
          <v-spacer></v-spacer>
          <v-btn icon x-small @click="removeSnackBarItem">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row class="content-middle pa-2 d-flex align-center" no-gutters>{{ snackBarProperties.text }}</v-row>
        <v-row class="content-bottom" no-gutters></v-row>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    snackBarProperties: Object,
  },
  computed: {
    ...mapGetters(["getterGetSnackBarItems"]),
  },
  methods: {
    ...mapMutations(["mutationRemoveSnackBarItem", "mutationResetBottomPosition"]),
    removeSnackBarItem() {
      this.mutationRemoveSnackBarItem(this.snackBarProperties.id);
      this.mutationResetBottomPosition();
    },
  },
};
</script>

<style scoped>
.content-top {
    height: 20px;
}

.content-middle {
  height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
  white-space: normal;
  vertical-align: middle;
}

.content-bottom {
    height: 20px;
}
</style>
