<template>
  <section ref="container" flat class="parent-card">
    <div class="total-wrapper">
      <div :class="totalTitleClass">Total Devices</div>
      <div :class="totalValueClass">{{ data.total }}</div>
    </div>
    <div class="online-offline-wrapper middle-wrapper">
      <div>
        <div class="data-title">online</div>
        <div :class="chartValueClass">{{ data.online }}</div>
      </div>
      <div class="doughnut-chart">
        <v-progress-circular :rotate="360" :width="chartThickness" :size="chartSize" :value="onlinePercentage" color="primary" />
      </div>
    </div>
    <div class="online-offline-wrapper">
      <div>
        <div class="data-title">offline</div>
        <div :class="chartValueClass">{{ data.offline }}</div>
      </div>
      <div class="doughnut-chart">
        <v-progress-circular :rotate="360" :width="chartThickness" :size="chartSize" :value="offlinePercentage" color="primary" />
      </div>
    </div>
    <v-fade-transition>
      <div v-if="loading" class="load-cover">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
    </v-fade-transition>
  </section>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: { total: 0, online: 0, offline: 0 } },
    loading: { type: Boolean, default: false },
  },
  data(){
    return {
      totalTitleClass: "",
      totalValueClass: "", 
      chartValueClass: "",
      chartSize: 20,
      chartThickness: 10
    }
  },
  mounted() {
    this.observeContainer();
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  computed: {
    onlinePercentage() {
      return this.data.total > 0 ? (this.data.online / this.data.total) * 100 : 0;
    },
    offlinePercentage() {
      return this.data.total > 0 ? (this.data.offline / this.data.total) * 100 : 0;
    }
  },
  methods: {
    observeContainer() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const width = entry.contentRect.width;

          if (width < 800) {
            this.totalTitleClass = "data-title-sm";
            this.totalValueClass = "data-value-sm";
            this.chartValueClass = "data-value-sm";
            this.chartSize = 60;
            this.chartThickness = 10;
          } else {
            this.totalTitleClass = "data-title-lg";
            this.totalValueClass = "data-value-lg";
            this.chartValueClass = "data-value-lg";
            this.chartSize = 85;
            this.chartThickness = 15;
          }
        }
      });
      this.resizeObserver.observe(this.$refs.container);
    },
  },
};
</script>

<style scoped>
.parent-card {
  position: relative;
  display: block;
  min-width: fit-content;
  border: solid 1px #e5e5ea;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: white;
}

.total-wrapper .data-title-lg {
  font-size: 1.375rem;
  font-weight: 500;
}
.total-wrapper .data-title-sm {
  font-size: 1.125rem;
  font-weight: 500;
}
.total-wrapper .data-value-lg {
  font-size: 4rem;
  font-weight: 500;
}
.total-wrapper .data-value-sm {
  font-size: 3rem;
  font-weight: 500;
}

.online-offline-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-inline: 1rem;
}
.online-offline-wrapper .data-title {
  font-size: 1.125rem;
  font-weight: 500;
}
.online-offline-wrapper .data-value-lg {
  font-size: 3rem;
  font-weight: 500;
}
.online-offline-wrapper .data-value-sm {
  font-size: 1.5rem;
  font-weight: 500;
}


/* 1fr 2fr, 16.6666%/66.6666% = 25% */
.doughnut-chart {
  display: flex;
  align-items: center;
  margin-left: 12.5%;
}

.middle-wrapper {
  position: relative;
}
.middle-wrapper::before,
.middle-wrapper::after {
  position: absolute;
  content: '';
  height: 60%;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #DBDBDB;
}
.middle-wrapper::before {
  left: 0;
}
.middle-wrapper::after {
  right: 0;
}

.load-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  display: grid;
  place-items: center;
}
</style>
