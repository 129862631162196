<template>
  <!-- ref="form" must be present for all forms for validation algorithm to work correctly -->
  <v-form ref="form" class="pa-5">

    <h3 class="blue--text text--darken-1 mb-4">Main Wi-Fi(2.4GHz)</h3>

    <!-- autocomplete="new-password" is a hack to prevent spyware browsers such as Chrome from autocompleting with all the bs they throw at you -->
    <v-text-field ref="wifiName" class="form-text-box" outlined dense label="Wi-Fi SSID" placeholder="Enter your Wi-Fi name" v-model="wifiName" :rules="mixinWifiNameRule()" autocomplete="new-password" @blur="mixinUpdateFormField('wirlessLAN.wifiName', `${configPath}.MainWiFi.WiFiName`, wifiName)"></v-text-field>

    <v-text-field ref="wifiPassword" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Wi-Fi Password" placeholder="Enter your Wi-Fi password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" v-model="wifiPassword" :rules="mixinWifiPasswordRule()" autocomplete="new-password" @blur="mixinUpdateFormField('wirlessLAN.wifiPassword', `${configPath}.MainWiFi.WiFiPassword`, wifiPassword)"></v-text-field>

    <v-select ref="wifiAuth" class="form-text-box" outlined dense label="Wi-Fi Authentication" :items="mixinGetWifiAuthList()" v-model="wifiAuth" @input="mixinUpdateFormField('wirlessLAN.wifiAuth', `${configPath}.MainWiFi.AuthenticationMethod`, wifiAuth)"></v-select>
    
    <h3 class="blue--text text--darken-1 my-4">Guest Wi-Fi(5.0GHz)</h3>

    <v-text-field ref="guestWifiName" class="form-text-box" outlined dense label="Wi-Fi SSID" placeholder="Enter your Wi-Fi name" v-model="guestWifiName" :rules="mixinWifiNameRule()" autocomplete="new-password" @blur="mixinUpdateFormField('wirlessLAN.guestWifiName', `${configPath}.Guest3WiFi.WiFiName`, guestWifiName)"></v-text-field>

    <v-text-field ref="guestWifiPassword" v-show="guestWifiAuth !== 0" class="form-text-box" outlined dense label="Wi-Fi Password" placeholder="Enter your Wi-Fi password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" v-model="guestWifiPassword" :rules="mixinWifiPasswordRule()" autocomplete="new-password" @blur="mixinUpdateFormField('wirlessLAN.guestWifiPassword', `${configPath}.Guest3WiFi.WiFiPassword`, guestWifiPassword)"></v-text-field>

    <v-select ref="guestWifiAuth" class="form-text-box" outlined dense label="Wi-Fi Authentication" :items="mixinGetWifiAuthList()" v-model="guestWifiAuth" @input="mixinUpdateFormField('wirlessLAN.guestWifiAuth', `${configPath}.Guest3WiFi.AuthenticationMethod`, guestWifiAuth)"></v-select>

  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js';
import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';

  export default {
    mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
    data(){
      return {
        // declare/initialize "v-model"ed variables
        wifiName: "",
        wifiPassword: "",
        showPassword: false,
        wifiAuth: -1,
        
        guestWifiName: "",
        guestWifiPassword: "",
        showPassword: false,
        guestWifiAuth: -1,

        configPath: "configuration_module.WirelessLAN.Value"
      }
    },
    created(){
      // set values fetched from the database
      this.initWirelessLAN();
      // console.log(this.$doublejson(this.getterGetNewDeviceConfigurations));
    },
    watch: {
      // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
      '$store.state.newDeviceConfigurations': {
        handler(){
          this.initWirelessLAN();
        },
        deep: true,
      },
      getterGetCurrentError: {
        handler(newVal){
          if(newVal && newVal.path){
            let errorMenu = newVal.path.split('.')[1];
            this.$nextTick(() => {
              if(this.$refs[errorMenu]){
                // This small delay will make sure that the cursor gets focused in correctly
                setTimeout(() => {
                  this.$refs[errorMenu].focus();
                }, 500)
              }
            })
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError"]),
    },
    methods: {
      // intialize all fields with value from vuex
      initWirelessLAN() {
        const defaultWirlessLAN = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value;

        const defaultMainWifi = defaultWirlessLAN.MainWiFi;
        this.wifiName = defaultMainWifi.hasOwnProperty("WiFiName") ? defaultMainWifi.WiFiName : "";
        this.wifiPassword = defaultMainWifi.hasOwnProperty("WiFiPassword") ? defaultMainWifi.WiFiPassword : "";
        this.wifiAuth = defaultMainWifi.hasOwnProperty("AuthenticationMethod") ? defaultMainWifi.AuthenticationMethod : "";
        
        const defaultGuestWifi = defaultWirlessLAN.Guest3WiFi;
        this.guestWifiName = defaultGuestWifi.hasOwnProperty("WiFiName") ? defaultGuestWifi.WiFiName : "";
        this.guestWifiPassword = defaultGuestWifi.hasOwnProperty("WiFiPassword") ? defaultGuestWifi.WiFiPassword : "";
        this.guestWifiAuth = defaultGuestWifi.hasOwnProperty("AuthenticationMethod") ? defaultGuestWifi.AuthenticationMethod : "";

        this.$nextTick(() => {
          this.mixinValidateForm('wirlessLAN', 'wirlessLAN');
        })
      }
    },
  }
</script>