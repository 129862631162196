<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">Firewall</h3>

    <div>
      <v-switch ref="IPv6Firewall" class="form-text-box d-inline-block" dense v-model="IPv6Firewall" hide-details @click="mixinUpdateFormField('security.portWhiteList.IPv6Firewall', `${configPathFirewall}.IPv6Firewall`, IPv6Firewall ? 1 : 0)">
        <template v-slot:label>
          <div>Firewall Switch</div>
        </template>
      </v-switch>
    </div>
    <v-expand-transition>
      <div v-show="IPv6Firewall">
        <PortWhiteList />
      </div>
    </v-expand-transition>
  </v-form>
</template>

<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import PortWhiteList from './PortWhiteList.vue';

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    PortWhiteList,
  },
  data() {
    return {
      // FirewallGeneral
      IPv6Firewall: false,
      configPathFirewall: "configuration_module.FirewallGeneral.Value",
    }
  },
  created(){
    this.initFirewall();
  },
  methods: {
    initFirewall(){
      const defaultFirewallInfo = this.getterGetNewDeviceConfigurations.configuration_module.FirewallGeneral.Value;
      this.IPv6Firewall = defaultFirewallInfo.hasOwnProperty("IPv6Firewall") ? (defaultFirewallInfo.IPv6Firewall === 1 ? true : false) : false;
    }
  }
}
</script>