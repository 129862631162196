<template>
  <v-form ref="form" class="pa-5">

    <h3 class="blue--text text--darken-1 mb-4">DHCP Range</h3>

    <v-text-field ref="DefaultGateway" class="form-text-box" outlined dense label="Default Gateway" v-model="DefaultGateway" :rules="mixinMandatoryIPv4Rule()" autocomplete="new-password" @blur="mixinUpdateFormField('dhcpRange.DefaultGateway', `${configPath}.DHCPServer.DefaultGateway`, DefaultGateway)"></v-text-field>

    <div class="form-text-box form-two-column-grid">
      <v-text-field class="form-text-box" outlined dense v-model="DefaultGateway" autocomplete="new-password" disabled></v-text-field>
      <v-text-field ref="IPAddrStarting4th" class="form-text-box" outlined dense label="IP Pool Starting Address" v-model.number="IPAddrStarting4th" :rules="IPPoolStartingAddress()" autocomplete="new-password" @keypress="mixinNumberOnlyKeyPress($event)" maxlength="3" hide-spin-buttons @blur="mixinUpdateFormField('dhcpRange.IPAddrStarting4th', `${configPath}.DHCPServer.IPAddrStarting4th`, IPAddrStarting4th)"></v-text-field>
    </div>

    <div class="form-text-box form-two-column-grid">
      <v-text-field class="form-text-box" outlined dense v-model="DefaultGateway" autocomplete="new-password" disabled></v-text-field>
      <v-text-field ref="IPAddrEnding4th" class="form-text-box" outlined dense label="IP Pool Ending Address" v-model.number="IPAddrEnding4th" :rules="IPPoolEndingAddress()" autocomplete="new-password" @keypress="mixinNumberOnlyKeyPress($event)" maxlength="3" hide-spin-buttons @blur="mixinUpdateFormField('dhcpRange.IPAddrEnding4th', `${configPath}.DHCPServer.IPAddrEnding4th`, IPAddrEnding4th)"></v-text-field>
    </div>

    <v-text-field ref="LeaseTime" class="form-text-box" outlined dense label="Lease Time(sec)" v-model.number="LeaseTime" :rules="leaseTimeRule()" autocomplete="new-password" @keypress="mixinNumberOnlyKeyPress($event)" maxlength="5" hide-spin-buttons @blur="mixinUpdateFormField('dhcpRange.LeaseTime', `${configPath}.DHCPServer.LeaseTime`, LeaseTime)"></v-text-field>

  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js'
  import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';

  export default {
    mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
    data(){
      return {
        DefaultGateway: "",
        IPAddrStarting4th: null,
        IPAddrEnding4th: null,
        LeaseTime: null,
        
        configPath: "configuration_module.WiredLAN.Value"
      }
    },
    created() {
      this.initWiredLAN();
    },
    watch: {
      // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
      '$store.state.newDeviceConfigurations': {
        handler(){
          this.initWiredLAN();
        },
        deep: true,
      },
      getterGetCurrentError: {
        handler(newVal){
          if(newVal && newVal.path){
            let errorMenu = newVal.path.split('.')[1];
            this.$nextTick(() => {
              if(this.$refs[errorMenu]){
                // This small delay will make sure that the cursor gets focused in correctly
                // the 500ms delay also prevents wierd UI glitch that happens in a split second,
                // which probably happens because it becomes mixed with fade in/out animation of the forms
                setTimeout(() => {
                  this.$refs[errorMenu].focus();
                }, 500)
              }
            })
          }
        },
        immediate: true,
        deep: true
      },
      // This is very specific to RG models. If the DefaultGateway value changes, the IPAddress value should also change
      DefaultGateway: {
        handler(newVal) {
          this.$nextTick(() => {
            this.mixinUpdateFormField('dhcpRange.DefaultGateway', `${this.configPath}.IPAddress`, newVal);
          })
        },
        immediate: true
      }
    },
    computed: {
      ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError"]),
    },
    methods: {
      initWiredLAN(){
        const defaultWiredLAN = this.getterGetNewDeviceConfigurations.configuration_module.WiredLAN.Value;
        this.DefaultGateway = defaultWiredLAN.DHCPServer.hasOwnProperty("DefaultGateway") ? defaultWiredLAN.DHCPServer.DefaultGateway : -1;
        this.IPAddrStarting4th = defaultWiredLAN.DHCPServer.hasOwnProperty("IPAddrStarting4th") ? defaultWiredLAN.DHCPServer.IPAddrStarting4th : -1;
        this.IPAddrEnding4th = defaultWiredLAN.DHCPServer.hasOwnProperty("IPAddrEnding4th") ? defaultWiredLAN.DHCPServer.IPAddrEnding4th : -1;
        this.LeaseTime = defaultWiredLAN.DHCPServer.hasOwnProperty("LeaseTime") ? defaultWiredLAN.DHCPServer.LeaseTime : -1;

        this.$nextTick(() => {
          this.mixinValidateForm('dhcpRange', 'dhcpRange');
        })
      },
      IPPoolStartingAddress() {
        return [
          value => this.IPOctetRegex.test(value) || "Address octet must be a number between 0~255",
          value => this.IPAddrEnding4th > value || "Starting IP octet must be less than ending octet"
        ]
      },
      IPPoolEndingAddress(){
        return [
          value => this.IPOctetRegex.test(value) || "Address octet must be a number between 0~255",
          value => this.IPAddrStarting4th < value || "Ending IP octet must be greater than starting octet"
        ]
      },
      leaseTimeRule(){
        return [
          value => (this.mixinIsDefinedAndNotNull(value) && value !== "") || "Lease time is required(sec)",
        ]
      }
    }
  }
</script>

