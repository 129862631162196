import { jsonDeepDiffList } from "json-deep-diff-list";
import HelpTable from "./HelpTable.json"

const state = {
  helpObject: {},
};

const getters = {
  // This is used for both dashboard info and router count
  getterGetHelp: (state) => {
    return state.helpObject;
  },
};

const mutations = {
  mutationSetHelp: (state, { model, path }) => {
    const pathArray = path.split(".");
    let helpString = HelpTable[model];

    pathArray.forEach(subPath => {
      helpString = helpString[subPath] ? helpString[subPath] : {};
    })

    state.helpObject = helpString;
  },
};

export default {
  state,
  getters,
  mutations,
};
