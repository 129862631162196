const TOTAL_MFA_LOGIN_ATTEMPTS = 4;

const state = {
    mfaLoginWaitTime: 0,
    mfaFailedLoginAttemptCounter: 0,
    mfaLoginStatus: {}
}

const getters = {
  getterGetMfaLoginStatus: (state) => {
      return state.mfaLoginStatus;
  }
}

const mutations = {
  mutationSetMfaWaitTime: (state, payload) => {
      state.mfaLoginWaitTime = Date.now() + (15 * 60 * 1000);
  },
  mutationSetMfaLoginStatus: (state, currentTime) => {
      const timeDiff = ((state.mfaLoginWaitTime - currentTime)/(1000 * 60)).toFixed(0);
      state.mfaLoginStatus.isLoginAvailable = timeDiff > 0 ? false : true;
      state.mfaLoginStatus.timeLeft = timeDiff;
      state.mfaLoginStatus.loginAttemptsLeft = TOTAL_MFA_LOGIN_ATTEMPTS - state.mfaFailedLoginAttemptCounter;
  },
  mutationSetMfaLoginAttemptCounter: (state, resetCounter) => {
      if(resetCounter){
          state.mfaFailedLoginAttemptCounter = 0;
      } else {
          state.mfaFailedLoginAttemptCounter = state.mfaFailedLoginAttemptCounter + 1;
      }
  },
}

const actions = {
  actionSetMfaFailedLoginAttempt: (context, payload) => {
      // If 5 login attempts reached or expiration time has passed
      // Reset the failed attempt counter to 0 and set the time to 15 minutes
      if(context.state.mfaFailedLoginAttemptCounter >= 4){
          context.commit("mutationSetMfaWaitTime");
          context.commit("mutationSetMfaLoginAttemptCounter", true);
      } else {
          context.commit("mutationSetMfaLoginAttemptCounter", false);
      }

  }
}

export default {
  state,
  getters,
  mutations,
  actions
}