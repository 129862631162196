<!-- Cera 05/01/23 -->
<!-- Rewrote the code entirely and commented out previous code -->
<template>
  <div>
    <v-app class="wrapper">
      <v-card v-if="hasMoreThanOneModel" flat class="dashboardCard mt-16 dashboard-background" :class="{ 'impersonate-top-margin': impersonate, 'mt-16': !impersonate }">
        <v-row class="mx-4 mt-12 mb-6" dense>
          <v-col cols="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="multiple-models" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinChangeRoute('/Home/DeviceGroupsList')">
                <v-row class="d-flex justify-center pt-6 mb-2 count">
                  <div class="d-flex align-end text-h2 dashboardCount--text">
                    <h4>{{ group }}</h4>
                  </div>
                </v-row>
                <p class="d-flex justify-center cardText">Groups</p>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="multiple-models" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinChangeRoute('/Home/BusinessUsers')">
                <v-row class="d-flex justify-center pt-6 mb-2 count">
                  <div class="d-flex align-end text-h2 dashboardCount--text">
                    <h4>{{ businessUsers }}</h4>
                  </div>
                </v-row>
                <p class="d-flex justify-center cardText">Users</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <div v-for="(router, index) in routers">
          <v-card-title class="dashboard-font">
            <b class="model-name">{{ modelToName(router.model) }}</b>
          </v-card-title>
          <v-row class="mx-4" dense>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="multiple-models" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model) })">
                  <v-row class="d-flex justify-center pt-6 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardCount--text">
                      <h4>{{ router.total }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Total</p>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="multiple-models" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model), status: 'ONLINE' })">
                  <v-row class="d-flex justify-center pt-6 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardOnline--text">
                      <h4>{{ router.online }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Online</p>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="multiple-models" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model), status: 'OFFLINE' })">
                  <v-row class="d-flex justify-center pt-6 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardOffline--text">
                      <h4>{{ router.offline }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Offline</p>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <!-- Cera 05/02/23 -->
      <!-- Single model view -->
      <v-card v-else flat class="dashboardCard dashboard-background overflow-visible" :class="{ 'impersonate-top-margin': impersonate, 'mt-16': !impersonate }">
        <v-row class="mx-4 mt-12 mb-6" dense>
          <v-col cols="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="single-model" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinChangeRoute('/Home/DeviceGroupsList')">
                <v-row class="d-flex justify-center pt-10 mb-2 count">
                  <div class="d-flex align-end text-h2 dashboardCount--text">
                    <h4>{{ group }}</h4>
                  </div>
                </v-row>
                <p class="d-flex justify-center cardText">Groups</p>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="single-model" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinChangeRoute('/Home/BusinessUsers')">
                <v-row class="d-flex justify-center pt-10 mb-2 count">
                  <div class="d-flex align-end text-h2 dashboardCount--text">
                    <h4>{{ businessUsers }}</h4>
                  </div>
                </v-row>
                <p class="d-flex justify-center cardText">Users</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <div v-for="(router, index) in routers">
          <v-card-title class="dashboard-font"
            ><b class="model-name">{{ modelToName(router.model) }}</b></v-card-title
          >
          <v-row class="mx-4" dense>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="single-model" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model) })">
                  <v-row class="d-flex justify-center pt-10 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardCount--text">
                      <h4>{{ router.total }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Total</p>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="single-model" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model), status: 'ONLINE' })">
                  <v-row class="d-flex justify-center pt-10 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardOnline--text">
                      <h4>{{ router.online }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Online</p>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card class="single-model" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinDataChangeRoute('CustomerDeviceList', { model: modelToName(router.model), status: 'OFFLINE' })">
                  <v-row class="d-flex justify-center pt-10 mb-2 count">
                    <div class="d-flex align-end text-h2 dashboardOffline--text">
                      <h4>{{ router.offline }}</h4>
                    </div>
                  </v-row>
                  <p class="d-flex justify-center cardText">Offline</p>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_routing_utils } from "@/mixins/routingUtils.js"
import { mapGetters } from "vuex";
import { router_group_list, list_Users, customer_admin_dashboard_details } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  mixins: [router_model_utils, mixin_routing_utils],
  data: () => {
    return {
      customers: 0,
      users: 0,
      businessUsers: 0,
      custID: "",
      group: 0,
      totalRouter: {},
      routers: [],
    };
  },
  created() {
    this.get_customer_data();
    this.get_group_list();
    this.get_user_list();
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetIsImpersonating"]),
    impersonate() {
      return this.getterGetIsImpersonating;
    },
    hasMoreThanOneModel() {
      let numberOfModelHavingRouter = 0;
      for (const key in this.totalRouter) {
        if (this.totalRouter[key].total_router > 0) {
          numberOfModelHavingRouter++;
        }
      }

      return numberOfModelHavingRouter > 1;
    },
  },
  methods: {
    async get_customer_data() {
      try {
        let result = await API.graphql(
          graphqlOperation(customer_admin_dashboard_details, {
            input: {
              customer_id: this.getterGetUserInfo.user.customer_id,
            },
          })
        );
        var response = JSON.parse(result.data.customer_admin_dashboard_details);
        var responseArray = Object.keys(response);

        responseArray.forEach((element) => {
          if (element.endsWith("Model")) {
            this.routers.push({
              model: element,
              online: response[element].online_routers,
              offline: response[element].offline_routers,
              registered: response[element].registered_routers,
              total: response[element].total_router,
            });
          }
        });

        this.totalRouter = this.extractModelCounts(response);
        this.custID = this.getterGetUserInfo.customer.customer_id;
      } catch (error) {}
    },

    async get_group_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(router_group_list, {
            input: {
              customer_id: this.getterGetUserInfo.user.customer_id,
              user_role: this.getterGetUserInfo.user.user_role,
              limit: 100,
              user_id: this.getterGetUserInfo.user.user_id,
              router_group_id: this.getterGetUserInfo.user.router_group_id,
            },
          })
        );
        var response = JSON.parse(result.data.router_group_list).response;
        this.group = response.items.filter(group => group.group_status === "ACTIVE").length;
      } catch (error) {}
    },

    async get_user_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(list_Users, {
            input: {
              user_role: this.getterGetUserInfo.user.user_role,
              customer_id: this.getterGetUserInfo.customer.customer_id,
            },
          })
        );
        var response = JSON.parse(result.data.list_Users).business;
        this.businessUsers = response.length;
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin-top: -64px;
}
.dashboard-background {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  padding-top: 70px;
}
.dashboardCard {
  margin-top: 50px;
}
.multiple-models {
  height: 18vh;
  min-height: 110px;
  position: relative;
}
.single-model {
  height: 16vh;
  min-height: 190px;
  position: relative;
}
.cardText {
  font-size: 1.3rem;
  color: #757575;
  padding-top: 4px;
}
.model-name {
  font-size: 1.5rem;
}
.count {
  height: 55%;
}

.impersonate-top-margin {
  margin-top: 96px !important;
}
</style>
