<template>
  <!-- ref="form" must be present for all forms for validation algorithm to work correctly -->
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">Guest Wi-Fi</h3>

    <div class="d-flex align-center">
      <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFiEnable')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-switch ref="guestWifiEnable" class="form-text-box d-inline-block mb-5" dense v-model="guestWifiEnable" hide-details @click="mixinUpdateFormField('guestWifi.guestWifiEnable', `${configPath}.Guest3WiFiEnable`, guestWifiEnable ? 1 : 0)">
        <template v-slot:label>
          <div>Enable Guest Wi-Fi</div>
        </template>
      </v-switch>
    </div>

    <div v-show="guestWifiEnable">
      <v-select ref="wifiBands" class="form-text-box" outlined dense label="Wi-Fi Band" :items="guestWifiBandsList()" v-model="wifiBands" @input="mixinUpdateFormField('guestWifi.wifiBands', `${configPath}.Guest3WiFi.RadioBand`, wifiBands)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.RadioBand')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>

      <!-- autocomplete="new-password" is a hack to prevent spyware browsers such as Chrome from autocompleting with all the bs they throw at you -->
      <!-- Chrome/Google wants to invade users' privacy no matter what -->
      <v-text-field ref="wifiName" class="form-text-box" outlined dense label="Wi-Fi SSID" placeholder="Enter your Wi-Fi name" v-model="wifiName" :rules="mixinWifiNameRule()" autocomplete="new-password" @blur="mixinUpdateFormField('guestWifi.wifiName', `${configPath}.Guest3WiFi.WiFiName`, wifiName)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.WiFiName')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-text-field>

      <v-text-field ref="wifiPassword" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Wi-Fi Password" placeholder="Enter your Wi-Fi password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" v-model="wifiPassword" :rules="mixinWifiPasswordRule()" autocomplete="new-password" @blur="mixinUpdateFormField('guestWifi.wifiPassword', `${configPath}.Guest3WiFi.WiFiPassword`, wifiPassword)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.WiFiPassword')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-text-field>

      <!-- <v-select ref="wifiEncryption" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Encryption Method" :items="mixinGetWifiEncryptionList()" v-model="wifiEncryption" @input="mixinUpdateFormField('guestWifi.wifiEncryption', `${configPath}.Guest3WiFi.EncryptionMethod`, wifiEncryption)"></v-select> -->

      <v-select ref="wifiAuth" class="form-text-box" outlined dense label="Authentication Method" :items="mixinGetWifiAuthList(wifiBands)" v-model="wifiAuth" @input="           mixinUpdateFormField('guestWifi.wifiAuth', `${configPath}.Guest3WiFi.AuthenticationMethod`, wifiAuth); setEncryptionVal();">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.AuthenticationMethod')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>

      <div class="d-flex align-center mt-2">
        <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.PrivacySeparator')">
          <UnchangedGroupConfigIcon />
        </div>
        <v-switch ref="privacySeparator" class="form-text-box d-inline-block" dense v-model="privacySeparator" hide-details @click="mixinUpdateFormField('guestWifi.privacySeparator', `${configPath}.Guest3WiFi.PrivacySeparator`, privacySeparator ? 1 : 0)">
          <template v-slot:label>
            <div>Privacy Separator</div>
          </template>
        </v-switch>
      </div>

      <div class="d-flex align-center mt-4">
        <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.SSIDStealth')">
          <UnchangedGroupConfigIcon />
        </div>
        <v-switch ref="ssidStealth" class="form-text-box d-inline-block" dense v-model="ssidStealth" hide-details @click="mixinUpdateFormField('guestWifi.ssidStealth', `${configPath}.Guest3WiFi.SSIDStealth`, ssidStealth ? 1 : 0)">
          <template v-slot:label>
            <div>SSID Stealth</div>
          </template>
        </v-switch>
      </div>

      <v-select ref="wifiMode" class="form-text-box mt-8" outlined dense label="Wi-Fi Mode" :items="mixinGetGuestWifiModeList(wifiBands)" v-model="mode" @input="mixinUpdateFormField('guestWifi.wifiMode', `${configPath}.Guest3WiFi.Mode`, mode)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.Mode')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>

      <v-select ref="wifiBandwidth" class="form-text-box" outlined dense label="Wi-Fi Bandwidth" :items="mixinGetWifiBandwidthList(wifiBands)" v-model="bandwidth" @input="mixinUpdateFormField('guestWifi.wifiBandwidth', `${configPath}.Guest3WiFi.Bandwidth`, bandwidth)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.Bandwidth')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>

      <v-select ref="wifiChannels" class="form-text-box" outlined dense label="Wi-Fi Channels" :items="mixinGetWifiChannelList(wifiBands, getMainWifiBand, bandwidth)" v-model="channels" @input="mixinUpdateFormField('guestWifi.wifiChannels', `${configPath}.Guest3WiFi.Channels`, channels)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.Channels')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    UnchangedGroupConfigIcon,
  },
  data() {
    return {
      // declare/initialize "v-model"ed variables
      guestWifiEnable: 0,
      wifiBands: -1,
      wifiName: "",
      wifiPassword: "",
      showPassword: false,
      wifiAuth: -1,
      wifiEncryption: -1,
      privacySeparator: false,
      ssidStealth: false,
      configPath: "configuration_module.WirelessLAN.Value",

      // advanced settings
      mode: -1,
      bandwidth: -1,
      channels: -1,
    };
  },
  created() {
    // set values fetched from the database
    this.initGuestWifi();
  },
  watch: {
    "$store.state.device.newDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand": {
      handler(newVal) {
        if(newVal === this.wifiBands) {
          const exclusiveArray = [0, 1, 2].filter(num => num !== newVal)
          const guestRadioBand = Math.min(...exclusiveArray);
          this.wifiBands = guestRadioBand;
          this.$nextTick(() => {
            this.mixinUpdateFormField("guestWifi.wifiBands", `${this.configPath}.Guest3WiFi.RadioBand`, guestRadioBand);
          })
        }
      },
      deep: true,
    },
    // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
    "$store.state.device.newDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand": {
      handler() {
        this.setAuthentication();
        this.setMode();
        this.setBandWidth();
        this.setChannels();
      },
      deep: true,
    },
    "$store.state.device.newDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.AuthenticationMethod": {
      handler(){
        this.setEncryption();
      },
    },
    "$store.state.device.newDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.Bandwidth": {
      handler(){
        this.setChannels();
      },
    },
    getterGetCurrentError: {
      handler(newVal) {
        if (newVal && newVal.path) {
          let errorMenu = newVal.path.split(".")[1];
          this.$nextTick(() => {
            if (this.$refs[errorMenu]) {
              // This small delay will make sure that the cursor gets focused in correctly
              setTimeout(() => {
                this.$refs[errorMenu].focus();
              }, 500);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    }
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError"]),
    getMainWifiBand() {
      return this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand
    },
  },
  methods: {
    ...mapMutations(["mutationUpdateConfigurations", "mutationDiffConfiguration"]),
    // change wifi encryption value to 0 automatically if
    setEncryptionVal() {
      // if(this.wifiAuth === 0){
      //   this.wifiEncryption = 0;
      //   this.mixinUpdateFormField('guestWifi.wifiEncryption', `${this.configPath}.Guest3WiFi.EncryptionMethod`, this.wifiEncryption)
      // } else if(this.wifiAuth !== 0 && this.wifiEncryption === 0) {
      //   this.wifiEncryption = 1;
      //   this.mixinUpdateFormField('guestWifi.wifiEncryption', `${this.configPath}.Guest3WiFi.EncryptionMethod`, this.wifiEncryption)
      // }
    },
    // This is used specifically for RG3100/3102 models
    // Main Wi-Fi and Guest Wi-Fi band frequencies cannot be equal to each other.
    // If Main Wi-Fi is 6.0GHz for example, 6.0GHz selection will be removed from guest Wi-Fi band selection
    guestWifiBandsList() {
      const wifiBands = [
        { text: "2.4GHz", value: 0 },
        { text: "5.0GHz", value: 1 },
        { text: "6.0GHz", value: 2 },
      ];
      return wifiBands;
    },
    // intialize all fields with value from vuex
    initGuestWifi() {
      const defaultWirlessLAN = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value;
      const defaultGuestWifi = defaultWirlessLAN.Guest3WiFi;

      this.guestWifiEnable = defaultWirlessLAN.hasOwnProperty("Guest3WiFiEnable") ? defaultWirlessLAN.Guest3WiFiEnable : -1;

      this.wifiBands = defaultGuestWifi.hasOwnProperty("RadioBand") ? defaultGuestWifi.RadioBand : -1;
      this.wifiName = defaultGuestWifi.hasOwnProperty("WiFiName") ? defaultGuestWifi.WiFiName : "";
      this.wifiPassword = defaultGuestWifi.hasOwnProperty("WiFiPassword") ? defaultGuestWifi.WiFiPassword : "";
      this.wifiAuth = defaultGuestWifi.hasOwnProperty("AuthenticationMethod") ? defaultGuestWifi.AuthenticationMethod : "";
      this.wifiEncryption = defaultGuestWifi.hasOwnProperty("EncryptionMethod") ? defaultGuestWifi.EncryptionMethod : -1;
      this.privacySeparator = defaultGuestWifi.hasOwnProperty("PrivacySeparator") ? (defaultGuestWifi.PrivacySeparator ? true : false) : false;
      this.ssidStealth = defaultGuestWifi.hasOwnProperty("SSIDStealth") ? (defaultGuestWifi.SSIDStealth ? true : false) : false;

      this.mode = defaultGuestWifi.hasOwnProperty("Mode") ? defaultGuestWifi.Mode : -1;
      this.bandwidth = defaultGuestWifi.hasOwnProperty("Bandwidth") ? defaultGuestWifi.Bandwidth : -1;
      this.channels = defaultGuestWifi.hasOwnProperty("Channels") ? defaultGuestWifi.Channels : -1;

      this.$nextTick(() => {
        this.mixinValidateForm("guestWifi", "guestWifi");
      });
    },
    setAuthentication(){
      if(this.wifiBands < 2){
        this.wifiAuth = 3;
      } else {
        this.wifiAuth = 1;
      }
      this.$nextTick(() => {
        this.mixinUpdateFormField("guestWifi.wifiAuth", `${this.configPath}.Guest3WiFi.AuthenticationMethod`, this.wifiAuth);
      });
    },
    //
    setEncryption() {
      if(this.wifiBands === 2){
        this.wifiEncryption = 0;
      } else {
        if(this.wifiAuth === 3) this.wifiEncryption = 1;
        else this.wifiEncryption = 0;
      }
      this.$nextTick(() => {
        this.mixinUpdateFormField("guestWifi.wifiAuth", `${this.configPath}.Guest3WiFi.EncryptionMethod`, this.wifiEncryption);
      });
    },
    // Setting for mode default relies on WiFi band
    setMode() {
      this.mode = 0;
      this.$nextTick(() => {
        this.mixinUpdateFormField("guestWifi.wifiMode", `${this.configPath}.Guest3WiFi.Mode`, this.mode);
      });
    },
    // Setting for bandwith default relies on WiFi band
    setBandWidth() {
      if(this.wifiBands === 0 || this.wifiBands === 1) {
        this.bandwidth = 1;
        this.$nextTick(() => {
          this.mixinUpdateFormField("guestWifi.wifiBandwidth", `${this.configPath}.Guest3WiFi.Bandwidth`, this.bandwidth);
        });
      }
      if(this.wifiBands === 2) {
        this.bandwidth = 0;
        this.$nextTick(() => {
          this.mixinUpdateFormField("guestWifi.wifiBandwidth", `${this.configPath}.Guest3WiFi.Bandwidth`, this.bandwidth);
        });
      }
    },
    // Setting for channel default relies on WiFi band and Bandwidth
    setChannels() {
      this.channels = 0;
      this.$nextTick(() => {
        this.mixinUpdateFormField("guestWifi.wifiChannels", `${this.configPath}.Guest3WiFi.Channels`, this.channels);
      });
    }
  },
};
</script>
