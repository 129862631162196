<template>
  <v-card>
    <v-card-title class="blue lighten-2" style="padding-block: 0.2rem; padding-inline: 0.8rem; font-size: 1rem">
      <v-icon class="mr-1" color="white" size="16">mdi-{{ modalIcon }}</v-icon>
      <span class="white--text mr-2">{{ modalTitle }}</span>
      <span v-if="subTitle" class="font-italic grey--text text--lighten-3">- {{ subTitle }}</span>
      <v-spacer></v-spacer>
      <slot name="header"></slot>
    </v-card-title>

    <v-card-text class="pt-5">
      <v-form>
        <slot name="body"></slot>
      </v-form>
    </v-card-text>

    <v-card-actions class="pa-3" style="border-top: 1px solid rgb(199, 199, 199)">
      <v-spacer></v-spacer>
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    modalIcon: String,
    modalTitle: String,
    subTitle: String
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
