<template>
  <div class="datepicker-container">
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ attrs, on }">
      <v-text-field v-model="formattedDateRange" placeholder="Select Period" persistent-hint dense outlined readonly hide-details append-icon="mdi-chevron-down" background-color="white" v-bind="attrs" v-on="on" @click:append="menu = !menu">
        <template v-slot:prepend-inner style="align-self: center;">
          <v-img class="mx-1" src="@/assets/calendar_month.svg" alt="" />
        </template>
      </v-text-field>
    </template>
    <v-fade-transition>
      <div class="calendar">

        <!-- Mode Selection (Start / End) -->
        <section class="calendar-header">
          <!-- From -->
          <div class="date-selection-mode-panel start" @click="mode = 'start'" :class="{ active: mode === 'start' }">
            <div :style="{ color: mode === 'start' ? '#0195d2' : 'inherit' }">From</div>
            <div>
              <span :style="{ color: startDate ? '#484852' : 'inherit' }">{{ startDate ? format(startDate, "MM-dd-yyyy") : "Select a Date" }}</span>
            </div>
          </div>
          <!-- To -->
          <div class="date-selection-mode-panel end" @click="mode = 'end'" :class="{ active: mode === 'end' }">
            <div :style="{ color: mode === 'end' ? '#0195d2' : 'inherit' }">To</div>
            <div>
              <span :style="{ color: endDate ? '#484852' : 'inherit' }">{{ endDate ? format(endDate, "MM-dd-yyyy") : "Select a Date" }}</span>
            </div>
          </div>
        </section>

        <!-- Month Navigation -->
        <section class="calendar-month-year">
          <!-- <span @click="changeMonth(-1)">‹</span> -->
          <v-btn icon small class="mr-2" @click="changeMonth(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
          <span class="month-year-text">{{ format(currentMonth, "MMMM yyyy") }}</span>
          <v-btn icon small class="ml-2" @click="changeMonth(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
          <!-- <span @click="changeMonth(1)">›</span> -->
        </section>

        <!-- Days of the Week -->
        <section class="calendar-grid">
          <span class="days-of-week" v-for="day in daysOfWeek" :key="day">{{ day }}</span>

          <!-- previous month -->
          <span class="day-background empty" v-for="day in previousMonthDays" :key="'prev-' + day"></span>

          <!-- current month -->
          <span class="day-background" v-for="day in daysInMonth" :key="'filled-' + day" :class="dayClass(day)" @click="selectDate(day)">
            <button>{{ format(day, "d") }}</button>
          </span>

          <!-- next month -->
          <span class="day-background empty" v-for="day in nextMonthDays" :key="'next-' + day"></span>
        </section>

        <section class="calendar-footer mt-6">
          <v-btn class="mr-2" small text depressed @click="menu = false">Cancel</v-btn>
          <v-btn small dark depressed color="#0195d2" @click="menu = false">Apply</v-btn>
        </section>
      </div>
    </v-fade-transition>
    <!-- Calendar UI -->
  </v-menu>
  </div>
</template>

<script>
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, addDays, isSameDay, getDay, subDays } from "date-fns";
// Whoever gets this source, I ain't writing any documentation so jokes on you!!
export default {
  data() {
    return {
      format,
      mode: "start", // Can be 'start' or 'end'
      startDate: null,
      endDate: null,
      currentMonth: new Date(),

      menu: false,
    };
  },
  computed: {
    formattedDateRange() {
      if (!this.startDate && !this.endDate) return null;
      return `${this.startDate ? format(this.startDate, "yyyy-MM-dd") : "Start"} - ${this.endDate ? format(this.endDate, "yyyy-MM-dd") : "End"}`;
    },
    daysOfWeek() {
      return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    },
    daysInMonth() {
      return eachDayOfInterval({
        start: startOfMonth(this.currentMonth),
        end: endOfMonth(this.currentMonth),
      });
    },
    firstDayOffset() {
      return getDay(startOfMonth(this.currentMonth)); // Get the weekday index of the first day
    },
    previousMonthDays() {
      const prevMonth = addMonths(this.currentMonth, -1);
      const lastDayPrevMonth = endOfMonth(prevMonth);
      const daysToShow = this.firstDayOffset; // Number of days to fill
      return daysToShow === 0 ? [] : eachDayOfInterval({ start: subDays(lastDayPrevMonth, daysToShow - 1), end: lastDayPrevMonth });
    },
    nextMonthDays() {
      const totalDays = 42; // Always keep 6 rows (7 days * 6 rows)
      const currentDays = this.firstDayOffset + this.daysInMonth.length; // Offset + current month days
      const daysToShow = totalDays - currentDays; // Remaining spaces
      return eachDayOfInterval({ start: addMonths(startOfMonth(this.currentMonth), 1), end: addDays(addMonths(startOfMonth(this.currentMonth), 1), daysToShow - 1) });
    },
  },
  methods: {
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    changeMonth(direction) {
      this.currentMonth = addMonths(this.currentMonth, direction);
    },
    selectDate(day) {
      // reset start and end date and set to start mode
      if(this.mode ==="idle"){
        this.startDate = null;
        this.endDate = null;
        this.mode = "start";
      }
      // handle start date selection
      if (this.mode === "start") {
        if (this.endDate && day > this.endDate) {
          this.startDate = this.endDate;
          this.endDate = day;
        } else {
          this.startDate = day;
        }
        this.mode = "end";
      } else {
        // handle end date selection
        if (this.startDate && day < this.startDate) {
          this.endDate = this.startDate;
          this.startDate = day;
        } else {
          this.endDate = day;
        }
        this.mode = "idle";
      }
    },
    dayClass(day) {
      const today = new Date();
      return {
        selected: isSameDay(day, this.startDate) || isSameDay(day, this.endDate),
        range: this.startDate && this.endDate && day > this.startDate && day < this.endDate,
        startDay: isSameDay(day, this.startDate) && this.startDate !== this.endDate && this.startDate && this.endDate,
        endDay: isSameDay(day, this.endDate) && this.startDate !== this.endDate && this.startDate && this.endDate,
        today: isSameDay(day, today)
      };
    },
  },
};
</script>

<style scoped>
* {
  --date-selection-panel-border-radius: 0.75rem;
}

::v-deep .v-input__prepend-inner {
  margin-top: 0 !important;
  align-self: center;
}

/* calendar body */
.calendar {
  /* position: absolute; */
  background: white;
  padding: 1rem;
  border-radius: 0.35rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* header styling */
.calendar-header {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}
.calendar-header::after {
  content: '';
  position: absolute;
  height: 60%;
  border: 1px solid #E0DFE0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.date-selection-mode-panel {
  display: grid;
  row-gap: 0.5rem;
  padding: 0.65rem;
  cursor: pointer;
  position: relative;
  background-color: #f4f4f4;
  color: #939393;
  transition: 0.3s;
}
.date-selection-mode-panel.start {
  border-top-left-radius: var(--date-selection-panel-border-radius);
  border-bottom-left-radius: var(--date-selection-panel-border-radius);
}
.date-selection-mode-panel.end {
  border-top-right-radius: var(--date-selection-panel-border-radius);
  border-bottom-right-radius: var(--date-selection-panel-border-radius);
}
.date-selection-mode-panel.active {
  background-color: transparent;
}
.date-selection-mode-panel.active::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  outline: 2px #0195d2 solid;
  z-index: 1;
}

/* Month-Year selection panel */
.calendar-month-year {
  display: flex;
  align-items: center;
  padding-block: 1rem;
}
.month-year-text {
  font-size: 1.5rem;
  text-align: center;
  width: 11.1rem;
}

/* calendar grid wrapper */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 0.5rem;
  text-align: center;
}
/* Days of week: "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" */
.days-of-week {
  text-transform: uppercase;
  color: #939393;
  font-weight: 400;
}

.calendar-grid .day-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  height: 28px;
  width: 50px;
  color: #484852;
}
/* prevent clicks */
.calendar-grid .day-background.empty {
  pointer-events: none;
}
.calendar-grid .day-background button {
  display: inline-block;
  border-radius: 50%;
  height: 100%;
  aspect-ratio: 1;
  transition: 0.3s;
}
.calendar-grid .day-background.today{
  color: #0195D2;
}
.calendar-grid .day-background button:hover {
  background-color: rgba(1, 147, 210, 0.5);
}
.calendar-grid .day-background.selected button {
  background: #0195d2;
  color: white;
}
.calendar-grid .day-background.range {
  background: rgba(1, 149, 210, 0.1);
}
/* This is for coloring the backgrounds of start or end day selections */
/* The left side of the start date should not be colored and end date vice versa */
.calendar-grid .day-background.startDay {
  background: linear-gradient(to left, rgba(1, 149, 210, 0.1) 50%, transparent 50%);
}
.calendar-grid .day-background.endDay {
  background: linear-gradient(to right, rgba(1, 149, 210, 0.1) 50%, transparent 50%);
}

/* Footer styling */
.calendar-footer {
  display: flex;
  justify-content: flex-end;
  padding-inline: 1rem;
}
</style>
