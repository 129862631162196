<template>
  <!-- Note that the color of the card is set to transparent because v-divider in "ListCard.vue" wouldn't show up -->
  <!-- on the very first item otherwise -->
  <v-card :class="{'mb-4': (type === 'single'), 'pa-2': (type === 'single')}" color="transparent" flat>
    <div class="my-2">
      <v-row no-gutters>
        <v-col cols="5" class="pr-3">
          <span class="font-weight-bold">{{ propertyNameTable[getPath(diff.path)].name }}</span>
        </v-col>
        <v-col cols="5">
          <span v-if="diff.type === 'add'">{{ mixinGetPropertyValue(propertyNameTable, getPath(diff.path), diff.after, diff.relatedAfter) }}</span>
          <span v-else-if="diff.type === 'delete'" class="grey--text">{{ mixinGetPropertyValue(propertyNameTable, getPath(diff.path), diff.before, diff.relatedBefore) }}</span>
          <span v-else-if="diff.type === 'replace'" class="text-decoration-line-through grey--text">{{ mixinGetPropertyValue(propertyNameTable, getPath(diff.path), diff.before, diff.relatedBefore) }}</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-img v-if="diff.type === 'add'" src="@/assets/plus.svg" height="16" width="16" contain></v-img>
          <v-img v-if="diff.type === 'delete'" src="@/assets/trash.svg" height="16" width="16" contain></v-img>
        </v-col>
      </v-row>
      <v-row v-if="diff.type === 'replace'" no-gutters>
        <v-col cols="5" class="pr-3"></v-col>
        <v-col cols="5">
          <span>{{ mixinGetPropertyValue(propertyNameTable, getPath(diff.path), diff.after,  diff.relatedAfter) }}</span>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-img v-if="diff.type === 'replace'" src="@/assets/edit.svg" height="16" width="16" contain></v-img>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import { diff } from "deep-diff";

  export default {
    mixins: [mixin_config_history_utils],
    props: {
      type: String,
      diff: Object,
      propertyNameTable: Object
    },
    // watch: {
    //   diff: {
    //     handler(newVal){
    //       console.log(this.$doublejson(newVal));
    //     },
    //     immediate: true
    //   }
    // },
    methods: {
      getPath(path){
        return this.type === "single" ? path : this.mixinSplitPropName(path);
      }
    }
  }
</script>
