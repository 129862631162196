<template>
  <v-form ref="form" class="pa-5">

    <h3 class="blue--text text--darken-1 mb-4">Control</h3>

    <v-select ref="FactoryResetButtonEnable" class="form-text-box" outlined dense label="Factory Reset Button" :items="mixinGetEnableList()" v-model="FactoryResetButtonEnable" @input="mixinUpdateFormField('control.FactoryResetButtonEnable', `${configPath}.FactoryResetButtonEnable`, FactoryResetButtonEnable)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'FactoryResetButtonEnable')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select v-if="this.getterGetCurrentDeviceModelAlias !== 'RG350'" ref="PowerBankEnable" class="form-text-box" outlined dense label="Power Bank" :items="mixinGetEnableList()" v-model="PowerBankEnable" @input="mixinUpdateFormField('control.PowerBankEnable', `${configPath}.PowerBankEnable`, PowerBankEnable)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'PowerBankEnable')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select v-if="this.getterGetCurrentDeviceModelAlias !== 'RG350'" ref="StopChargingBatteryLevel" class="form-text-box" outlined dense label="Battery Level" :items="mixinGetControlBatteryLevelList()" v-model="StopChargingBatteryLevel" @input="mixinUpdateFormField('control.StopChargingBatteryLevel', `${configPath}.StopChargingBatteryLevel`, StopChargingBatteryLevel)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'StopChargingBatteryLevel')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select v-if="this.getterGetCurrentDeviceModelAlias === 'RG350'" ref="SmartChargingEnable" class="form-text-box" outlined dense label="Smart Charging" :items="mixinGetEnableList()" v-model="SmartChargingEnable" @input="mixinUpdateFormField('control.SmartChargingEnable', `${configPath}.SmartChargingEnable`, SmartChargingEnable)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'SmartChargingEnable')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select ref="AccessWebInterface" class="form-text-box" outlined dense label="Access Web Interface" :items="mixinGetEnableList()" v-model="AccessWebInterface" @input="mixinUpdateFormField('control.AccessWebInterface', `${configPath}.AccessWebInterface`, AccessWebInterface)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'AccessWebInterface')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-text-field ref="Password" v-show="AccessWebInterface !== 0" class="form-text-box" outlined dense label="Password" placeholder="Password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" v-model="Password" :rules="AccessWebInterface ? mixinRouterAdminPasswordRule() : []" autocomplete="new-password" maxlength="16" @blur="mixinUpdateFormField('control.Password', `configuration_module.RouterAdmin.Value.RouterAccountList.0.Password`, Password)"></v-text-field>

  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js'
  import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';
  import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

  export default {
    mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
    components: {
      UnchangedGroupConfigIcon,
    },
    data(){
      return {
        FactoryResetButtonEnable: -1,
        PowerBankEnable: -1,
        StopChargingBatteryLevel: -1,
        AccessWebInterface: -1,
        SmartChargingEnable: -1,
        Password: "",

        showPassword: false,
        
        configPath: "configuration_module.MISC.Value"
      }
    },
    created() {
      this.initMisc();
    },
    watch: {
      // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
      '$store.state.newDeviceConfigurations': {
        handler(){
          this.initMisc();
        },
        deep: true,
      },
      getterGetCurrentError: {
        handler(newVal){
          if(newVal && newVal.path){
            let errorMenu = newVal.path.split('.')[1];
            this.$nextTick(() => {
              if(this.$refs[errorMenu]){
                // This small delay will make sure that the cursor gets focused in correctly
                setTimeout(() => {
                  this.$refs[errorMenu].focus();
                }, 500)
              }
            })
          }
        },
        immediate: true,
        deep: true
      },
    },
    computed: {
      ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError", "getterGetCurrentDeviceModelAlias"]),
    },
    methods: {
      initMisc(){
        const defaultMisc = this.getterGetNewDeviceConfigurations.configuration_module.MISC.Value;
        const defaultRouterAdmin = this.getterGetNewDeviceConfigurations.configuration_module.RouterAdmin.Value.RouterAccountList[0];

        this.FactoryResetButtonEnable = defaultMisc.hasOwnProperty("FactoryResetButtonEnable") ? defaultMisc.FactoryResetButtonEnable : -1;
        if(this.getterGetCurrentDeviceModelAlias !== 'RG350'){
          this.PowerBankEnable = defaultMisc.hasOwnProperty("PowerBankEnable") ? defaultMisc.PowerBankEnable : -1;
          this.StopChargingBatteryLevel = defaultMisc.hasOwnProperty("StopChargingBatteryLevel") ? defaultMisc.StopChargingBatteryLevel : -1;
        }
        this.AccessWebInterface = defaultMisc.hasOwnProperty("AccessWebInterface") ? defaultMisc.AccessWebInterface : -1;

        if(this.getterGetCurrentDeviceModelAlias === "RG350"){
          this.SmartChargingEnable = defaultMisc.hasOwnProperty("SmartChargingEnable") ? defaultMisc.SmartChargingEnable : -1;
        }

        this.Password = defaultRouterAdmin.hasOwnProperty("Password") ? defaultRouterAdmin.Password : "";

        this.$nextTick(() => {
          this.mixinValidateForm('control', 'control');
        });
      }
    }
  }
</script>