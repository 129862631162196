<template>
  <div class="d-flex flex-row align-center">
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 50 40" fill="none" class="pt-2">
      <template v-for="(_, index) in 5">
        <rect 
          :x="1 + 10 * index" 
          :y="32 - index * 8" 
          :height="8 * (index + 1)" 
          :fill-opacity="getOpacity(deviceSignal, index)"
          width="8" 
          rx="0.5" 
          ry="0.5" 
          fill="#0195D2" 
        />
      </template>
    </svg>
    <h1 class="ml-2">
      {{ connectionType }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    deviceSignal: Number,
    connectionType: String,
  },
  methods: {
    getOpacity(signal, index) {
      return signal > index ? 1 : 0.25;
    }
  },
};
</script>