<template>
  <section ref="container" flat class="parent-card">
    <div class="total-wrapper">
      <div class="data-title">{{ title }}</div>
      <div class="data-value">{{ value }}</div>
    </div>
    <v-fade-transition>
      <div v-if="loading" class="load-cover">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
    </v-fade-transition>
  </section>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    value: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
  },
  data(){
    return {
    }
  },
};
</script>

<style scoped>
.parent-card {
  position: relative;
  display: block;
  min-width: fit-content;
  border: solid 1px #e5e5ea;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: white;
}

.total-wrapper .data-title {
  font-size: 1.375rem;
  font-weight: 500;
}
.total-wrapper .data-value {
  font-size: 4rem;
  font-weight: 500;
}

.load-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  display: grid;
  place-items: center;
}
</style>
