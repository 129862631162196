<template>
  <div>
    <v-dialog
      v-model="infoDialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dense dark class="bannercolor">
          <v-toolbar-title>Payment Status – Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close_dialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar height="80px" class="background">
          <v-img
            fit
            max-width="120px"
            height="100%"
            src="@/assets/FB1000-image1.png"
          ></v-img>
        </v-toolbar>
        <div>
          <b>Device : </b> {{ rowPayementDetails.router_name }}

          <div><b>Serial Number :</b>{{ serialNo.split("/")[1] }}</div>
        </div>
        <div class="ml-10">
          <b>IMEI : </b>{{ rowPayementDetails.imei }}

          <div><b>Partner :</b>{{ rowPayementDetails.partner_name }}</div>
        </div>
        <div class="ml-10">
          <b>License Type : </b> {{ rowPayementDetails.license_type }}

          <div>
            <b>License Description :</b
            >{{ rowPayementDetails.router_description }}
          </div>
        </div>

        <v-card-text>
          <v-divider class="mt-2"></v-divider>
          <v-card class="mt-4">
            <v-card-text>
              <div class="mt-6"><b>TimeLine</b></div>
              <v-row no-gutters>
                <v-stepper flat vertical>
                  <v-stepper-step
                    step="1"
                    v-for="(item, i) in timelineDetails"
                    :key="i"
                    complete
                  >
                    <small class="mt-4" v-text="item.type"> </small>
                    <small class="mt-4" v-text="convert_date(item.created_on)">
                    </small>
                  </v-stepper-step>
                </v-stepper>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-card class="mt-4">
            <v-card-text>
              <div class="mt-6"><b>Checkout Summary</b></div>
              <v-row class="pt-2" no-gutters>
                <v-col cols="3">
                  <div>Customer</div>
                </v-col>
                <v-col cols="3">
                  <div>Shipping Details</div>
                </v-col>
                <v-col cols="3">
                  <div></div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2" no-gutters>
                <v-col cols="3">
                  <div>Product</div>
                </v-col>
                <v-col cols="3">
                  <div>
                    {{
                      rowPayementDetails.router_name +
                      " " +
                      rowPayementDetails.license_name
                    }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div>
                    {{
                      rowPayementDetails.payment_amount +
                      " " +
                      rowPayementDetails.payment_currency
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-card class="mt-4">
            <v-card-text>
              <div class="mt-6 mb-4"><b>Automatic tax calculation </b></div>
              <v-row>
                <v-col cols="6">
                  <div><b>Status :</b>{{}}</div>
                  <div><b>Tax location :</b>{{}}</div>
                  <div><b>Tax amount :</b>{{}}</div>
                  <div><b>Taxability:</b>{{}}</div>
                </v-col>
                <v-col cols="6">
                  <div><b>Shipping address :</b>{{}}</div>
                  <div><b>Customer billing address :</b>{{}}</div>
                  <div><b>Payment method country :</b>{{}}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-text>
              <div class="mt-6 mb-4"><b>Shipping </b></div>
              <div><b>Recipient Address :</b>{{}}</div>
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-text>
              <div class="mt-6 mb-4"><b>Receipt history </b></div>
              <div><b>Payment :</b>{{}}</div>
            </v-card-text>
            <v-btn
              class="button ma-2"
              depressed
              dark
              small
              @click="download_action()"
              >Download PDF</v-btn
            >
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    infoDialog: Boolean,
    rowPayementDetails: Object,
  },
  data() {
    return {
      rowPayementDetails: "",
      rowClickPaymentDetails: "",
      rowClickOrderDetails: "",
      serialNo: "",
      paymentId: "",
      timelineDetails: [],
    };
  },
  watch: {
    infoDialog: {
      handler() {},
    },
    immediate: true,
  },

  methods: {
    download_action() {
      window.open(this.paymentDetials.payment_receipt, "_blank");
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
    // method to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(
          d.getDate()
        )}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },
  },
};
</script>