<template>
  <v-tabs v-model="navTabs" dense right dark>
    <v-tabs-slider class="button"></v-tabs-slider>

    <!-- Dashboard Menu -->
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'DashboardSAdmin' })">Dashboard</v-tab>
      </template>
    </v-menu>

    <!-- Manage Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'SuperBusinessDeviceList' })">Manage</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in manageMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Device Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'ProductModel' })">Device</v-tab>
      </template>

      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in deviceMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Accounts Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab id="account" v-bind="attrs" v-on="on" class="text-capitalize" ref="tab1" @click="pushRoute({ name: 'BusinessUsers' })">Accounts</v-tab>
      </template>

      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in accountsMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Reports Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'LicenseReport' })">Reports</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in reportsMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-tabs>
</template>

<!-- scripts -->
<script>
import { mixin_routing_utils } from "@/mixins/routingUtils.js";

export default {
  // The menu component above will loop through the array object lists below to make its menu items
  mixins: [ mixin_routing_utils ],
  data() {
    return {
      navTabs: 0,
      dashboardMenu: { name: "DashboardSAdmin" },
      manageMenu: [
        { title: "Business Devices", name: { name: "SuperBusinessDeviceList" } },
        { title: "Individual Devices", name: { name: "SuperIndividualDeviceList" } },
        { title: "Organizations", name: { name: "Organization" } },
        { title: "Groups", name: { name: "DeviceGroupsList" } },
        { title: "Bulk Upload", name: { name: "BulkUpload" } },
        { title: "Register MAC Address", name: { name: "RegisterDefaultMACAddress" } },
        { title: "FOTA Package", name: { name: "FotaPackage" } },
      ],
      deviceMenu: [
        { title: "Model Creation", name: { name: "ProductModel" } },
        { title: "Default Configuration", name: { name: "Config" } },
        { title: "Licenses", name: { name: "Licenses" } },
      ],
      accountsMenu: [
        { title: "Business Users", name: { name: "BusinessUsers" } },
        { title: "Individual Users", name: { name: "IndividualUsers" } },
      ],
      reportsMenu: [{ title: "License Status", name: { name: "LicenseReport" } }],
    };
  },
  // The <v-tabs-slider> will move according to the "navTabs" value index
  // Watch for changes in routing. If the route changes, return the matching "navTabs" index value
  watch: {
    $route(to, from) {
      this.navTabs = this.positionMenuSlider(to.name);
    },
  },
  // Make sure that the slider is on the correct position in case the user refreshes the page or enters by link directly
  created() {
    this.navTabs = this.positionMenuSlider(this.$route.name);
  },
  methods: {
    pushRoute(menuRoute) {
      this.mixinChangeRoute(menuRoute);
    },
    positionMenuSlider(name) {
      if (this.dashboardMenu.name === name) return 0;
      if (this.manageMenu.some((item) => name === item.name.name)) return 1;
      if (this.deviceMenu.some((item) => name === item.name.name)) return 2;
      if (this.accountsMenu.some((item) => name === item.name.name)) return 3;
      if (this.reportsMenu.some((item) => name === item.name.name)) return 4;
    }
  },
};
</script>
