<template>
  <v-card flat class="parent-card">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-chart ref="chart" class="chart mb-2" :option="option" :autoresize="true" />
      <div class="legend-container">
        <div class="inline-container" v-for="(item, index) in data" :key="title + index">
          <div class="legend-item" v-if="shouldDisplayNoTooltip(item.name)">
            <div class="legend-color" :style="{ backgroundColor: option.color[index] }"></div>
            <div class="legend-label">{{ item.name }}</div>
          </div>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="legend-item" v-bind="attrs" v-on="on">
                <div class="legend-color" :style="{ backgroundColor: option.color[index] }"></div>
                <div class="legend-label">{{ item.name }}</div>
              </div>
            </template>
            <span>{{ unknownText }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
    <v-fade-transition>
      <div v-if="loading" class="load-cover">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
    </v-fade-transition>
  </v-card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GraphicComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent, GraphicComponent]);

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    middleText: {
      type: String,
      default: "",
    },
    chartData: {
      type: Object,
      default: {},
    },
    unknownText: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.handleResize(); // Resize immediately
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    option() {
      this.data = Object.entries(this.chartData).map((item) => ({ name: item[0], value: item[1] }));
      let tooltip = {
        trigger: "item",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderWidth: 0,
        padding: [5, 8],
        textStyle: {
          color: "white",
          fontSize: 12,
          fontFamily: "Roboto",
          lineHeight: 18,
        },
        formatter: function (params) {
          return `
            <div class="d-flex align-center mb-2">
              <span class="mr-1" style="background-color: ${params.color}; display: inline-block; height: 0.75rem; aspect-ratio: 1; border-radius: 50%;"></span>${params.name}
            </div>
            <div class="d-flex justify-end align-baseline">
              <span class="mr-1" style="font-size: 1.2rem; font-weight: 600">${params.value}</span> Devices
            </div>`;
        },
        extraCssText: "min-width: 130px",
      };

      return {
        title: { show: false },
        legend: { show: false },
        tooltip,
        color: [ "#598FF9", "#9BBCFB", "#6C61EA", "#B5AFF4", "#D71C7A", "#E777AF", "#FA5918", "#FC9B74", "#FCAB2E", "#FDD496" ],
        series: [ { name: this.title, type: "pie", radius: ["65%", "90%"], label: { show: false }, labelLine: { show: false }, data: this.data } ],
        graphic: { type: "text", left: "center", top: "middle", style: { text: this.middleText, fontSize: 16, fontWeight: "bold", fill: "#333" } },
      };
    },
  },
  methods: {
    shouldDisplayNoTooltip(category) {
      return category !== "Unknown" || this.unknownText === "";
    },
    handleResize() {
      if (this.$refs.chart) {
        this.$refs.chart.resize();
      }
    },
  },
};
</script>

<style scoped>
.chart {
  min-height: 180px;
}
.parent-card {
  position: relative;
  min-width: fit-content;
  border: solid 1px #e5e5ea;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.legend-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  height: 65px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari */
.legend-container::-webkit-scrollbar {
  display: none;
}

.legend-item {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.legend-color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.5rem;
}

.legend-label {
  font-size: 0.75rem;
  width: fit-content;
}

.load-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  display: grid;
  place-items: center;
}
</style>
