<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4 mt-6">Port Whitelist</h3>

    <div>
      <v-switch ref="Enable" v-model="Enable" class="mt-n1 d-inline-block" @click="mixinUpdateFormField('security.portWhiteList.Enable', `${configPathPortWhilteList}.Enable`, Enable ? 1 : 0)">
        <template v-slot:label>
          <div>Enable</div>
        </template>
      </v-switch>
    </div>

    <v-expand-transition>
      <div v-show="Enable">
        <v-row class="mb-4" no-gutters>
          <v-col cols="10">
            <v-text-field ref="PortWhiteList" class="list-proxy-text-field" v-model="PortWhiteList" :rules="portWhiteListValidationRule()" hidden></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center" cols="2">
            <v-btn small @click="open_port_whitelist_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
          </v-col>
        </v-row>
    
        <v-data-table show-select single-select dense :items="PortWhiteList" :headers="portWhiteListHeaders">
    
          <template v-slot:item.data-table-select="{ item, index }">
            <v-switch :input-value="item.ActiveRule" @click.stop="change_port_whitelist_enable_state(item, index)" hide-details style="margin: auto"></v-switch>
          </template>

          <template v-slot:item.Protocol="{ item }">
            <span>{{ mixinInternetProtocolReturn(item.Protocol) }}</span>
          </template>
    
          <template v-slot:[`item.Actions`]="{ item, index }">
            <v-menu bottom left content-class="menu-offset">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
    
              <v-list dense>
                <v-list-item @click="open_port_whitelist_dialog('edit', item, index)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteFromList(item, index)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-expand-transition>
    <!-- other form fields -->
    <PortWhiteListDialog :portWhiteListDialog="portWhiteListDialog" :portWhiteListDialogMode="portWhiteListDialogMode" :PortWhiteList="PortWhiteList" @close="close_port_whitelist_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import PortWhiteListDialog from "../SubDialogs/PortWhiteListDialog.vue";
import DeleteDialog from "../../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    PortWhiteListDialog,
    DeleteDialog
  },
  data() {
    return {
      portWhiteListHeaders: [
        { text: "Rule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "RuleName" },
        { text: "Port", sortable: true, class: "headerColor white--text ", value: "Port" },
        { text: "Protocol", sortable: true, class: "headerColor white--text ", value: "Protocol" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      // PortForwarding
      Enable: false,
      PortWhiteList: [], // your table data

      configPathPortWhilteList: "configuration_module.PortWhiteList.Value",

      portWhiteListDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      portWhiteListDialogMode: {},

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initPortWhiteList();
  },
  watch: {
    // The apnList is artificially bound to v-text-field for a proper form validation
    PortWhiteList: {
      handler(newVal) {
        this.$nextTick(() => {
          this.mixinUpdateFormField("security.portForwarding.PortWhiteList", `${this.configPathPortWhilteList}.PortWhiteList`, this.PortWhiteList);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initPortWhiteList() {
      const defaultPortWhiteListInfo = this.getterGetNewDeviceConfigurations.configuration_module.PortWhiteList.Value;

      this.Enable = defaultPortWhiteListInfo.hasOwnProperty("Enable") ? (defaultPortWhiteListInfo.Enable === 1 ? true : false) : false;
      this.PortWhiteList = defaultPortWhiteListInfo.hasOwnProperty("PortWhiteList") ? [...defaultPortWhiteListInfo.PortWhiteList] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("portWhiteList", "security");
      });
    },
    portWhiteListValidationRule() {
      return [
        value => this.PortWhiteList.length <= 20 || "There cannot be more than 20 items in the Port Whitelist"
      ];
    },

    change_port_whitelist_enable_state(item, itemIndex){
      this.PortWhiteList.forEach((listItem, listIndex) => {
        if(itemIndex === listIndex){
          listItem.ActiveRule = item.ActiveRule === 0 ? 1 : 0;
        }
      })
    },
    deleteFromList(item, index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.PortWhiteList.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.PortWhiteList.forEach((listItem, index) => {
          listItem.Index = index;
        })
      }
    },
    // a method for both edit and add
    open_port_whitelist_dialog(mode, item, index) {
      if (this.PortWhiteList.length >= 20 && mode === "add") {
        this.triggerSnackBar("error", "You cannot have more than 20 items in the Port Mapping List");
        return;
      }
      this.portWhiteListDialog = true;
      this.portWhiteListDialogMode = {
        mode,
      };
      if (mode === "edit") {
        this.portWhiteListDialogMode.item = item;
        this.portWhiteListDialogMode.index = index;
      }
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_port_whitelist_dialog(dialogObj) {
      if (dialogObj.status === 1) {
        if (dialogObj.mode === "edit") {
          this.$set(this.PortWhiteList, dialogObj.index, dialogObj.item);
        } else if (dialogObj.mode === "add") {
          this.PortWhiteList.push(dialogObj.item);
        }
      }
      this.portWhiteListDialog = false;
    },
  },
};
</script>

<style scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
