<template>
  <div class="d-flex flex-column align-center" :style="style">
    <svg viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.15625" y="2.57227" width="35.59" height="28.9" :fill="color" :fill-opacity="opacity" />
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" :fill="textColor">{{ innerLabel }}</text>
      <mask id="mask0_747_2263" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="36">
        <path d="M1 0.5H0.5V1V35V35.5H1H39H39.5V35V1V0.5H39H1Z" fill="white" stroke="white" />
      </mask>
      <g mask="url(#mask0_747_2263)">
        <mask id="path-3-outside-1_747_2263" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="37" fill="black">
          <rect fill="white" width="41" height="37" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M36.59 24.4953H30.704V28.0787H26.4368V31.473H12.776V28.0787H8.70868V24.4953H3.54214V3.42298H36.59V24.4953ZM37.3209 1H2.67922C1.75669 1 1 1.74863 1 2.68441V33.3157C1 34.2515 1.75669 35.0001 2.67922 35.0001H37.3209C38.2538 35.0001 39.0001 34.2515 39.0001 33.3157V2.68441C39.0001 1.74863 38.2538 1 37.3209 1V1Z" />
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M36.59 24.4953H30.704V28.0787H26.4368V31.473H12.776V28.0787H8.70868V24.4953H3.54214V3.42298H36.59V24.4953ZM37.3209 1H2.67922C1.75669 1 1 1.74863 1 2.68441V33.3157C1 34.2515 1.75669 35.0001 2.67922 35.0001H37.3209C38.2538 35.0001 39.0001 34.2515 39.0001 33.3157V2.68441C39.0001 1.74863 38.2538 1 37.3209 1V1Z"
          fill="white" />
        <path
          d="M36.59 24.4953V25.4953H37.59V24.4953H36.59ZM30.704 24.4953V23.4953H29.704V24.4953H30.704ZM30.704 28.0787V29.0787H31.704V28.0787H30.704ZM26.4368 28.0787V27.0787H25.4368V28.0787H26.4368ZM26.4368 31.473V32.473H27.4368V31.473H26.4368ZM12.776 31.473H11.776V32.473H12.776V31.473ZM12.776 28.0787H13.776V27.0787H12.776V28.0787ZM8.70868 28.0787H7.70868V29.0787H8.70868V28.0787ZM8.70868 24.4953H9.70868V23.4953H8.70868V24.4953ZM3.54214 24.4953H2.54214V25.4953H3.54214V24.4953ZM3.54214 3.42298V2.42298H2.54214V3.42298H3.54214ZM36.59 3.42298H37.59V2.42298H36.59V3.42298ZM36.59 23.4953H30.704V25.4953H36.59V23.4953ZM29.704 24.4953V28.0787H31.704V24.4953H29.704ZM30.704 27.0787H26.4368V29.0787H30.704V27.0787ZM25.4368 28.0787V31.473H27.4368V28.0787H25.4368ZM26.4368 30.473H12.776V32.473H26.4368V30.473ZM13.776 31.473V28.0787H11.776V31.473H13.776ZM12.776 27.0787H8.70868V29.0787H12.776V27.0787ZM9.70868 28.0787V24.4953H7.70868V28.0787H9.70868ZM8.70868 23.4953H3.54214V25.4953H8.70868V23.4953ZM4.54214 24.4953V3.42298H2.54214V24.4953H4.54214ZM3.54214 4.42298H36.59V2.42298H3.54214V4.42298ZM35.59 3.42298V24.4953H37.59V3.42298H35.59ZM37.3209 0H2.67922V2H37.3209V0ZM2.67922 0C1.20551 0 0 1.19523 0 2.68441H2C2 2.30202 2.30786 2 2.67922 2V0ZM0 2.68441V33.3157H2V2.68441H0ZM0 33.3157C0 34.8049 1.20551 36.0001 2.67922 36.0001V34.0001C2.30786 34.0001 2 33.6981 2 33.3157H0ZM2.67922 36.0001H37.3209V34.0001H2.67922V36.0001ZM37.3209 36.0001C38.809 36.0001 40.0001 34.8009 40.0001 33.3157H38.0001C38.0001 33.7021 37.6986 34.0001 37.3209 34.0001V36.0001ZM40.0001 33.3157V2.68441H38.0001V33.3157H40.0001ZM40.0001 2.68441C40.0001 1.19925 38.809 0 37.3209 0V2C37.6986 2 38.0001 2.298 38.0001 2.68441H40.0001Z"
          :fill="color" mask="url(#path-3-outside-1_747_2263)" />
      </g>
    </svg>
    <strong v-if="outerLabel" class="outer-label">
      {{ outerLabel }}
    </strong>
  </div>
</template>

<script>

const activeColor = "#0195D2";
const inactiveColor = "#BEBFC2";

export default {
  name: 'EthernetIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    }, 
    innerLabel: {
      type: String,
      default: ""
    }, 
    outerLabel: {
      type: String,
      default: ""
    }, 
    width: {
      type: String,
      default: "2rem"
    }
  },
  computed: {
    color() {
      return this.active ? activeColor : inactiveColor;
    },
    opacity() {
      return this.active ? 1 : 0; 
    }, 
    textColor() {
      return this.active ? "#FFFFFF" : inactiveColor;
    }, 
    style() {
      return {
        '--icon-width': this.width,
        width: this.width,
        height: 'auto'
      }
    }
  }
}
</script>

<style scoped>
.outer-label {
  margin-top: 0.25rem;
  font-size: calc(var(--icon-width) * 4);
}
</style>