<template>
  <div>
    <v-menu
      v-model="openMenu"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="text-none" v-bind="attrs" v-on="on" elevation="0" outlined small :color="buttonColor"><v-icon>mdi-filter-outline</v-icon>Filter</v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-combobox v-model="selectedStatus" :items="['ONLINE', 'OFFLINE']" label="Status" clearable outlined></v-combobox>
          <v-combobox v-if="modelOptions.length" v-model="selectedModel" :items="modelOptions" label="Model" clearable outlined></v-combobox>
          <v-combobox v-if="groupOptions.length" v-model="selectedGroup" :items="groupOptions" label="Group" clearable outlined></v-combobox>
          <v-combobox v-if="organizationOptions.length" v-model="selectedOrganization" :items="organizationOptions" label="Organization" clearable outlined></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clearAll()">Clear All</v-btn>
          <v-btn color="#0195D2" class="white--text" @click="openMenu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "FilterButtonMenu",
  props: {
    filters: {
      type: Object, 
      default: () => {}
    }, 
    groupOptions: {
      type: Array,
      default: () => []
    }, 
    modelOptions: {
      type: Array,
      default: () => []
    },
    organizationOptions: {
      type: Array,
      default: () => []
    },
  },
  model: {
    prop: "filters",
    event: "change",
  },  
  data() {
    return {
      openMenu: false,
      selectedStatus: "",
      selectedModel: "",
      selectedGroup: "",
      selectedOrganization: "",
    };
  },
  watch: {
    selectedStatus(newVal) {
      if (newVal === null) this.selectedStatus = ""; // Vuetify's clear function sets selectedStatus to null, this line is to make sure selectedStatus is always a string
      else this.$emit("change", { ...this.filters, status: this.selectedStatus });
    },
    selectedModel(newVal) {
      if (newVal === null) this.selectedModel = "";
      else this.$emit("change", { ...this.filters, model: this.selectedModel });
    },
    selectedGroup(newVal) {
      if (newVal === null) this.selectedGroup = "";
      else this.$emit("change", { ...this.filters, group: this.selectedGroup });
    },
    selectedOrganization(newVal) {
      if (newVal === null) this.selectedOrganization = "";
      else this.$emit("change", { ...this.filters, organization: this.selectedOrganization });
    },
    filters: {
      handler(newVal) {
        this.selectedStatus = newVal.status || "";
        this.selectedModel = newVal.model || "";
        this.selectedGroup = newVal.group || "";
        this.selectedOrganization = newVal.organization || "";
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    buttonColor() {
      return (this.selectedStatus === "" && 
              this.selectedModel === "" && 
              this.selectedGroup === "" && 
              this.selectedOrganization === "") ? "" : "#0195D2";
    }
  },
  methods: {
    clearAll() {
      this.selectedStatus = "";
      this.selectedModel = "";
      this.selectedGroup = "";
      this.selectedOrganization = "";
    },
  }
};
</script>