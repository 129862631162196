export const get_payment_subscriptions = /* GraphQL */ `
  query get_payment_subscriptions($input: PaymentSubscriptionsInput) {
    get_payment_subscriptions(input: $input)
  }
`;

export const get_payment_prices = /* GraphQL */ `
  query get_payment_prices($input: PaymentPricesInput) {
    get_payment_prices(input: $input)
  }
`;
export const get_subscription_list = /* GraphQL */ `
  query get_subscription_list {
    get_subscription_list
  }
`;
export const get_subscription_status = /* GraphQL */ `
  query get_subscription_status {
    get_subscription_status
  }
`;
export const get_product_list = /* GraphQL */ `
  query get_product_list {
    get_product_list
  }
`;
export const does_subscription_exist = /* GraphQL */ `
  query does_subscription_exist($input: DoesSubscriptionExistInput) {
    does_subscription_exist(input: $input)
  }
`;