const state = {
  username: "",
  password: "",
  resetEmail: "",
  loginInfo: {},
  windowPage: "Login",
};

const getters = {
  // This is used for both dashboard info and router count
  getterGetUsername: (state) => {
    return state.username;
  },
  getterGetPassword: (state) => {
    return state.password;
  },
  getterGetResetEmail: (state) => {
    return state.resetEmail;
  },
  getterGetLoginInfo: (state) => {
    return state.loginInfo;
  },
  getterGetLoginWindowPage: (state) => {
    return state.windowPage;
  },
};

const mutations = {
  mutationSetUsername: (state, payload) => {
    state.username = payload;
  },
  mutationSetPassword: (state, payload) => {
    state.password = payload;
  },
  mutationSetResetEmail: (state, payload) => {
    state.resetEmail = payload;
  },
  mutationSetLoginInfo: (state, payload) => {
    state.loginInfo = payload;
  },
  mutationSetLoginWindowPage: (state, payload) => {
    state.windowPage = payload;
  },
};

const actions = {
  actionResetLoginInfo: ({ commit }, _) => {
    commit("mutationSetUsername", "");
    commit("mutationSetPassword", "");
    commit("mutationSetResetEmail", "");
    commit("mutationSetLoginInfo", {});
    commit("mutationSetLoginWindowPage", "Login");
  }
}

export default {
  state,
  getters,
  mutations,
  actions
};
