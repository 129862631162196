<template>
    <v-card>
      <v-card-title style="padding: 0">
        <v-icon class="white--text close-popup" size="28" @click="close_ad_dialog">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-img max-width="100%" :src="currentImageSrc" />
      </v-card-text>

      <v-card-actions class="card-actions">
        <v-btn depressed outlined class="mr-1 mb-2 white--text ml-2" color="white" @click="close_ad_dialog" style="text-transform: none">Close</v-btn>
        <v-checkbox style="color: white; border-color: white" color="white" class="mt-2 ml-2 custom-checkbox" v-model="showAgainCheck"></v-checkbox>
        <p class="mt-2 white--text">Do not show again</p>
        <v-spacer></v-spacer>
        <v-btn class="mb-2 white--text view-products mr-2" color="#4D95B6" @click="redirect_to_website" style="text-transform: none">More Info</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      adImages: ["PopUp.png", "PopUp2.png", "PopUp3.png", "PopUp4.png", "PopUp5.png"],
      showAgainCheck: false,
      currentImage: "PopUp.png",
    };
  },
  computed: {
    currentImageSrc() {
      return require(`@/assets/${this.currentImage}`);
    },
  },
  props: {
    adDialog: Boolean,
  },
  watch: {
    adDialog: {
      handler(newVal) {
        if (newVal) {
          this.updateRandomImage();
          this.showAgainCheck = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["mutationSetInitialAdCheck"]),
    updateRandomImage() {
      const randomIndex = Math.floor(Math.random() * this.adImages.length);
      this.currentImage = this.adImages[randomIndex];
    },
    redirect_to_website() {
      window.open("https://www.jextream.net", "_blank");
    },
    close_ad_dialog() {
      this.showAgainCheck ? this.mutationSetInitialAdCheck(false) : "";
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.close-popup {
  position: absolute !important;
  top: 0;
  right: 0;
  font-size: 50px;
  transform: translate(50%, -50%);
  border: white 2px solid;
  border-radius: 100px !important;
  background-color: black;
  cursor: pointer;
  z-index: 999;
}
.close-popup .mdi {
  font-size: 36px;
}
.ad-card-title {
  height: 20px;
}
.ad-card {
  max-width: 700px;
  background-color: transparent;
}
.card-actions {
  background-color: #135371;
  padding-bottom: 0px;
}

.custom-checkbox >>> input {
  color: white !important;
}

.card-actions >>> i {
  color: white;
}
.card-actions >>> .v-input--selection-controls__input {
  margin-right: 4px;
}
</style>
