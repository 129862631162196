<template>
  <div class="dashboard-container">
    <ExpirationCard :cancelledSubs="cancelledSubs" :expiredSubs="expiredSubs"/>
    <div class="dashboard-grid">
      <DoughnutChartCard :chartData="modelCountObject" title="Device Models" :middleText="`${fetchedDevices.length} Devices`" />
      <DoughnutChartCard :chartData="onlineCountObject" title="Connection Status" :middleText="`${onlineCountObject['Online']} Online`" />
      <DoughnutChartCard :chartData="technologies" title="Technology" unknownText="Power cycle your device to begin tracking connection technology" />
      <SubscriptionInfoCard :subscriptions="subscriptions" />
    </div>
  </div>
</template>

<script>
import SubscriptionInfoCard from "@/components/Dashboard/SubscriptionInfoCard.vue";
import ExpirationCard from "@/components/Dashboard/ExpirationCard.vue";
import DoughnutChartCard from "@/components/Dashboard/DoughnutChartCard.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";

export default {
  name: "IndividualDashboard",
  mixins: [router_model_utils],
  props: {
    fetchedDevices: {
      type: Array,
      required: true
    },
  },
  components: {
    SubscriptionInfoCard,
    ExpirationCard,
    DoughnutChartCard
  },
  computed: {
    modelCountObject() {
      return this.fetchedDevices.reduce((acc, curr) => {
        acc[curr.router_model] = (acc[curr.router_model] || 0) + 1;
        return acc;
      }, {});
    },
    onlineCountObject() {
      return this.fetchedDevices.reduce((acc, curr) => {
        if (curr.online_status == "ONLINE") {
          acc.Online += 1;
        } else {
          acc.Offline += 1;
        }
        return acc;
      }, {
        Online: 0,
        Offline: 0,
      });
    },
    subscriptions() {
      return this.fetchedDevices.map((device) => {
        return {
          device_name: device.router_name,
          imei: device.imei_number,
          subscription_type: device.license_type.toUpperCase(), 
          subscription_status: device.license_status.toUpperCase(),
          expire_date: device.expire_date.substring(0, device.expire_date.indexOf("T")),
        };
      });
    },
    technologies() {
      return this.fetchedDevices.reduce((acc, curr) => {
        const techIndex = curr.technology_type
        if (techIndex == undefined) {
          acc["Unknown"] += 1;
        } else {
          acc[this.convert_connectedStatus(techIndex)] = (acc[this.convert_connectedStatus(techIndex)] || 0) + 1;
        }
        return acc;
      }, { "Unknown": 0 });
    }, 
    cancelledSubs() {
      return this.fetchedDevices.filter((device) => {
        return device.license_status.toUpperCase() === "CANCELLED";
      });
    },
    expiredSubs() {
      return this.fetchedDevices.filter((device) => {
        return device.license_status.toUpperCase() === "EXPIRED";
      });
    },
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 22%)) auto;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
}
</style>