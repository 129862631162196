<template>
  <div>
    <v-layout class="justify-center">
      <div>
        <v-card class="justify-start mt-4">
          <v-card-title>Change Your Password</v-card-title>
          <v-card-text>
            <v-row v-if="!passwordValid" justify="start" no-gutters>
              <v-col>
                Enter your current password
                <v-text-field dense class="mt-6" style="width: 400px" v-model="oldPwd" label="Current Password" outlined @keydown.space.prevent @keyup.enter="check_password_validity()"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else justify="start" no-gutters>
              <v-col>
                <v-form ref="form">
                  Enter your new password
                  <v-text-field dense class="mt-6 mb-2" v-model="newPwd" style="width: 400px" label="New Password" outlined maxlength="20" :rules="mixinLoginPasswordRule()" @keyup.enter="update_password()"></v-text-field>
                  <v-text-field dense v-model="confirmPwd" style="width: 400px" label="Confirm Password" outlined maxlength="20" :rules="confirmNewPasswordRule()" @keyup.enter="update_password()"></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="passwordValid" class="justify-end mr-2">
            <v-btn small depressed class="blue--text mt-n4 ml-2 mb-2 normal-text" color="white" outlined @click="cancel()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="update_password()"> Submit </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="justify-end mr-2">
            <v-btn small depressed class="blue--text mt-n4 ml-2 mb-2 normal-text" color="white" outlined @click="cancel()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="check_password_validity()"> Next </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import { mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
export default {
  mixins: [mixin_form_validation_utils],
  props: {
    emailId: String,
  },
  data() {
    return {
      oldPwd: "",
      newPwd: "",
      confirmPwd: "",
      loading: false,
      passwordValid: false,
    };
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on cancel action to move to previous step
    cancel() {
      this.$emit("cancel");
    },
    confirmNewPasswordRule() {
      return [
        ...this.mixinLoginPasswordRule(),
        (value) => value === this.newPwd || "The passwords must match"
      ]
    },

    //Gene 7/18/23 Check if initial password is valid
    async check_password_validity() {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.emailId, this.oldPwd);
        this.passwordValid = true;
      } catch (error) {
        this.triggerSnackBar("error", "Incorrect password")
        this.passwordValid = false;
      } finally {
        this.loading = false;
      }
    },

    //Gene 7/18/23 Update password with new value
    async update_password() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "There are errors in your form");
      try {
        this.loading = true;
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.oldPwd, this.newPwd);
        this.$emit("successMsg");
      } catch (err) {
        this.triggerSnackBar("error", err);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
