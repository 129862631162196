<template>
  <div class="day-picker-container">
    <v-btn v-for="day in days" :key="day.value" :color="isSelected(day.value) ? 'primary' : 'white'" class="ma-2" @click="toggleDay(day.value)"
    small rounded elevation="0">
      {{ day.value }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    // The v-model value is now expected to be a string
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      days: [
        { name: "Monday", value: "Mon" },
        { name: "Tuesday", value: "Tue" },
        { name: "Wednesday", value: "Wed" },
        { name: "Thursday", value: "Thu" },
        { name: "Friday", value: "Fri" },
        { name: "Saturday", value: "Sat" },
        { name: "Sunday", value: "Sun" },
      ],
    };
  },
  methods: {
    isSelected(day) {
      return this.value.split(", ").includes(day);
    },
    toggleDay(day) {
      let selectedDays = this.value ? this.value.split(", ") : [];
      const index = selectedDays.indexOf(day);
      if (index >= 0) {
        selectedDays.splice(index, 1); // Remove the day if it's already selected
      } else {
        selectedDays.push(day); // Add the day if it's not selected
      }
      // Emit the updated value as a string
      this.$emit("input", selectedDays.join(", "));
    },
  },
};
</script>

<style scoped>
  .day-picker-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>