<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">Parental Control</h3>

    <v-switch ref="ParentalControlEnable" class="form-text-box d-inline-block mb-5" dense v-model="parentalControlEnable" hide-details @click="mixinUpdateFormField('ParentalControl.ParentalControlEnable', `${configPath}.Enable`, parentalControlEnable ? 1 : 0)">
      <template v-slot:label>
        <div>Parental Control Enable</div>
      </template>
    </v-switch>

    <v-fade-transition>
      <div v-show="parentalControlEnable">
        <v-tabs v-model="tab" class="mb-4">
          <v-tab>Profiles</v-tab>
          <v-tab>Time Schedule</v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="tab">
          <!-- Parental Control List -->
          <v-tab-item>
            <v-data-table class="mb-6" dense :items="parentalControlList" :headers="parentalControlListHeaders">
              <template v-slot:item.ProfileName="{ item }">
                <span>{{ hexToUtf8String(item.ProfileName) }}</span>
              </template>
              <template v-slot:item.DeviceList="{ item }">
                <span v-for="(device, index) in item.DeviceList" :key="device.MACAddress">
                  {{ device.HostName }}
                  <span v-if="index !== item.DeviceList.length - 1">, </span>
                </span>
              </template>
            </v-data-table>
          </v-tab-item>
    
          <!-- Time Control List -->
          <v-tab-item>
            <v-data-table class="mb-6" dense :items="timeControlList" :headers="timeControlListHeaders">
              <template v-slot:item.ScheduleName="{ item }">
                <span>{{ hexToUtf8String(item.ScheduleName) }}</span>
              </template>
              <template v-slot:item.StartTime="{ item }">
                <span>{{ mixinTimeReturn(item.StartTime) }}~{{ mixinTimeReturn(item.EndTime) }}</span>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-fade-transition>

  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  data() {
    return {
      tab: null,
      timeControlListHeaders: [
        { text: "Schedule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "ScheduleName" },
        { text: "Time Range", align: "start", sortable: true, class: "headerColor white--text ", value: "StartTime" },
        { text: "Day of Week", sortable: true, class: "headerColor white--text ", value: "DayOfWeek" },
      ],
      parentalControlListHeaders: [
        { text: "Rule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "ProfileName" },
        { text: "Device List", align: "start", sortable: true, class: "headerColor white--text ", value: "DeviceList" },
      ],
      timeControlList: [],
      parentalControlList: [],
      parentalControlEnable: false,

      configPath: "configuration_module.ParentalControl.Value",
    };
  },
  created() {
    this.initParentalControl();
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initParentalControl() {
      const defaultParentalControl = this.getterGetNewDeviceConfigurations.configuration_module.ParentalControl.Value;

      this.parentalControlEnable = defaultParentalControl.hasOwnProperty("Enable") ? defaultParentalControl.Enable : -1;
      this.timeControlList = defaultParentalControl.hasOwnProperty("TimeControlList") ? [...defaultParentalControl.TimeControlList] : [];
      this.parentalControlList = defaultParentalControl.hasOwnProperty("ParentalControlList") ? [...defaultParentalControl.ParentalControlList] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("ParentalControl", "ParentalControl");
      });
    },
    hexToUtf8String(hex) {
      const bytes = new Uint8Array(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(bytes);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}

::v-deep .v-calendar-daily_head-day-label .v-btn {
  display: none;
}
</style>
