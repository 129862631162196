<template>
  <div class="d-flex flex-row justify-space-between align-center">
    <v-progress-circular :size="60" :width="10" :rotate="-90" :value="memoryUsage" color="#0195D2"/>
    <h1 class="ml-4">{{ Math.round(memoryUsage).toString() }}%</h1>
  </div>
</template>

<script>

export default {
  name: "MemoryUsage",
  props: {
    memoryUsage: {
      type: Number,
      default: 0,
    },
  },
};
</script>