<template>
  <div>
    <div v-if="detailsCheck == 0">
      <v-subheader class="pl-0 text-h5">Update Information</v-subheader>

      <v-card class="mt-3" flat>
        <v-row justify="start" no-gutters class="row-border pt-4">
          <v-col cols="2" sm="5" xs="5" md="3" class="mt-2 mr-6">
            <v-text-field dense v-model="userFirstName" label="First Name" outlined :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @keydown.space.prevent @input="set_limit(userFirstName)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
          </v-col>
          <v-col cols="2" sm="5" xs="5" md="3" class="mt-2">
            <v-text-field class="mr-6" dense v-model="userLastName" label="Last Name" outlined :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @keydown.space.prevent @input="set_limit(userLastName)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
          </v-col>
          <v-col>
            <v-btn class="button ml-2 mt-2" :loading="loading" dark @click="validate_change()">Update</v-btn>
          </v-col>
        </v-row>

        <v-row v-if="getterGetCurrentUserRole != 'INDIVIDUAL'" justify="start" no-gutters class="mt-4" align="end">
          <v-col sm="4" md="3">
            <span><b>Email</b> <br />{{ emailId }}</span>
          </v-col>
          <v-col sm="8" md="9">
            <v-btn v-if="getterGetCurrentUserRole === 'C_ADMIN' || 'P_ADMIN'" small text class="ml-2 edit-acc" @click="detailsCheck = 1"> Edit </v-btn>
          </v-col>
        </v-row>
        <v-row justify="start" no-gutters class="mt-4" align="center">
          <v-col sm="4" md="3">
            <span><b>Password</b> <br />{{ password }}</span>
          </v-col>
          <v-col sm="8" md="9">
            <!-- Enabled change password when superadmin -->
            <v-btn small text class="ml-2 edit-acc" @click="detailsCheck = 2"> Edit </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="getterGetCurrentUserRole != 'INDIVIDUAL'" justify="start" no-gutters class="mt-4" align="center">
          <v-col sm="4" md="3">
            <span><b>Multi-Factor Authentication</b> <br />{{ mfaEnabled ? "Enabled" : "Disabled" }}</span>
          </v-col>
          <v-col v-if="!getterGetIsImpersonating && !mfaEnabled" sm="8" md="9">
            <v-btn small text class="ml-2 edit-acc" @click="set_mfa()"> Enable </v-btn>
          </v-col>
          <v-col v-if="!getterGetIsImpersonating && mfaEnabled" sm="8" md="9">
            <v-btn small text class="ml-2 edit-acc" @click="change_mfa_settings()"> Disable </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div v-if="detailsCheck == 1">
      <v-layout class="justify-center">
        <v-card flat>
          <ChangeEmail @cancel="detailsCheck=0"> </ChangeEmail>
        </v-card>
      </v-layout>
    </div>
    <div v-if="detailsCheck == 2">
      <v-layout class="justify-center">
        <v-card flat>
          <ChangePwd :emailId="emailId" @cancel="detailsCheck=0" v-on:successMsg="success_toast"></ChangePwd>
        </v-card>
      </v-layout>
    </div>
    <div v-if="detailsCheck == 3">
      <v-layout class="justify-center">
        <v-card flat>
          <MFA :secretKey="secretKey" :qrString="qrString" @cancel="detailsCheck=0" v-on:mfaSuccess="mfa_completion" />
        </v-card>
      </v-layout>
    </div>
    <v-dialog v-model="mfaConfirm" persistent overflow-hidden max-width="400px">
      <v-card class="cardBg">
        <v-card-title class="medium cardTitle">
          <span>Disable MFA</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="mfaConfirm = false">
            <v-icon color="iconCross">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="cardBg pt-6 justify-center">
          Are you sure you want to disable MFA for
          <b> {{ customerName }} </b> account.
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn class="button tabledata--text" @click="mfaConfirm = false">Cancel</v-btn>
          <v-btn class="button tabledata--text" :loading="loading" @click="disable_mfa_options()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MFA from "@/components/UpdateProfileInfo/MFA.vue";
import ChangeEmail from "@/components/UpdateProfileInfo/ChangeEmail.vue";
import ChangePwd from "@/components/UpdateProfileInfo/ChangePwd.vue";
import { update_user } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ChangeEmail,
    ChangePwd,
    MFA,
  },
  data() {
    return {
      detailsCheck: 0,

      loading: false,
      userFirstName: "",
      userLastName: "",
      emailId: "",
      password: "**********",
      mfaConfirm: false,
      secretKey: "",
      qrString: "",
      mfaEnabled: false,
      customerName: "",
    };
  },
  created() {
    this.bind_data();
  },
  computed: {
    ...mapGetters(["getterGetIsImpersonating", "getterGetCurrentUserRole", "getterGetUserInfo"])
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method to bind the existing values
    bind_data() {
      this.customerName = this.getterGetUserInfo.user.user_full_name;
      this.userFirstName = this.getterGetUserInfo.user.user_first_name;
      this.userLastName = this.getterGetUserInfo.user.user_last_name;
      this.mfaEnabled = this.getterGetUserInfo.user.mfa_enabled;
      this.emailId = this.getterGetUserInfo.user.user_email_id;
    },

    //method to display the success toast
    success_toast() {
      this.detailsCheck = 0;
      this.validate_change();
    },

    //method invokes on mfa disable actions
    change_mfa_settings() {
      this.mfaConfirm = true;
    },
    //method invokes on mfa disable cofirmation
    async disable_mfa_options() {
      var user = await Auth.currentAuthenticatedUser();
      Auth.setPreferredMFA(user, "NOMFA");
      this.mfaEnabled = false;
      this.mfaConfirm = false;
      this.save_changes();
    },
    //method to generate qr string
    async set_mfa() {
      var user = await Auth.currentAuthenticatedUser();
      try {
        Auth.setupTOTP(user).then((code) => {
          this.secretKey = code;
          const str = "otpauth://totp/JEXtream:" + this.$store.getters.getterGetCurrentUserEmail + "?secret=" + code + "&issuer=" + "JEXtream";
          this.qrString = str;
        });
        this.detailsCheck = 3;
      } catch (error) {}
    },

    //method to limit maxlength
    set_limit(value) {
      if (value.length > this.maxLength) value = value.slice(0, this.maxLength);
    },

    //method to restrict the special characters
    specal_charaters(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var text = clipboardData.getData("Text");
        var reg = /^[a-zA-Z0-9- ]+$/;
        if (!reg.test(text)) {
          event.preventDefault();
        } else {
          return false;
        }
      }
      var regex = new RegExp("^[a-zA-Z0-9- ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },

    //method invokes on mfa completion
    mfa_completion() {
      this.detailsCheck = 3;
      this.mfaEnabled = true;
      this.save_changes();
    },

    //method to validate the empty fields
    validate_change() {
      if (this.emailId == "" || this.userFirstName == "" || this.userLastName == "") {
        return this.triggerSnackBar("error", "Please enter all mandatory fields");
      }
      this.save_changes();
    },

    //method invokes if data is  valid to update
    async save_changes() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(update_user, {
            input: {
              user_id: this.$store.getters.getterGetUserInfo.user.user_id,
              user_first_name: this.userFirstName,
              user_last_name: this.userLastName,
              user_email_id: this.emailId,
              mfa_enabled: this.mfaEnabled,
            },
          })
        );
        this.triggerSnackBar("success", "The user account profile has been updated.");
        this.mfaConfirm = false;
      } catch (error) {
        this.triggerSnackBar("error", error.errors[0].message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.row-border {
  border-top: 2px rgb(224, 224, 224) solid;
  border-bottom: 2px rgb(224, 224, 224) solid;
  border-radius: 0% !important;
}

.edit-acc {
  text-transform: none;
  letter-spacing: normal;
  color: #0195d2 !important;
  text-decoration: underline;
  font-size: 14px !important;
}
</style>
