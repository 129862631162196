export const color_code_and_carrier_details = /* GraphQL */ `
  query Color_code_and_carrier_details($input: ColorCodeAndCarrierDetailsInput) {
    color_code_and_carrier_details(input: $input)
  }
`;

export const get_all_partners = /* GraphQL */ `
  query Get_all_partners {
    get_all_partners {
      user_id
      is_adding_device
      is_individual
      user_email_id
      user_first_name
      user_last_name
      user_full_name
      user_role
      user_status
      user_time_zone
      user_created_on
      user_country_code
      user_phone_number
      profile_key
      no_of_alerts
    }
  }
`;
