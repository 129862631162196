<template>
  <v-dialog overflow-hidden v-model="editDialog" persistent max-width="600px">
    <v-card class="dialogCard cardBg overflow-hidden">
      <v-toolbar class="dialogToolbar cardBg" dense>
        <v-toolbar-title>Edit: {{ orgName }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-icon @click="clear_data()" color="iconCross" class="ml-2">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-form ref="addUserForm">
          <v-row no-gutters class="mt-4">
            <v-col cols="12">
              <v-select filled dense label="Partner *" outlined :menu-props="{ bottom: true, offsetY: true }" v-model="partnerName" item-text="label" item-value="value" :items="partnerList"></v-select>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field dense v-model="orgName" label="Organization Name*" outlined style="max-width: 300px" :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @keydown.space="prevent_leading_space" @input="set_limit(orgName)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field class="ml-2" dense v-model="orgCode" label="Organization Code" outlined :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @input="set_limit(orgCode)" @keydown.space="prevent_leading_space" style="max-width: 300px" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="6">
              <v-select label="Provider as Carrier*" dense v-model="carrierType" :menu-props="{ bottom: true, offsetY: true }" outlined required item-text="label" item-value="value" :items="carrierItems" style="max-width: 300px"></v-select>
            </v-col>

            <v-col cols="6">
              <v-form>
                <v-select class="ml-2" style="max-width: 300px" dense :menu-props="{ bottom: true, offsetY: true }" outlined required v-model="colorCode" :items="colorOptions" label="Select Color">
                  <template #selection="{ item }">
                    <v-icon class="square-icon mr-1" :color="getColor(item.value)">mdi-circle</v-icon>
                    <v-list-item-content class="py-0">{{ getColorTextUIOnly(item.value) }}</v-list-item-content>
                  </template>
                  <template #item="{ item }">
                    <v-icon class="square-icon mr-1" :color="getColor(item.value)">mdi-circle</v-icon>
                    <v-list-item-content> {{ getColorTextUIOnly(item.value) }}</v-list-item-content>
                  </template>
                </v-select>
              </v-form>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field readonly label="Email*" dense v-model="email" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field dense v-model="addr1" class="ml-2" label="Address 1" outlined style="max-width: 300px" :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @keydown.space="prevent_leading_space" @input="set_limit(addr1)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field dense v-model="city" label="City" outlined style="max-width: 300px" :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @keydown.space="prevent_leading_space" @input="set_limit(city)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field class="ml-2" dense v-model="postalCode" label="Postal Code" outlined :rules="[(val) => val.length <= 40 || 'Max Length(40)']" maxlength="40" @input="set_limit(postalCode)" @keydown.space="prevent_leading_space" style="max-width: 300px" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="button-corner">
        <v-spacer></v-spacer>
        <v-btn class="blue--text" outlined @click="clear_data()">Cancel</v-btn>
        <v-btn :loading="loading1" @click="validation_data()" class="button tabledata--text mr-2">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { edit_organization } from "@/graphql/mutations.js";
import { mapMutations } from "vuex";

import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    editDialog: Boolean,
    editDetails: Object,
    partnerList: Array,
    carrierItems: Array,
  },

  data() {
    return {
      loading1: false,
      carrierType: "",
      colorCode: "",
      carrierList: [],
      colorList: [],
      lastName: "",
      firstName: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail";
        },
      },
      colorOptions: [
        { value: "01282d19-90c4-47e7-95e5-40362c04c6e7", text: "Yellow", class: "option8" },
        { value: "f25a700e-3779-45aa-8004-395a775f3c76", text: "Dark Blue", class: "option7" },
        { value: "74036d7c-bd77-48bf-a0d4-8f4d49a8267f", text: "Purple", class: "option6" },
        { value: "c59d8ff2-5123-43d4-a178-7904d2bfe8b4", text: "Teal", class: "option5" },
        { value: "36e80a42-57f9-46ce-95d2-5ddd0a478fd5", text: "Blue", class: "option4" },
        { value: "e165458e-3b13-4962-9303-65664b6c1f6c", text: "Light Blue", class: "option3" },
        { value: "3f7cb5e2-e055-4d9e-9bf4-95fda6169b51", text: "Green", class: "option2" },
        { value: "67b5e6c6-75da-4ef7-8bb3-ed94e516dcf7", text: "Light Green", class: "option1" },
      ],
    };
  },
  watch: {
    editDialog: {
      handler() {
        this.partnerName = this.editDetails.partner_id;
        this.orgName = this.editDetails.organization_name;

        this.orgCode = this.editDetails.organization_code != undefined ? this.editDetails.organization_code : "";
        this.carrierType = this.editDetails.carrier_id;
        this.colorCode = this.editDetails.color_id;

        this.email = this.editDetails.user_email_id;
        this.orgStatus = this.editDetails.organization_status;

        this.addr1 = this.editDetails.address_first != undefined ? this.editDetails.address_first : "";

        this.city = this.editDetails.organization_city != undefined ? this.editDetails.organization_city : "";
        this.postalCode = this.editDetails.postal_code != undefined ? this.editDetails.postal_code : "";

        this.customerId = this.editDetails.customer_id;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close action
    clear_data() {
      var Toggle = 0;
      this.$emit("clicked", Toggle);
    },

    //method to allow only numeric values
    is_Number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    //method to prevent copy of texts
    process(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var pastedData = clipboardData.getData("Text");
        if (isNaN(pastedData)) {
          event.preventDefault();
        } else {
          return;
        }
      }
      var keyCode = event.keyCode || event.which;
      if (keyCode >= 96 && keyCode <= 105) {
        keyCode -= 48;
      }
      var charValue = String.fromCharCode(keyCode);
      if (isNaN(parseInt(charValue)) && event.keyCode != 8) {
        event.preventDefault();
      }
    },

    //method to restrict to maxlength
    set_limit(value) {
      if (value.length > this.maxLength) value = value.slice(0, this.maxLength);
    },

    //method to restrict special characters
    specal_charaters(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var text = clipboardData.getData("Text");
        var reg = /^[a-zA-Z0-9- ]+$/;
        if (!reg.test(text)) {
          event.preventDefault();
        } else {
          return false;
        }
      }
      var regex = new RegExp("^[a-zA-Z0-9- ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },

    //method to prevent the leading spaces
    prevent_leading_space(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },

    //method to validate  data
    validation_data() {
      var valid = true;
      if (this.partnerName.trim() == "" || this.orgName.trim() == "" || this.carrierType == "") {
        valid = false;
        var Toggle = 1;
        this.$emit("clicked", Toggle);
      }
      if (valid) {
        this.update_user();
      }
    },

    //method invokes if data is valid
    async update_user() {
      this.loading1 = true;

      try {
        let result = await API.graphql(
          graphqlOperation(edit_organization, {
            input: {
              customer_id: this.customerId,
              organization_name: this.orgName,
              organization_status: this.orgStatus,
              organization_city: this.city == "" ? undefined : this.city,
              postal_code: this.postalCode == "" ? undefined : this.postalCode,

              carrier_id: this.carrierType == "" ? undefined : this.carrierType,
              organization_code: this.orgCode == "" ? undefined : this.orgCode,
              color_id: this.colorCode,
              partner_id: this.partnerName,
              address_first: this.addr1 == "" ? undefined : this.addr1,
            },
          })
        );
        this.$emit("clicked", 3);
        this.loading1 = false;
        this.clear_data();
      } catch (error) {
        this.loading1 = false;
        this.clear_data();
        this.triggerSnackBar("error", error.errors[0].message);
      }
    },
    getColor(item) {
      if (item == "74036d7c-bd77-48bf-a0d4-8f4d49a8267f") {
        return "#7030a0";
      } else if (item == "01282d19-90c4-47e7-95e5-40362c04c6e7") {
        return "#ED6E05";
      } else if (item == "f25a700e-3779-45aa-8004-395a775f3c76") {
        return "#AC1010";
      } else if (item == "c59d8ff2-5123-43d4-a178-7904d2bfe8b4") {
        return "#D41694";
      } else if (item == "36e80a42-57f9-46ce-95d2-5ddd0a478fd5") {
        return "#0070c0";
      } else if (item == "e165458e-3b13-4962-9303-65664b6c1f6c") {
        return "#0D3D79";
      } else if (item == "3f7cb5e2-e055-4d9e-9bf4-95fda6169b51") {
        return "#01631D";
      } else if (item == "67b5e6c6-75da-4ef7-8bb3-ed94e516dcf7") {
        return "#44A336";
      }
    },
    getColorTextUIOnly(item) {
      if (item == "74036d7c-bd77-48bf-a0d4-8f4d49a8267f") {
        return "Purple";
      } else if (item == "01282d19-90c4-47e7-95e5-40362c04c6e7") {
        return "Orange";
      } else if (item == "f25a700e-3779-45aa-8004-395a775f3c76") {
        return "Red";
      } else if (item == "c59d8ff2-5123-43d4-a178-7904d2bfe8b4") {
        return "Pink";
      } else if (item == "36e80a42-57f9-46ce-95d2-5ddd0a478fd5") {
        return "Blue";
      } else if (item == "e165458e-3b13-4962-9303-65664b6c1f6c") {
        return "Dark Blue";
      } else if (item == "3f7cb5e2-e055-4d9e-9bf4-95fda6169b51") {
        return "Green";
      } else if (item == "67b5e6c6-75da-4ef7-8bb3-ed94e516dcf7") {
        return "Light Green";
      }
    },
  },
};
</script>
<style scoped>
.option1 {
  background-color: #92d050;
}
.option2 {
  background-color: #00b050;
}

.option3 {
  background-color: #2ccaff;
}
.option4 {
  background-color: #0070c0;
}
.option5 {
  background-color: #28637c;
}
.option6 {
  background-color: #7030a0;
}
.option7 {
  background-color: #002060;
}
.option8 {
  background-color: #ffc000;
}
</style>
