<template>
  <div>
    <v-menu
      v-model="openMenu"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="text-none" v-bind="attrs" v-on="on" elevation="0" outlined small :color="buttonColor"><v-icon>mdi-view-column-outline</v-icon>Columns</v-btn>
      </template>
      <v-card style="width: 36rem;">
        <v-card-text class="d-flex flex-row flex-wrap" >
          <template v-for="(value, index) in Object.keys(allHeaders)">
            <v-checkbox style="width: 11rem" v-model="includeArray[index]" :value="includeArray[index]" :label="allHeaders[value].text"></v-checkbox>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="selectAll()">Select All</v-btn>
          <v-btn text @click="unselectAll()">Unselect All</v-btn>
          <v-btn color="#0195D2" class="white--text" @click="openMenu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { label } from 'aws-amplify';

export default {
  name: "ColumnButtonMenu",
  props: {
    allHeaders: Object,
    headers: Array,
  },
  model: {
    prop: "headers",
    event: "change",
  },  
  data() {
    return {
      openMenu: false,
      includeArray: [],
    };
  },
  watch: {
    allHeaders: {
      handler(newVal){
        this.includeArray = Array(Object.keys(newVal).length).fill(true);
      },
      immediate: true
    }, 
    includeArray: {
      handler(newVal){
        const out = Object.keys(this.allHeaders).filter((_, index) => newVal[index]).map(name => this.allHeaders[name]);
        this.$emit("change", out);
      },
      immediate: true
    }
  },
  computed: {
    buttonColor() {
      return this.includeArray.length === Object.keys(this.allHeaders).length ? 
        "" :
        "#0195D2";
    }
  },
  methods: {
    selectAll() {
      this.includeArray = Object.keys(this.allHeaders);
    }, 
    unselectAll() {
      this.includeArray = [];
    }
  }
};
</script>