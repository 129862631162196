<template>
  <v-dialog overflow-hidden v-model="assignToGroupDialog" persistent max-width="600px">
    <DialogCard v-if="assignToGroupDialog" :modalIcon="'format-list-group-plus'" :modalTitle="'Add devices to group'">
      <template #header>
        <v-btn icon x-small @click="close_create_group_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <v-select v-model="group" label="Select a group" dense outlined :items="groupList">
            <template #label v-if="isLoadingGroups == true">
              <i class="fas fa-spinner fa-spin mr-1" style="color: #949494"></i>
              <span> Loading {{ groupModel }} groups </span>
            </template>
          </v-select>
        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_create_group_dialog()">Cancel</v-btn>
        <v-btn small :loading="loadingAddGroup" @click="add_to_group()" class="button tabledata--text mr-2">Assign</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js';
import { mapMutations } from "vuex";
import { addDevicesToAGroup, getOrganizationGroup } from "@/services";

export default {
  mixins: [ mixin_form_validation_utils ],
  components: {
    DialogCard,
  },
  props: {
    assignToGroupDialog: Boolean,
    selectedDevices: Array,
    groupModel: String,
    deviceOrganization: String
  },
  watch: {
    assignToGroupDialog: {
      async handler(newVal) {
        if(newVal) {
          try {
            this.isLoadingGroups = true;
            let orgGroups = await getOrganizationGroup(this.deviceOrganization);
            if(orgGroups) {
              orgGroups = orgGroups.filter(group => group.group_model === this.groupModel);
              orgGroups.forEach((group) => {
                this.groupList.push({
                  text: group.group_name,
                  value: group.router_group_id
                })
              });
            }
          } catch(err) {
            this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
          } finally {
            this.isLoadingGroups = false;
          }
          if(this.groupList.length > 0) this.group = this.groupList[0].value;
        } else {
          this.groupList = [];
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      group: "",
      groupList: [],
      isLoadingGroups: false,
      loadingAddGroup: false,
    };
  },
  computed: {

  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close actions
    close_create_group_dialog() {
      this.$emit("close", 0);
    },

    //  mutation call for adding  groups
    async add_to_group() {
      if (!this.group) return this.triggerSnackBar("error", "Select a group first");

      this.loadingAddGroup = true;

      try {
        if (this.selectedDevices.length > 100) {
          let deviceListChunks = [];
          for (let i = 0; i < this.selectedDevices.length; i += 100) {
            deviceListChunks.push(this.selectedDevices.slice(i, i + 100));
          }
          for (let i = 0; i < deviceListChunks.length; i++) {
            await addDevicesToAGroup({
              router_ids: deviceListChunks[i],
              customer_id: this.deviceOrganization,
              router_group_id: this.group,
            });
          }
        } else {
          await addDevicesToAGroup({
            router_ids: this.selectedDevices,
            customer_id: this.deviceOrganization,
            router_group_id: this.group,
          });
        }

        this.triggerSnackBar("success", `Devices have been assigned to the group`);
        this.$emit("close", 1);
      } catch (error) {
        this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
      } finally {
        this.loadingAddGroup = false;
      }
    },
  },
};
</script>
