<template>
  <div ref="container">
    <v-main>
      <div class="wrapper" :style="{ minHeight: getterGetIsImpersonating ? 'calc(100vh - 108px)' : 'calc(100vh - 70px)' }">
        <DeviceCountCard class="device-count-card" :class="deviceCountCardClass" :data="deviceCount" :loading="loading" />
        <GroupUserInfoCard v-if="SuperAdminCheck || AdminCheck || OrgAdminCheck" :class="groupCountCardClass" title="Groups" :value="groupCount" :loading="loading" />
        <GroupUserInfoCard v-if="SuperAdminCheck" title="Users" :value="userCount" :loading="loading" />
        <DoughnutChartCard :chartData="models" title="Device Models" :loading="loading" />
        <HorizontalBarChartCard title="Firmware Version" :data="firmwareData" :loading="loading" />
        <BarChartCard :chartData="carriers" title="Device Carriers" dataUnit="Devices" :showBarLabel="true" :loading="loading" />
        <DoughnutChartCard :chartData="technologies" title="Technology" :loading="loading" />
        <BarChartCard class="usage-chart" :chartData="usage" dataUnit="Bytes" title="Data Usage: Last 7 Days" :showLegend="true" :loading="loading" />
      </div>
    </v-main>
  </div>
</template>

<script>
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mapGetters, mapMutations } from "vuex";

import DeviceCountCard from "@/components/Dashboard/DeviceCountCard.vue";
import HorizontalBarChartCard from "@/components/Dashboard/HorizontalBarChartCard.vue";
import DoughnutChartCard from "@/components/Dashboard/DoughnutChartCard.vue";
import BarChartCard from "@/components/Dashboard/BarChartCard.vue";
import GroupUserInfoCard from "@/components/Dashboard/GroupUserInfoCard.vue";

import DatePicker from "@/components/Usage/DatePicker.vue";

import { getDashboardData } from "@/services";

export default {
  mixins: [router_model_utils, mixin_routing_utils],
  components: {
    GroupUserInfoCard,
    DeviceCountCard,
    HorizontalBarChartCard,
    DoughnutChartCard,
    BarChartCard,
    DatePicker
  },
  data() {
    return {
      deviceCount: {
        total: 0,
        online: 0,
        offline: 0
      },
      groupCount: 0,
      userCount: 0,
      firmwareData: {},
      technologies: {},
      carriers: {},
      models: {},
      usage: {},
      loading: false,

      deviceCountCardClass: "",
      groupCountCardClass: ""
    };
  },
  mounted() {
    this.fetchDashboardData();
    this.observeContainer();
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetIsImpersonating"]),
    SuperAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";// Admin
    },
    AdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN";// Admin
    },
    OrgAdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN_1";//Org Admin
    },
    GroupManagerCheck() {
      return this.getterGetCurrentUserRole === "G_M_ADMIN";// Group Manager
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async fetchDashboardData() {
      this.loading = true;
      try{
        const response = await getDashboardData(this.getterGetUserInfo.user.user_id);
        this.deviceCount = { total: response.total, online: response.online, offline: response.offline };
        this.groupCount = this.SuperAdminCheck || this.AdminCheck || this.OrgAdminCheck ? response.groups : 0;
        this.userCount = this.SuperAdminCheck ? response.users : 0;
        this.carriers = response.carriers;
        this.firmwareData = response.firmware;
        this.models = response.models;
        this.technologies = response.technologies;
        this.usage = response.usage;
      } catch(error){
        this.triggerSnackBar("error", error);
      } finally {
        this.loading = false;
      }
    },
    observeContainer() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const width = entry.contentRect.width;

          if (width > 1450) {
            // device count card span change per user role
            if(this.SuperAdminCheck || this.AdminCheck || this.OrgAdminCheck) this.deviceCountCardClass = "device-count-card-span-2";
            else this.deviceCountCardClass = "device-count-card-span-4";
            
            if(this.AdminCheck || this.OrgAdminCheck) this.groupCountCardClass = "group-count-card-span-2";
            else this.groupCountCardClass = "";
          } else {
            this.deviceCountCardClass = "";
            this.groupCountCardClass = "";
          }
        }
      });
      this.resizeObserver.observe(this.$refs.container);
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1980px;
  margin-inline: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(350px, 1fr));
  background-color: #f7f7f7;
  gap: 10px;
}

.device-count-card-span-4 {
  grid-column: span 4;
}
.device-count-card-span-2 {
  grid-column: span 2;
}

.group-count-card-span-2 {
  grid-column: span 2;
}


.usage-chart {
  grid-column: span 4;
}

@media (max-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(2, minmax(350px, 1fr));
  }
  .usage-chart,
  .device-count-card {
    grid-column: span 2;
  }
}

@media (max-width: 800px) {
  .wrapper {
    grid-template-columns: minmax(350px, 1fr);
  }
  .usage-chart,
  .device-count-card {
    grid-column: span 1;
  }
}
</style>
