<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title>Admin Logs</v-toolbar-title>

        <v-select
          class="mt-6 ml-6"
          style="font-size: 12px; max-width: 150px"
          label="Filter By*"
          clearable
          :items="noOfDaysItems"
          item-text="text"
          item-value="value"
          v-model="filterParams"
          @input="validate_reports()"
          @click:clearable="validate_reports()"
          >
          <template #item="{ item }">
            <span class="filter-font"> {{ item.text }}</span>
          </template>
        </v-select>

        <v-menu
          v-if="filterParams == 'Custom'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="fromDate"
              class="ml-4 mt-6"
              label="From*"
              prepend-icon="mdi-calendar"
              outlined
              style="max-width: 140px"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="fromDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(fromDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          v-if="filterParams == 'Custom'"
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="toDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              class="ml-2 mt-6"
              v-model="toDate"
              label="To*"
              prepend-icon="mdi-calendar"
              style="max-width: 140px"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="toDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(toDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <!-- component for excel -->
        <vue-json-to-csv
          :labels="{
            created_on: { title: 'Created On' },
            log_command: { title: 'Type' },
            user_email_id: { title: 'Email' },
            log_message: { title: 'Message' },
            user_role: { title: 'Role' },
          }"
          csv-title="Admin Logs"
          :json-data="tableData"
          ><v-btn class="button ml-8" dark>Export</v-btn>
        </vue-json-to-csv>
        <v-spacer></v-spacer>

        <div class="container-wrapper">
          <v-text-field
                clearable
                append-icon="mdi-magnify blue--text"
                @click:clearable="clear_value"
                v-model="search"
                class="search-bar mr-1"
                label="Search"
                single-line
                style="max-width: 250px"
                @blur="search_deactive" 

              ></v-text-field>
        </div>


      
      </v-toolbar>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="tableData"
          :search="search"
          :items-per-page="itemsPerPage"
          :loading="loading2"
          class="elevation-1"
          dense
        >
          <template v-slot:header.created_on="{ header }">
            <span class="ml-2">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            <span class="caption ml-2">{{ convert_date(item.created_on) }}</span>
          </template>
          <template v-slot:header.created_on="{ header }">
              <span class="ml-2">
                {{ header.text }}
              </span>
            </template>
          <template v-slot:[`item.log_command`]="{ item }">
            <span class="caption">{{ item.log_command }}</span>
          </template>
          <template v-slot:[`item.user_role`]="{ item }">
            <span class="caption">{{ item.user_role }}</span>
          </template>
          <template v-slot:[`item.log_message`]="{ item }">
            <span class="caption">{{ item.log_message }}</span>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <span class="caption">{{ item.user_email_id }}</span>
          </template>
          <template v-slot:footer>
            <div class="footer-height">
              <LoadingIcon v-if="loadingIcon == true"
              style="position: absolute"
              class="ml-2 mt-2"></LoadingIcon>
              
              <span
                v-if="loadingIcon == true"
                style="position: absolute"
                class="ml-10 mt-5">
                Loading admin logs...
              </span>

              <span v-else>
                <v-icon
                    @click="refresh_list()"
                    class= "ml-1 mt-3 btn-fix"
                    dark
                    outline="none"
                    color="cyan"
                    style= "position:absolute; font-size: 34px"
                    id="refresh"
                  >
                    mdi-refresh
                </v-icon>
                <span 
                  style="position: absolute"
                  class="ml-11 pl-0 mt-5">
                  Last updated: {{ lastUpdated }} 
                </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-main>
  </div>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
import Styling from "/src/assets/Styling.css";
import { get_admin_logs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  components: {
    VueJsonToCsv,
    Styling,
    LoadingIcon,
  },
  data: () => ({
    tableData: [],
    actionButtonStatus: 0,
    search: "",
    excel: "",
    selectData: [],
    selected: [],
    selectDataForExport: [],
    noOfDaysItems: [
      { text: "Today", value: 1 },
      { text: "Last 3 Days", value: 3 },
      { text: "Last 7 Days", value: 7 },
      { text: "Last 15 Days", value: 15 },
      { text: "Custom", value: "Custom" },
    ],
    menu: false,
    toDate: "",
    fromDate: "",
    menu1: false,
    dropDown: "",
    filterParams: "",
    search: "",
    loading: false,
    eventtypeVal: "",
    devicemodVal: "",
    deviceidVal: "",
    items1: ["Device Model", "Event Type"],
    deviceidItems: ["FB1000", "EN1000", "EN2000"],
    deviceModelItems: ["EN1070", "EN1030", "EN1040"],
    eventTypeItems: [
      "Data Cap Alert",
      "Shutdown Cap Alert",
      "Firm Cap Alert",
      "Time Of Day Access Alert",
      "WAN Connection Alert",
    ],
    itemsPerPage: 10,
    loading2: false,
    headers: [
      {
        text: "Created On",
        sortable: true,
        class: "headerColor white--text ",
        value: "created_on",
      },
      {
        text: " Report Type",
        sortable: true,
        class: "headerColor white--text ",
        value: "log_command",
      },

      {
        text: "Email",
        sortable: true,
        class: "headerColor white--text ",
        value: "user_email_id",
      },
      {
        text: "Role",
        sortable: true,
        class: "headerColor white--text ",
        value: "user_role",
      },
      {
        text: "Log Message",
        sortable: true,
        class: "headerColor white--text ",
        value: "log_message",
      },
    ],
    searchActive: false,
    loadingIcon: true,
    startTime: null,
    lastUpdated: "Recently Updated"
  }),
  created() {
    this.startTime = Date.now();
    setInterval(() => {
      this.timeSince(this.startTime);
    }, 60001);
    this.get_admin_logs();
  },

  watch: {
    toDate() {
      if (this.fromDate != null && this.toDate != null) {
        this.validate_reports();
      }
    }
  },
  methods: {
    //method to validate for empty fields
    validate_reports() {
      var valid = true;
      if (
        this.filterParams != "Custom" &&
        (this.partnerName == "" || this.filterParams == null)
      ) {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields ",
          timeout: 5000,
          Top: true,
        };
      } else if (
        this.filterParams == "Custom" &&
        (this.fromDate == "" || this.toDate == "")
      ) {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields ",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        this.get_action_logs();
      }
    },

    //query call to fetch admin logs based on the filter option
    async get_action_logs() {
      this.loading2 = true;
      try {
        let result = await API.graphql(
          graphqlOperation(get_admin_logs, {
            input: {
              customer_id: this.$store.getters.getterGetUserInfo.user.customer_id,
              no_of_days: this.filterParams,
            },
          })
        );

        this.loading2 = false;

        var response = JSON.parse(result.data.get_admin_logs);
        this.tableData = response.data.Items;
      } catch (error) {
        console.log(error);
        this.loading2 = false;
        this.loading = false;
      }
    },

    //query call to fetch admin logs 
    async get_admin_logs() {
      this.loading2 = true;
      this.loadingIcon = true;
      try {
        let result = await API.graphql(
          graphqlOperation(get_admin_logs, {
            input: {
              customer_id: this.$store.getters.getterGetUserInfo.user.customer_id,
            },
          })
        );

        this.loadingIcon = false;
        this.loading2 = false;
        var response = JSON.parse(result.data.get_admin_logs);
        this.tableData = response.data.Items;
      } catch (error) {
        console.log(error);
        this.loading2 = false;
        this.loadingIcon = false;
        this.loading = false;
      }
    },
    timeSince(start) {
        var seconds = Math.floor((Date.now() - start) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) {
          this.lastUpdated = Math.floor(interval) + " years";
        } else {
          interval = seconds / 2592000;
          if (interval > 1) {
            this.lastUpdated = Math.floor(interval) + " months";
          } else {
            interval = seconds / 86400;
            if (interval > 1) {
              this.lastUpdated = Math.floor(interval) + " days";
            } else {
              interval = seconds / 3600;
              if (interval >= 1) {
                const hours = Math.floor(interval);
                const remainingMinutes = Math.ceil((seconds - hours * 3600) / 60);
                if (remainingMinutes > 0) {
                  this.lastUpdated = hours + (hours === 1 ? " hour " : " hours ") + remainingMinutes + (remainingMinutes === 1 ? " minute ago" : " minutes ago");
                } else {
                  this.lastUpdated = hours + (hours === 1 ? " hour ago" : " hours ago");
                }
              } else {
                interval = seconds / 60;
                if (interval >= 1) {
                  this.lastUpdated = Math.floor(interval) + (Math.floor(interval) === 1 ? " minute ago" : " minutes ago");
                } else {
                  this.lastUpdated = "Recently Updated";
                }
              }
            }
          }
        }
      },
    async refresh_list() {
      this.loadingIcon = true;
      this.startTime = Date.now();

      await this.get_admin_logs();
    },
    // Fuction to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(
          d.getDate()
        )}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },
    search_deactive() {
        if (this.search == null || this.search.length == 0) {
          this.search = "";
          this.searchActive = false;
        }
      },

    clear_value() {
      this.search = "";
    },

    get_log_message(message, userEmail) {
      if (message.contains("undefined")) {
        return message.replace(/undefined/g, userEmail);
      } else {
        return message;
      }
    }
  },
};
</script>

<style>
.button.v-btn {
  text-transform: none;
}
.v-input.search-bar {
    width: 400px;
    margin-top: 25px;
  }
.v-icon.magnify-btn {
  color: #0195D2;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
    border-color: #0195D2 !important;
    color: #0195D2 !important;
  }

  .v-application .search-bar >>> .mdi-close{
    color: grey !important
  }
</style>