import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
import Croppa from "vue-croppa";
import VueQRCodeComponent from "vue-qrcode-component";
import VueJsonToCsv from "vue-json-to-csv";
import * as VueGoogleMaps from "vue2-google-maps";
import { jsonDeepDiffList } from "json-deep-diff-list";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

import "@mdi/font/css/materialdesignicons.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const googleMapsObj = {
  load: {
    key: "AIzaSyBAva6xyebVkkBd_8AKJeI0Mfj1j-JvBK0",
    libraries: "places",
    installComponents: true,
  },
};

const developEndPoint = "https://twuhssvql5axhdygyoms4h4mo4.appsync-api.us-east-1.amazonaws.com/graphql";
const prodEndPoint = "https://we5gbhv7zbfllkkvqgumiyyvyy.appsync-api.us-east-2.amazonaws.com/graphql";

const developmentConfiguration = {
  Auth: {
    userPoolId: "us-east-1_kAFzhinS4",
    region: "us-east-1",
    userPoolWebClientId: "6kptuk66nfg46q1ciq3ebafom",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint: developEndPoint, //SAM managed
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
};

const productionConfiguration = {
  Auth: {
    userPoolId: "us-east-2_diXGVRlvy",
    region: "us-east-2",
    userPoolWebClientId: "622c01h5h5husptmaqpfqe65ti",
  },
  API: {
    aws_project_region: "us-east-2",
    aws_appsync_graphqlEndpoint: prodEndPoint, //SAM-managed
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
};

Vue.config.productionTip = false;
Vue.prototype.$diff = jsonDeepDiffList;
Vue.prototype.$doublejson = function doublejsonconvert(input) { return JSON.parse(JSON.stringify(input)) };
Vue.use(VueJsonToCsv);
Vue.use(require("vue-cookies"));
Vue.use(Croppa);
Vue.use(VueGoogleMaps, googleMapsObj);
Vue.component("qr-code", VueQRCodeComponent);

// 05-18-2023 Daniel : Seperated the Aplofy link for the development or production server.
if (process.env.VUE_APP_DEPLOY === "development") {
  // console.log("Running on the Development Server");
  Amplify.configure(developmentConfiguration);
} else {
  // console.log("Running on the Production Server");
  Amplify.configure(productionConfiguration);
}

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
