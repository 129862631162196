<template>
  <CardGroupSlot v-if="groupDiffs" :CardGroupTitle="'Router Admin'" :isPreview="isPreview">
    <template #body>
      <transition name="fade">
        <ListCard title="Router Account List" :listData="RouterAccountList" />
      </transition>
    </template>
  </CardGroupSlot>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import CardGroupSlot from "../CardComponents/CardGroupSlot.vue";
import SingleCard from "../CardComponents/SingleCard.vue";
import ListCard from "../CardComponents/ListCard.vue";

export default {
  mixins: [mixin_config_history_utils],
  components: {
    SingleCard,
    ListCard,
    CardGroupSlot
  },
  data() {
    return {
      RouterAccountList: [],
    };
  },
  props: {
    groupDiffs: Array,
    isPreview: Boolean
  },
  watch: {
    groupDiffs: {
      handler() {
        const tempRouterAccountList = this.groupDiffs.filter((item) => item.path.split(".")[3] === "RouterAccountList");
        this.RouterAccountList = this.mixinParseListData(tempRouterAccountList);
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
