const state = {
  progressBar: false,
  circularProgressLoader: false
}

const getters = {
  // This is used for both dashboard info and router count
  getterGetProgressBarState: (state) => {
      return state.progressBar;
  },
  getterGetCircularProgressState: (state) => {
    return state.circularProgressLoader;
  }
}

const mutations = {
  mutationOpenProgressBar: (state) => {
    state.progressBar = true;
  },
  mutationCloseProgressBar: (state) => {
    state.progressBar = false;
  },
  mutationOpenCircularProgressLoader: (state) => {
    state.circularProgressLoader = true;
  },
  mutationCloseCircularProgressLoader: (state) => {
    state.circularProgressLoader = false;
  }
}

export default {
  state,
  getters,
  mutations
}