<template>
  <!-- coponents to display the data -->
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-main>
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title>Licenses</v-toolbar-title>
        <v-btn class="button ml-6" v-if="$store.getters.getterGetCurrentUserRole != 'SP_ADMIN'" @click="add_dialog()" dark>Add License</v-btn>
        <vue-json-to-csv
          :labels="{
            name: { title: 'Name' },
            created: { title: 'Created On' },
            prices: { title: 'Pries' },
            id: { title: 'Product ID' },
          }"
          csv-title="Log List"
          :json-data="tableData"
        >
          <v-btn class="button ml-6" dark>Export</v-btn>
        </vue-json-to-csv>
        <v-spacer></v-spacer>
        <div class="container-wrapper">
          <v-text-field clearable append-icon="mdi-magnify blue--text" @click:clearable="clear_value" v-model="search" class="search-bar mr-1" label="Search" single-line style="max-width: 250px" @blur="search_deactive"></v-text-field>
        </div>
      </v-toolbar>
      <v-card-text>
        <v-data-table :headers="headers" :items="tableData" :search="search" :items-per-page="itemsPerPage" class="elevation-1 caption" :loading="loading" dense>

          <template v-slot:[`item.name`]="{ item }">
            <span class="ml-2">{{ item.product_sku }}</span>
          </template>
          
          <!-- <template v-slot:[`header.name`]="{ header }">
            <span class="ml-2">{{ header.text }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="ml-2">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.model`]="{ item }">
            <span> {{ item.prices[0].lookup_key.split("_")[0] }}</span>
          </template>
          <template v-slot:[`item.prices`]="{ item }">
            <div v-for="price in item.prices" :key="price.id">
              <b>{{ `${capitalizeFirstLetter(price.lookup_key.split("_")[2])}` }} -</b>
              {{ `${price.currency === "usd" ? price.unit_amount / 100 : price.unit_amount} ${price.currency} ` }}
            </div>
          </template> -->



          <!-- <template v-slot:[`item.created`]="{ item }">
            <span v-html="formatDateWithTime(item.created)"></span>
          </template> -->

          <!-- <template slot="item.Actions" slot-scope="props">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="edit_info(props.item, props.index)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="delete_item(props.item, props.index)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template> -->

          <template v-slot:footer>
            <div class="footer-height">
              <LoadingIcon v-if="loadingIcon == true" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>

              <span v-if="loadingIcon == true" style="position: absolute" class="ml-10 mt-5"> Loading licenses... </span>

              <span v-else>
                <v-icon @click="refresh_list()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                <span style="position: absolute" class="ml-11 pl-0 mt-5"> Last updated: {{ lastUpdated }} </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- components invokes on add action click -->
      <div v-if="addCheck == true">
        <LicenseAdd :tableData="tableData" :addlicense="addlicense" :productList="productList" v-on:errorMsg="error_info" @clicked="close_dailog"></LicenseAdd>
      </div>

      <!-- components invokes on edit action click -->
      <div v-if="editData == 1">
        <LicenseEdit :tableData="tableData" :editLicense="editLicense" :productList="productList" :editDetails="editDetails" v-on:errorMsg="error_info" @clicked="close_edit"></LicenseEdit>
      </div>

      <!-- delete dialog component -->
      <v-dialog persistent overflow-hidden max-width="400px" v-model="deleteDailog">
        <v-card class="cardBg">
          <v-card-title class="medium cardTitle">
            <span> Delete Record</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="deleteDailog = false">
              <v-icon color="iconCross">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="cardBg pt-6 justify-center"> Are you sure you want to delete {{ licesneName }} ? </v-card-text>
          <v-card-text class="cardBg pt-6 justify-center"> Are you sure you want to delete {{ licesneName }} ? </v-card-text>

          <v-card-actions class="button-corner">
            <v-spacer></v-spacer>
            <v-btn class="blue--text" outlined @click="deleteDailog = false">Cancel</v-btn>
            <v-btn @click="delete_data()" :loading="loadingDel" class="button tabledata--text mr-2">Delete</v-btn>
            <v-btn class="blue--text" outlined @click="deleteDailog = false">Cancel</v-btn>
            <v-btn @click="delete_data()" :loading="loadingDel" class="button tabledata--text mr-2">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>
<script>
import LicenseAdd from "@/components/SuperAdminAcces/Licenses/LicenseAdd.vue";
import LicenseEdit from "@/components/SuperAdminAcces/Licenses/LicenseEdit.vue";
import { API, graphqlOperation } from "aws-amplify";
import VueJsonToCsv from "vue-json-to-csv";
import Styling from "/src/assets/Styling.css";
import { delete_license_for_model } from "@/graphql/mutations.js";
import { get_all_license_for_model, get_product_list, get_all_product_model } from "@/graphql/queries.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SnackBar from "@/components/SnackBar.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { LexRuntime } from "aws-sdk";

export default {
  components: {
    VueJsonToCsv,
    Styling,
    LicenseAdd,
    SnackBar,
    LicenseEdit,
    LoadingIcon,
  },
  data: () => ({
    SnackBarComponent: {},
    selectData: [],
    addUserForm: "",
    orgName: "",
    search: "",
    dropDown: "",
    switch1: false,
    mobileNumber: "",
    mobileCcCode: "",
    timeZones: "",
    email: "",
    dropDown: "",
    licenseNameItems: [],
    dropDownItems: [
      { value: "router_model", text: "Model" },
      { value: "license_type", text: "License Type" },
      { value: "license_status", text: "License Status" },
      { value: "license_name", text: "License Name" },
    ],
    licenseTypeItems: [
      { value: "STANDARD", text: "Standard" },
      { value: "ADDON", text: "AddOn" },
    ],
    licenseStatusItems: [
      { value: "LICENSED", text: "Active" },
      { value: "EXPIRED", text: "Expired" },
    ],
    itemsPerPage: 10,
    tableData: [],
    loading: false,
    suppHeaders: [
      {
        text: "Model",
        sortable: true,
        value: "product_sku",
      },
      {
        text: "Created On",
        sortable: true,
        value: "created",
      },
      {
        text: "License Type",
        sortable: true,
        value: "license_type",
      },
      {
        text: "License Name",
        sortable: true,
        value: "license_name",
      },
    ],
    headers: [
      // {
      //   text: "Product Name",
      //   sortable: true,
      //   value: "name",
      //   class: "white--text",
      // },
      {
        text: "Model",
        sortable: true,
        value: "product_sku",
        class: "white--text",
      },
      {
        text: "Status",
        sortable: true,
        value: "product_status",
        class: "white--text",
      },
      // {
      //   text: "Prices",
      //   sortable: true,
      //   value: "prices",
      //   class: "white--text",
      // },
      // {
      //   text: "Product ID",
      //   sortable: true,
      //   value: "id",
      //   class: "white--text",
      // },
      // {
      //   text: "Created On",
      //   sortable: true,
      //   value: "created",
      //   class: "white--text",
      // },
    ],
    addlicense: false,
    addCheck: false,
    productList: [],
    editData: 0,
    editLicense: false,
    editDetails: {},
    deleteID: "",
    licesneName: "",
    deleteDailog: false,
    loadingDel: false,
    searchActive: false,
    loadingIcon: true,
    startTime: null,
    lastUpdated: "Recently Updated",
    lastUpdated: "Recently Updated",
  }),

  //methods invokes on page load
  created() {
    this.startTime = Date.now();
    setInterval(() => {
      this.timeSince(this.startTime);
    }, 60001);
    this.get_license_data();
    this.products();
  },
  computed: {
    ...mapGetters(["getterGetProductList"]),
    getProductInfo() {
      return this.getterGetProductList;
    },
  },
  methods: {
    ...mapActions(["actionGetProductList"]),
    //method to fetch the license details
    async get_license_data() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(get_all_license_for_model, {
            input: {
              license_status: "ACTIVE",
            },
          })
        );

        let response = JSON.parse(result.data.get_all_license_for_model);
        await this.actionGetProductList();
        const products = this.getterGetProductList.filter((product) => product.prices[0].lookup_key);
        this.tableData = products;
        this.loading = false;
        this.loadingIcon = false;
      } catch (error) {
        this.loading = false;
        this.loadingIcon = false;
      }
    },

    //method to fetch product details
    async products() {
      try {
        let result = await API.graphql(
          graphqlOperation(get_all_product_model, {
            input: {
              product_status: "ACTIVE",
            },
          })
        );
        var response = JSON.parse(result.data.get_all_product_model);

        var self = this;
        response.forEach((element) => {
          self.productList.push({
            label: element.product_sku,
            value: element.product_sku,
          });
        });
      } catch (error) {}
    },

    //method invokes on add action
    add_dialog() {
      var data = 0;
      this.addCheck = true;
      this.addlicense = true;
    },

    //method invokes on edit action
    edit_info(val) {
      this.editDetails = val;
      this.editData = 1;
      this.editLicense = true;
    },

    //method invokes on delete action
    delete_item(item) {
      this.licesneName = item.license_name;
      this.deleteID = item.license_id;
      this.deleteDailog = true;
    },

    //method invokes on delete confirmation
    async delete_data() {
      this.loadingDel = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_license_for_model, {
            input: {
              license_id: this.deleteID,
            },
          })
        );
        this.loadingDel = false;
        this.get_license_data();
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Deleted Successfully",
          timeout: 5000,
          Top: true,
        };
        this.deleteDailog = false;
      } catch (error) {}
    },

    //method invokes on close add popup
    close_dailog(val) {
      if (val == 0) {
        this.addlicense = false;
      } else if (val == 1) {
        this.addlicense = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Created Successfully",
          timeout: 5000,
          Top: true,
        };
        this.get_license_data();
      }
    },

    //method invokes on close edit popup
    close_edit(val) {
      if (val == 0) {
        this.editLicense = false;
      } else if (val == 1) {
        this.editLicense = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Updated Successfully",
          timeout: 5000,
          Top: true,
        };
        this.get_license_data();
      }
    },
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },

    //method to display the error messages
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.addlicense = false;
      this.editLicense = false;
    },
    search_deactive() {
      if (this.search == null || this.search.length == 0) {
        this.search = "";
        this.searchActive = false;
      }
    },

    clear_value() {
      this.search = "";
    },
    timeSince(start) {
      var seconds = Math.floor((Date.now() - start) / 1000);
      var interval = seconds / 31536000;
      var seconds = Math.floor((Date.now() - start) / 1000);
      var interval = seconds / 31536000;

      if (interval > 1) {
        this.lastUpdated = Math.floor(interval) + " years";
      } else {
        interval = seconds / 2592000;
        if (interval > 1) {
          this.lastUpdated = Math.floor(interval) + " months";
        } else {
          interval = seconds / 86400;
          if (interval > 1) {
            this.lastUpdated = Math.floor(interval) + " days";
          } else {
            interval = seconds / 3600;
            if (interval >= 1) {
              const hours = Math.floor(interval);
              const remainingMinutes = Math.ceil((seconds - hours * 3600) / 60);
              if (remainingMinutes > 0) {
                this.lastUpdated = hours + (hours === 1 ? " hour " : " hours ") + remainingMinutes + (remainingMinutes === 1 ? " minute ago" : " minutes ago");
              } else {
                this.lastUpdated = hours + (hours === 1 ? " hour ago" : " hours ago");
              }
            } else {
              interval = seconds / 60;
              if (interval >= 1) {
                this.lastUpdated = Math.floor(interval) + (Math.floor(interval) === 1 ? " minute ago" : " minutes ago");
              } else {
                this.lastUpdated = "Recently Updated";
              }
            }
          }
        }
      }
    },
    async refresh_list() {
      this.loadingIcon = true;
      this.startTime = Date.now();
      await this.get_license_data();
      await this.products();
    },
    formatDateWithTime(dateString) {
      // Create a new Date object from the input date string
      const endDate = new Date(dateString * 1000);
      // Extract the month, day, and year from the Date object
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
      const day = String(endDate.getDate()).padStart(2, "0");
      const year = endDate.getFullYear();
      // Extract the hours, minutes, and seconds from the Date object
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      // Return the formatted date string in MM/DD/YYYY HH:MM:SS format
      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    },
    capitalizeFirstLetter(str) {
      return str.substring(0, 1).toUpperCase() + str.substring(1);
    },
  },
};
</script>
<style>
.button.v-btn {
  text-transform: none;
}
.v-input.search-bar {
  width: 400px;
  margin-top: 25px;
}
.v-icon.magnify-btn {
  color: #0195d2;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}

.v-application .search-bar >>> .mdi-close {
  color: grey !important;
}
</style>
