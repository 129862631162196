import { API, graphqlOperation } from "aws-amplify";
import { list_all_organization, get_all_product_model } from "@/graphql";

// Another query without any regular response for empty list
// Remove error checking
export const getOrganizationList = async (organizationDetails) => {
  try {
    const result = await API.graphql(
      graphqlOperation(list_all_organization, {
        input: organizationDetails,
      })
    );
    const response = organizationDetails.organization_status === "ALL" ? JSON.parse(result.data.list_all_organization).organizationDetails.Items : JSON.parse(result.data.list_all_organization).Active_organization;
    return response;
  } catch (err) {
    // console.error("Error fetching organization list", err);
    // throw err;
  }
};

export const getProductList = async () => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_all_product_model, {
        input: {
          product_status: "ACTIVE",
        },
      })
    );
    const response = JSON.parse(result.data.get_all_product_model);
    return response;
  } catch (err) {
    console.error("Error fetching product details", err);
    throw err;
  }
};

