var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:(_vm.cssVars),attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Latest Location")]),_c('v-card-text',[(Object.keys(this.deviceLocation).length)?_c('div',[_c('GmapMap',{ref:"map",staticClass:"device-map",attrs:{"center":_vm.center,"zoom":15,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
      }}},[_c('GmapMarker',{attrs:{"position":_vm.center,"clickable":false,"draggable":false,"title":_vm.deviceLocation.latitude + ', ' + _vm.deviceLocation.longitude}})],1),_c('p',{staticClass:"address-display"},[_vm._v(_vm._s(_vm.address))]),_c('div',{ref:"result",staticClass:"d-none"}),_c('p',{staticClass:"last-updated-display",attrs:{"hidden":!(_vm.deviceLocation.lastUpdated)}},[_vm._v(" Last Updated: "+_vm._s(_vm.deviceLocation.lastUpdated)+" ")])],1):_c('div',[_c('div',{staticClass:"no-gps-panel"},[_c('svg',{attrs:{"width":"69","height":"85","viewBox":"0 0 69 85","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M34.5031 41.8979C36.7211 41.8979 38.6173 41.1081 40.1919 39.5283C41.7672 37.9486 42.5548 36.0497 42.5548 33.8317C42.5548 31.6137 41.7649 29.717 40.1852 28.1418C38.6055 26.5672 36.7062 25.7799 34.4874 25.7799C32.2694 25.7799 30.3732 26.5698 28.7986 28.1496C27.2233 29.7293 26.4357 31.6285 26.4357 33.8473C26.4357 36.0653 27.2256 37.9616 28.8053 39.5361C30.385 41.1107 32.2843 41.8979 34.5031 41.8979ZM34.4953 75.9964C43.2172 68.1884 49.8917 60.6991 54.5187 53.5286C59.1458 46.3581 61.4593 40.0778 61.4593 34.6877C61.4593 26.5602 58.8771 19.8786 53.7129 14.643C48.5487 9.40746 42.1428 6.78968 34.4953 6.78968C26.8477 6.78968 20.4418 9.40746 15.2776 14.643C10.1134 19.8786 7.53125 26.5602 7.53125 34.6877C7.53125 40.0778 9.84475 46.3581 14.4718 53.5286C19.0988 60.6991 25.7733 68.1884 34.4953 75.9964ZM34.4953 84.8952C23.2751 75.1731 14.8615 66.1253 9.2544 57.7518C3.6473 49.3775 0.84375 41.6895 0.84375 34.6877C0.84375 24.3994 4.17152 16.0701 10.8271 9.69985C17.4834 3.32964 25.3728 0.144531 34.4953 0.144531C43.6177 0.144531 51.5071 3.32964 58.1634 9.69985C64.819 16.0701 68.1468 24.3994 68.1468 34.6877C68.1468 41.6895 65.3432 49.3775 59.7361 57.7518C54.129 66.1253 45.7154 75.1731 34.4953 84.8952Z","fill":"#BEBFC2"}})]),_c('div',{staticClass:"no-gps-text"},[_vm._v("No GPS Data")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }