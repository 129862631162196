<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon small @click="toggleTimeFormat = !toggleTimeFormat">
        <v-icon>mdi-clock-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="mb-4 schedule-graph-container">
        <!-- day labels on the side -->
        <div class="day-labels">
          <div :style="`height: 4rem;`"></div>
          <div class="day-label" :style="`height: ${unitHeight}px;`" v-for="day in daysList" :key="day">
            <div>{{ day }}</div>
            <div class="grey--text text--lighten-1 text-caption font-italic">{{ hoursEachDay[day].toFixed(1) }} hours</div>
          </div>
        </div>
        <!-- Graph hour lables + the schedule graph -->
        <div class="schedule">
          <!-- Hour labels -->
          <div class="schedule-time">
            <div :style="`width: ${unitWidth}px; height: 4rem;`" v-for="k in hoursList" :key="`hour-${k}`">{{ convertScheduleLabelTimeFormat(k) }}</div>
          </div>
          <!-- Graph -->
          <div class="schedule-graph" ref="scheduleGraph">
            <div class="schedule-graph-row" v-for="i in 7" :key="`row-${i}`">
              <div class="hour-block" :class="{ 'left-border': j === 0, 'top-border': i === 1 }" :style="`width: ${unitWidth}px; height: ${unitHeight}px;`" v-for="j in hoursList" :key="`hour-${j}`"></div>
            </div>

            <!-- Schedule Items that are generated dynamically -->
            <div class="schedule-item" v-for="schedule in localizedScheduleList" :key="`${schedule.top}${schedule.left}`" :style="`top: ${schedule.top}px; left: ${schedule.left}px; width: ${schedule.width}px; height: ${0.8*unitHeight}px; border: 1px solid ${schedule.color} !important;`" :class="`${schedule.color} lighten-3`">
              <div>
                <div :class="`mx-2 ${schedule.color}--text text--darken-1 schedule-item-caption`">Allowed</div>
                <div :class="`mx-2 ${schedule.color}--text text--darken-1 schedule-item-time`">
                  {{ convertScheduleItemTimeFormat(schedule.startTime) }} ~ {{ convertScheduleItemTimeFormat(schedule.endTime) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    ScheduleList: Array
  },
  data() {
    return {
      // Used for drawing the initial graph
      unitHeight: 60,
      unitWidth: 180,
      hoursList: [],
      daysList: [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun" ],
      toggleTimeFormat: false,

      // Variables used for placing schedules on the graph
      localizedScheduleList: [],
      hoursEachDay: {}
    };
  },
  watch: {
    ScheduleList: {
      handler(newVal){
        let tempScheduleList = newVal.map(schedule => ({...schedule}));
        this.convertTime(tempScheduleList);
        let dayMap = this.convertSchedules(tempScheduleList);
        this.generateLocalizedSchedules(dayMap);
      },
      immediate: true,
      deep: true
    }
  },
  created(){
    for(let i = 0; i < 24; i++){
      this.hoursList.push(i);
    }
  },
  methods: {
    convertSchedules(ScheduleList) {
      let dayMap = { Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: [] };

      ScheduleList.forEach(schedule => {
        let daysOfWeek = schedule.DayOfWeek === "All" ? "All" : schedule.DayOfWeek.split(",");
        if(daysOfWeek === "All") {
          for(let day in dayMap) {
            dayMap[day].push(schedule);
          }
        } else {
          daysOfWeek.forEach(day => {
            dayMap[day].push(schedule);
          })
        }
      })
      return dayMap
    },
    // If StartTime and EndTime of the ScheduleList is integers as is the case for RG2100, convert it into "HHMM" format
    convertTime(ScheduleList) {
      ScheduleList.forEach(schedule => {
        if(typeof schedule.StartTime === "number") {
          schedule.StartTime = `0${schedule.StartTime}00`.slice(-4);
        }
        if(typeof schedule.EndTime === "number") {
          schedule.EndTime = `0${schedule.EndTime}00`.slice(-4);
        }
      })
    },
    generateLocalizedSchedules(dayMap){
      const tempScheduleList = [];
      let aggregateHours = { Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0 }
      const dayMultiplier = { Mon: 0, Tue: 1, Wed: 2, Thu: 3, Fri: 4, Sat: 5, Sun: 6 };
      // { Mon: [{ Endtime: "0100", StartTime: "0200", "ScheduleName: '', Index: 1 " }], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: [] };
      for(let day in dayMap) {
        dayMap[day].forEach(schedule => {
          // read EndTime and StartTime
          // Calculate the position and width of the schedule item that is to be placed on the graph
          // And push it into localizedScheduleList for reactive dynamic vue rendering in v-for loop
          const startTimeInMinutes =  this.convertTimeInMinutes(schedule.StartTime);
          const endTimeInMinutes = this.convertTimeInMinutes(schedule.EndTime);
          const pixelsPerMinute = this.unitWidth / 60;

          aggregateHours[day] += (endTimeInMinutes - startTimeInMinutes)/60;

          // Calculate position within the graph
          const topPosition = (dayMultiplier[day] * this.unitHeight) + (this.unitHeight * 0.1);
          const leftPosition = startTimeInMinutes * pixelsPerMinute;
          const width = (endTimeInMinutes * pixelsPerMinute) - (startTimeInMinutes * pixelsPerMinute);
          const color = this.getColorByIndex(schedule.Index);

          const scheduleData = {
            index: schedule.Index,
            days: schedule.DayOfWeek,
            startTime: schedule.StartTime,
            endTime: schedule.EndTime,
            top: topPosition,
            left: leftPosition,
            width: width,
            color: color
          }
          tempScheduleList.push(scheduleData);
        })
      }
      this.hoursEachDay = aggregateHours;
      this.localizedScheduleList = tempScheduleList;
    },
    // takes in time in HHMM format and converts it into minutes integer value
    convertTimeInMinutes(timeString){
      let minutes = parseInt(timeString.slice(2, 4));
      let hoursToMinutes = parseInt(timeString.slice(0, 2)) * 60;
      return minutes + hoursToMinutes;
    },
    getColorByIndex(number) {
      const colors = [ 'red', 'blue', 'purple', 'orange', 'gray', 'pink' ];
      const index = Math.abs(number) % 6;
      return colors[index];
    },
    convertScheduleLabelTimeFormat(val) {
      let formattedTime;
      let hours = val;
      let AMorPM = "am"

      if(this.toggleTimeFormat){
        formattedTime = `${val}:00`;
      } else {
        if(hours > 12) {
          hours = hours - 12;
          AMorPM = "pm";
        }
        formattedTime = `${hours}:00 ${AMorPM}`;
      }
      return formattedTime;
    },
    convertScheduleItemTimeFormat(time) {
      let formattedTime;
      let hours = parseInt(time.slice(0,2));
      let AMorPM = "am"

      if(this.toggleTimeFormat){
        formattedTime = `${time.slice(0,2)}:${time.slice(2,4)}`;
      } else {
        if(hours > 12) {
          hours = hours - 12;
          AMorPM = "pm";
        }
        formattedTime = `${hours}:${time.slice(2,4)} ${AMorPM}`;
      }
      return formattedTime;
    }
  }
};
</script>

<style scoped>
.day-labels {
  padding-block: 1rem;
}
.day-label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.schedule-graph-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
}
/* Max width must be set to a huge number that will never be reached for the following reasons */
/* 1. The graph must fit anywhere and only take up as much space is allowed by its parent horizontally */
/* 2. Any overflow that it has must be scrollable horizontally */
.schedule {
  max-width: 10000px;
  overflow-x: auto;
  padding: 1rem;
}
/* All schedule objects will be posted here */
.schedule-graph {
  position: relative;
  width: 100%;
  min-width: 0;
}
.schedule-time {
  display: flex;
  min-width: fit-content;
}
.schedule-graph-row {
  display: flex;
  min-width: fit-content;
}
/* Draw borders on the hour block */
.hour-block {
  border-bottom: 1px solid rgb(212, 212, 212);
  border-right: 1px solid rgb(212, 212, 212);
}
/* Draw borders in respect to their positions */
.top-border {
  border-top: 1px solid rgb(212, 212, 212);
}
.left-border {
  border-left: 1px solid rgb(212, 212, 212);
}
/* Schedule items dynamically generated on the graph */
.schedule-item {
  position: absolute;
  display: flex;
  align-items: flex-end;
}
.schedule-item > * {
  padding: 0.1rem 0;
}
.schedule-item-caption {
  font-size: 0.75rem;
}
.schedule-item-time {
  font-size: 1rem;
}
</style>
