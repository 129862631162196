<template>
  <v-card flat>
    <v-card-title> Wi-Fi Details </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead >
            <tr class="wifi-header">
              <th>Type</th>
              <th>Status</th>
              <th>SSID</th>
              <th>Password</th>
              <th>Wi-Fi Band</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Main Wi-Fi</td>
              <td>
                <span class="statusOnline--text">Enabled</span>
              </td>
              <td>{{ mainWifiName }}</td>
              <td>{{ mainWifiPassword }}</td>
              <td>{{ mainWifiRadioBand }}</td>
            </tr>
            <tr>
              <td>Guest Wi-Fi</td>
              <td>
                <span v-if="guestWifiIsEnabled" class="statusOnline--text">Enabled</span>
                <span v-else class="statusOffline--text">Disabled</span>
              </td>
              <td>{{ guestWifiName }}</td>
              <td>{{ guestWifiPassword }}</td>
              <td>{{ guestWifiRadioBand }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapGetters } from 'vuex';
import { router_model_utils } from '@/mixins/routerModelUtils';

export default {
  mixins: [router_model_utils],
  computed: {
    ...mapGetters(['getterGetDeviceConfigurations']),
    getWirelessLANSettings() {
      return this.getterGetDeviceConfigurations.configuration_module.WirelessLAN ? this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value : false;
    }, 
    mainWifiName() {
      return this.getWirelessLANSettings ? this.getWirelessLANSettings.MainWiFi.WiFiName : "-";
    }, 
    guestWifiName() {
      return (this.guestWifiIsEnabled && this.getWirelessLANSettings) ? this.getWirelessLANSettings.Guest3WiFi.WiFiName : "-";
    },
    mainWifiPassword() {
      return this.getWirelessLANSettings ? this.getWirelessLANSettings.MainWiFi.WiFiPassword : "-";
    }, 
    guestWifiPassword() {
      return (this.guestWifiIsEnabled && this.getWirelessLANSettings) ? this.getWirelessLANSettings.Guest3WiFi.WiFiPassword : "-";
    },
    mainWifiRadioBand() {
      return this.getWirelessLANSettings ? this.convert_wifiBand(this.getWirelessLANSettings.MainWiFi.RadioBand) : "-";
    },
    guestWifiRadioBand() {
      return (this.guestWifiIsEnabled && this.getWirelessLANSettings) ? this.convert_wifiBand(this.getWirelessLANSettings.Guest3WiFi.RadioBand) : "-";
    },
    guestWifiIsEnabled() {
      return this.getWirelessLANSettings ? this.getWirelessLANSettings.Guest3WiFiEnable : false;
    },
  },

};
</script>

<style>
.wifi-header {
  background-color: #F1F1F1;
}

.wifi-header th {
  color: black !important;
}
</style>