<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">Data Usage</h3>

    <div class="d-flex">
      <div class="pt-5" style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('Usage', 'UsageCycle')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-radio-group ref="UsageCycle" v-model="UsageCycle" row label="Data Cycle Resets" @change="mixinUpdateFormField('dataUsage.UsageCycle', `${configPath}.UsageCycle`, UsageCycle)">
        <v-radio label="Monthly" :value="0"></v-radio>
        <v-radio label="Yearly" :value="1"></v-radio>
      </v-radio-group>
    </div>

    
    <div class="switch-boxes">
      <v-fade-transition>
        <v-select v-show="UsageCycle === 0" ref="CycleStartDayofMonth" class="form-text-box" outlined dense label="Day of Month" :items="mixinGetMonthDayList()" v-model="CycleStartDayofMonth" @input="mixinUpdateFormField('dataUsage.CycleStartDayofMonth', `${configPath}.CycleStartDayofMonth`, CycleStartDayofMonth)">
          <template v-if="mixinCheckGroupConfigChange('Usage', 'CycleStartDayofMonth')" slot="prepend">
            <UnchangedGroupConfigIcon />
          </template>
        </v-select>
      </v-fade-transition>
      <v-fade-transition>
        <div v-show="UsageCycle === 1" class="form-text-box form-two-column-grid">
          <v-select ref="CycleStartDayofYear" class="form-text-box" outlined dense label="Month" :items="mixinGetYearMonthList()" v-model="CycleStartDayofYear_Month" @input="updateCycleStartDayofYear()">
            <template v-if="mixinCheckGroupConfigChange('Usage', 'CycleStartDayofYear')" slot="prepend">
              <UnchangedGroupConfigIcon />
            </template>
          </v-select>
          <v-select ref="CycleStartDayofYear" class="form-text-box" outlined dense label="Day" :items="mixinGetYearDayList(CycleStartDayofYear_Month)" v-model="CycleStartDayofYear_Day" @input="updateCycleStartDayofYear()">
            <template v-if="mixinCheckGroupConfigChange('Usage', 'CycleStartDayofYear')" slot="prepend">
              <UnchangedGroupConfigIcon />
            </template>
          </v-select>
        </div>
      </v-fade-transition>
    </div>

    <div>
      <v-checkbox v-model="MonthlyLimit_Check" class="mt-n1 d-inline-block">
        <template v-slot:label>
          <div>Unlimited Usage</div>
        </template>
      </v-checkbox>
    </div>
    
    <div class="form-text-box form-two-column-grid">
      <v-text-field ref="MonthlyLimit_Data" class="form-text-box" outlined dense label="Usage" v-model.number="MonthlyLimit_Data" :rules="!MonthlyLimit_Check ? mixinUsageLimitRule() : []" suffix="GB" :disabled="MonthlyLimit_Check" @keypress="mixinNumberOnlyKeyPress($event)" maxlength="3" @blur="mixinUpdateFormField('dataUsage.MonthlyLimit_Data', `${configPath}.MonthlyLimit`, (MonthlyLimit_Data * 1024))">
        <template v-if="mixinCheckGroupConfigChange('Usage', 'MonthlyLimit')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-text-field>
      
      <div class="d-flex">
        <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('Usage', 'MonthlyLimitAlert')">
          <UnchangedGroupConfigIcon />
        </div>
        <v-switch ref="MonthlyLimitAlert" class="form-text-box" dense v-model="MonthlyLimitAlert" hide-details :disabled="MonthlyLimit_Check" @click="mixinUpdateFormField('dataUsage.MonthlyLimitAlert', `${configPath}.MonthlyLimitAlert`, MonthlyLimitAlert ? 1 : 0)">
          <template v-slot:label>
            <div>Monthly Limit Alert</div>
          </template>
        </v-switch>
      </div>
    </div>

  </v-form>

</template>

<script>
import { mapGetters } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import { mixin_time_utils } from '@/mixins/timeUtils.js';
import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils, mixin_time_utils],
  components: {
    UnchangedGroupConfigIcon,
  },
  data() {
    return {
      UsageCycle: -1,
      CycleStartDayofMonth: 1,
      CycleStartDayofYear_Month: "",
      CycleStartDayofYear_Day: "",
      isInitialized: false,
      MonthlyLimit_Check: false,
      MonthlyLimit_Data: 0,
      MonthlyLimitAlert: false,

      configPath: "configuration_module.Usage.Value",
    };
  },
  created() {
    this.initUsage();
  },
  watch: {
    // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
    "$store.state.newDeviceConfigurations": {
      handler() {
        this.initUsage();
      },
      deep: true,
    },
    getterGetCurrentError: {
      handler(newVal){
        if(newVal && newVal.path){
          let errorMenu = newVal.path.split('.')[1];
          this.$nextTick(() => {
            if(this.$refs[errorMenu]){
              // This small delay will make sure that the cursor gets focused in correctly
              setTimeout(() => {
                this.$refs[errorMenu].focus();
              }, 500)
            }
          })
        }
      },
      immediate: true,
      deep: true
    },
    UsageCycle: {
      handler(newVal){
        if(this.isInitialized) {
          if(newVal === 0) {
            this.CycleStartDayofMonth = 1;
            this.mixinUpdateFormField('dataUsage.CycleStartDayofMonth', `${this.configPath}.CycleStartDayofMonth`, this.CycleStartDayofMonth);
            this.mixinUpdateFormField('dataUsage.CycleStartDayofYear', `${this.configPath}.CycleStartDayofYear`, "", true);
          }
          if(newVal === 1) {
            this.CycleStartDayofYear_Month = "01";
            this.CycleStartDayofYear_Day = "01";
            this.mixinUpdateFormField('dataUsage.CycleStartDayofYear', `${this.configPath}.CycleStartDayofYear`, this.CycleStartDayofYear_Month + this.CycleStartDayofYear_Day);
            this.mixinUpdateFormField('dataUsage.CycleStartDayofMonth', `${this.configPath}.CycleStartDayofMonth`, "", true);
          }
        }
      },
      immediate: false
    },

    MonthlyLimit_Check: {
      handler(newVal){
        if(this.isInitialized) {
          if(newVal) {
            this.MonthlyLimit_Data = 0;
            this.MonthlyLimitAlert = false;
            this.$nextTick(() => {
              this.mixinUpdateFormField('dataUsage.MonthlyLimit_Data', `${this.configPath}.MonthlyLimit`, 0);
              this.mixinUpdateFormField('dataUsage.MonthlyLimitAlert', `${this.configPath}.MonthlyLimitAlert`, this.MonthlyLimitAlert ? 1 : 0);
            })
          } else {
            this.MonthlyLimit_Data = 1;
            this.MonthlyLimitAlert = true;
            this.$nextTick(() => {
              this.mixinUpdateFormField('dataUsage.MonthlyLimit_Data', `${this.configPath}.MonthlyLimit`, this.MonthlyLimit_Data * 1024);
              this.mixinUpdateFormField('dataUsage.MonthlyLimitAlert', `${this.configPath}.MonthlyLimitAlert`, this.MonthlyLimitAlert ? 1 : 0);
            })
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError"]),
  },
  methods: {
    initUsage() {
      const defaultUsage = this.getterGetNewDeviceConfigurations.configuration_module.Usage.Value;
      this.UsageCycle = defaultUsage.hasOwnProperty("UsageCycle") ? defaultUsage.UsageCycle : -1;

      // This is here because someone made a error, changing the data that is supposed to be a string to a number
      if (this.UsageCycle == 1) {
        if(defaultUsage.hasOwnProperty("CycleStartDayofYear")) {
          const cycleStartDayofYear = typeof defaultUsage.CycleStartDayofYear === "number" ? this.mixinConvertIntDateToString(defaultUsage.CycleStartDayofYear) : defaultUsage.CycleStartDayofYear;
          this.CycleStartDayofYear_Month = cycleStartDayofYear.substring(0, 2);
          this.CycleStartDayofYear_Day = cycleStartDayofYear.substring(2, 4);
        }
      } else {
        this.CycleStartDayofMonth = defaultUsage.hasOwnProperty("CycleStartDayofMonth") ? defaultUsage.CycleStartDayofMonth : null;
      }

      this.MonthlyLimit_Check = defaultUsage.hasOwnProperty("MonthlyLimit") && defaultUsage.MonthlyLimit === 0 ?  true :  false;
      this.MonthlyLimit_Data = defaultUsage.hasOwnProperty("MonthlyLimit") && defaultUsage.MonthlyLimit > 0 ? defaultUsage.MonthlyLimit / 1024 : 0;
      this.MonthlyLimitAlert = defaultUsage.hasOwnProperty("MonthlyLimitAlert") ? (defaultUsage.MonthlyLimitAlert ? true : false) : false;

      // Make sure that the UsageCycle watcher only starts after the data has been initialized and rendered to DOM
      this.$nextTick(() => {
        this.isInitialized = true;
        this.mixinValidateForm('dhcpRange', 'dhcpRange');
      });
    },
    updateCycleStartDayofYear() {
      const CycleStartDayofYear = this.CycleStartDayofYear_Month + this.CycleStartDayofYear_Day;
      this.mixinUpdateFormField('dataUsage.CycleStartDayofYear', `${this.configPath}.CycleStartDayofYear`, CycleStartDayofYear);
    }
  },
};
</script>

<style scoped>
  .switch-boxes{
    position: relative;
    height: 66px;
  }
  .switch-boxes > * {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .form-two-column-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
</style>