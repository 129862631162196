export const router_group_list = /* GraphQL */ `
  query Router_group_list($input: RouterGroupListInput) {
    router_group_list(input: $input)
  }
`;

export const sub_router_group_list = /* GraphQL */ `
  query Router_group_list($input: SubRouterGroupListInput) {
    sub_router_group_list(input: $input)
  }
`;

export const get_router_for_specific_router_group = /* GraphQL */ `
  query Get_router_for_specific_router_group(
    $input: GetRouterForSpecificRouterGroupInput
  ) {
    get_router_for_specific_router_group(input: $input)
  }
`;

export const get_grouped_and_non_grouped_router = /* GraphQL */ `
  query Get_grouped_and_non_grouped_router(
    $input: GetGroupedAndNonGroupedRouterInput
  ) {
    get_grouped_and_non_grouped_router(input: $input)
  }
`;

export const get_organization_router_group = /* GraphQL */ `
  query Get_organization_router_group($input: GetOrganizationRouterGroupInput) {
    get_organization_router_group(input: $input)
  }
`;