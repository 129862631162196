<template>
  <v-app>
    <!-- All in one snackbar-->
    <div v-for="item in getSnackBarItems" :key="item.id">
      <SnackBarModal :snackBarProperties="item" />
    </div>
    <ProgressBar :progressBarDialog="getProgressBarState"/>
    <CircularProgress />
    <router-view></router-view>
  </v-app>
</template>
<script>
import Style from "/src/assets/Styling.css";
import { mapGetters } from "vuex";
import SnackBarModal from "@/components/GlobalComponents/SnackBarModal.vue";
import ProgressBar from "./components/GlobalComponents/ProgressBar.vue";
import CircularProgress from "./components/GlobalComponents/CircularProgress.vue";

export default {
  name: "App",
  components: {
    Style,
    SnackBarModal,
    ProgressBar,
    CircularProgress
  },
  computed: {
    ...mapGetters(["getterGetSnackBarItems", "getterGetProgressBarState"]),
    getSnackBarItems() {
      return this.getterGetSnackBarItems;
    },
    getProgressBarState() {
      return this.getterGetProgressBarState
    }
  }
};
</script>
