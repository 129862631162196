<template>
  <v-app>
    <v-main>
      <div class="login-page-layout">
        <div class="login-left-layout">
          <v-img max-width="240px" src="@/assets/jextream-log-mdm.png" class="my-6"></v-img>
          <v-card width="90%" max-width="550px">
            <v-card-text>
              <p class="login-title-text grey--text text--darken-2">Login</p>
              <v-window v-model="windowPage">
                <v-window-item :value="'Login'">
                  <LoginWindow/>
                </v-window-item>
                <v-window-item :value="'Forgot-Password'">
                  <ForgotPasswordWindow/>
                </v-window-item>
                <v-window-item :value="'Forgot-Password-Submit'">
                  <ForgotPasswordSubmitWindow/>
                </v-window-item>
                <v-window-item :value="'New-Password'">
                  <ResetPasswordWindow/>
                </v-window-item>
                <v-window-item :value="'MFA'">
                  <MFAWindow/>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
          <BottomImage/>
        </div>
        <RightImage/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex"; 

import BottomImage from "@/components/Login/BottomImage.vue";
import LoginWindow from "@/components/Login/LoginWindow";
import ForgotPasswordWindow from "@/components/Login/ForgotPasswordWindow.vue";
import ForgotPasswordSubmitWindow from "@/components/Login/ForgotPasswordSubmitWindow.vue";
import ResetPasswordWindow from "@/components/Login/ResetPasswordWindow.vue";
import MFAWindow from "@/components/Login/MFAWindow.vue";

import RightImage from "@/components/Login/RightImage.vue";

export default {
  components: {
    BottomImage,
    LoginWindow,
    ForgotPasswordWindow,
    ForgotPasswordSubmitWindow,
    ResetPasswordWindow,
    MFAWindow,
    RightImage,
  },
  computed: {
    ...mapGetters([ "getterGetLoginWindowPage" ]),
    windowPage: {
      get() {
        return this.getterGetLoginWindowPage;
      }
    }
  }
};
</script>

<style scoped>
.login-title-text {
  margin: auto;
  text-align: center;
  font-weight: 500;
  font-size:  1rem;
}
.login-page-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1600px;
  height: 100%;
  margin-inline: auto;
}

.login-left-layout {
  display: grid;
  justify-items: center;
  align-content: center;
}

@media (max-width: 1200px) {
  .login-page-layout {
    grid-template-columns: 1fr;
  }
}
</style>
