<template>
  <div>
    <v-dialog v-model="delPermanently" persistent overflow-hidden max-width="400px">
      <v-card class="dialogCard cardBg overflow-hidden">
        <v-toolbar class="dialogToolbar cardBg" dense>
          <v-toolbar-title>Delete User</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon @click="close_dialog()" class="ml-2" color="iconCross">mdi-close</v-icon>
        </v-toolbar>

        <v-card-text class="cardBg pt-6 justify-center">
          Are you sure you want to delete <b> {{ delUserName }}</b> permanently?
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn class="blue--text" outlined @click="close_dialog()">Cancel</v-btn>
          <v-btn class="button tabledata--text mr-2" :loading="loadingDelete" @click="delete_permanently()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { delete_user_permanently } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { mapMutations } from "vuex";
export default {
  props: {
    delUserName: String,
    userID: String,
    delPermanently: Boolean,
  },
  data() {
    return {
      loadingDelete: false,
    };
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async delete_permanently() {
      this.loadingDelete = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_permanently, {
            input: {
              user_id: this.userID,
            },
          })
        );
        var data = JSON.parse(result.data.delete_user_permanently);
        var msg = data.Message;
        if (msg == "User as the active router, please delete the router before deleting the user!!") {
          this.$emit("clicked", 6);
          this.$emit("routersIDs", data.router_ids);
        } else {
          this.$emit("clicked", 5);
        }
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.loadingDelete = false;
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>
