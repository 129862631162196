<template>
  <v-card flat class="parent-card">
    <v-card-title class="d-flex justify-space-between" style="flex-wrap: nowrap;">
      <span>{{ title }}</span>
      <div v-if="showLegend" class="legend-container">
        <div class="inline-container" v-for="(item, index) in seriesData" :key="title + index">
          <div class="legend-item">
            <div class="legend-color" :style="{ backgroundColor: option.color[index] }"></div>
            <div class="legend-label">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="chart-container">
        <!-- Chart -->
        <v-chart ref="chart" class="chart mb-2" :option="option" :autoresize="true" @dataZoom="handleDataZoom" />
        <!-- Left Indicator -->
        <v-fade-transition>
          <div v-if="!reachedLeftEnd" class="edge-indicator left" @click="handleLeftClick">⬅</div>
        </v-fade-transition>
        <!-- Right Indicator -->
        <v-fade-transition>
          <div v-if="!reachedRightEnd" class="edge-indicator right" @click="handleRightClick">➡</div>
        </v-fade-transition>
      </div>
    </v-card-text>
    <v-fade-transition>
      <div v-if="loading" class="load-cover">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
    </v-fade-transition>
  </v-card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GraphicComponent, GridComponent, DataZoomComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GraphicComponent, GridComponent, DataZoomComponent]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    title: { type: String, default: "" },
    showBarLabel: { type: Boolean, default: false },
    showLegend: { type: Boolean, default: false },
    dataUnit: { type: String, default: "" },
    chartData: { type: Object, default: {} },
    loading: { type: Boolean, default: false }
  },
  watch: {
    zoomStart: {
      handler(newStart){
        this.reachedLeftEnd = newStart === 0;
      },
      immediate: true
    },
    zoomEnd: {
      handler(newEnd){
        this.reachedRightEnd = newEnd === 100;
      },
      immediate: true
    }
  },
  data() {
    return {
      dataLabels: [],
      seriesData: [],
      barWidth: 20,
      xAxisWidth: 140,

      zoomStart: 0,
      zoomEnd: 50,

      reachedLeftEnd: false,
      reachedRightEnd: false,
    };
  },
  mounted() {
    this.handleResize(); // Resize immediately
    this.adjustVisibleItems(); // Run once on mount

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.adjustVisibleItems); // Update on resize
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.adjustVisibleItems);
  },
  computed: {
    option() {
      this.generateSeriesData();
      let tooltip = {
        trigger: "item",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderWidth: 0,
        padding: [5, 8],
        textStyle: {
          color: "white",
          fontSize: 12,
          fontFamily: "Roboto",
          lineHeight: 18,
        },
        formatter: (params) => {
          return `
            <div class="d-flex align-center mb-2">
              <span class="mr-1" style="background-color: ${params.color}; display: inline-block; height: 0.75rem; aspect-ratio: 1; border-radius: 50%;"></span>${params.seriesName}
            </div>
            <div class="d-flex justify-end align-baseline">
              <span class="mr-1" style="font-size: 1.2rem; font-weight: 600">${params.value}</span> ${this.dataUnit}
            </div>`;
        },
        extraCssText: "min-width: 130px",
      };

      this.handleResize();
      this.adjustVisibleItems();

      return {
        title: { show: false },
        legend: { show: false },
        tooltip,
        grid: { left: 100, top: "10%", right: 0, bottom: "10%" },
        xAxis: {
          type: "category",
          data: this.dataLabels,
          axisLine: { show: true, lineStyle: { color: "#E0DFE0", type: "solid" } },
          axisTick: { show: false },
          axisLabel: { interval: 0, rotate: 0, margin: 10, color: "#000" },
        },
        yAxis: {
          type: "value",
          scale: true,
          boundaryGap: [0, 0.2],
          min: 0,
          splitLine: { lineStyle: { type: "dashed" } },
        },
        color: ["#598FF9", "#9BBCFB", "#6C61EA", "#B5AFF4", "#D71C7A", "#E777AF", "#FA5918", "#FC9B74", "#FCAB2E", "#FDD496"],
        series: this.seriesData,
        barWidth: this.barWidth,
        barGap: "5%",
        barCategoryGap: "30%",
        graphic: { type: "text", left: "center", top: "middle", style: { text: this.middleText, fontSize: 16, fontWeight: "bold", fill: "#333" } },
        // type: Hides the zoom UI, enables only panning
        // start: Show the first 50% of the data
        // end: Adjust as needed for initial view
        // zoomLock: Disable zooming (only allows left/right movement)
        // xAxisIndex: Apply panning to x-axis only
        dataZoom: [{ type: "inside", start: this.zoomStart, end: this.zoomEnd, zoomLock: true, xAxisIndex: [0] }],
      };
    },
  },
  methods: {
    generateSeriesData() {
      this.seriesData = [];
      this.dataLabels = [];
      const parsedData = Object.entries(this.chartData);
      if (parsedData.some((item) => typeof item[1] === "object" && item !== null)) {
        parsedData.forEach((item, index) => {
          const dataName = item[0];
          const dataValue = item[1];
          // get x-axis label list first
          if (index === 0) this.dataLabels = Object.keys(dataValue);
          const currentData = {
            name: dataName,
            type: "bar",
            label: { show: this.showBarLabel, position: "top", fontSize: 16, fontWeight: "bold", color: "#000" },
            labelLine: { show: false },
            data: Object.values(dataValue),
          };
          this.seriesData.push(currentData);
        });
      } else {
        this.dataLabels = parsedData.map((item) => item[0]);
        this.seriesData = [
          {
            name: this.title,
            type: "bar",
            label: { show: this.showBarLabel, position: "top", fontSize: 16, fontWeight: "bold", color: "#000" },
            labelLine: { show: false },
            data: parsedData.map((item) => item[1]),
          },
        ];
      }
    },
    handleResize() {
      if (this.$refs.chart) {
        this.$refs.chart.resize();
      }
    },
    adjustVisibleItems() {
      if (!this.$refs.chart) return;
      const chartWidth = this.$refs.chart.$el.clientWidth;
      // Approximate width needed per item (adjust as needed)
      let itemWidth = this.xAxisWidth + this.barWidth * this.seriesData.length;
      this.visibleItems = Math.max(1, Math.floor(chartWidth / itemWidth)); // Min 1 item visible

      // Update `dataZoom.end` dynamically
      let percentage = (this.visibleItems / this.dataLabels.length) * 100;
      this.zoomEnd = Math.min(100, percentage); // Ensure it doesn't exceed 100%
    },
    handleDataZoom(params) {
      const dataZoom = params.batch ? params.batch[0] : params; // Handle batch events
      const start = dataZoom.start; // Start percentage
      const end = dataZoom.end; // End percentage

      // Check if the user reached the left or right end
      this.reachedLeftEnd = start === 0;
      this.reachedRightEnd = end === 100;
    },
    handleRightClick() {
    if (!this.$refs.chart?.chart) return;
      const chartInstance = this.$refs.chart.chart;
      const dataZoom = chartInstance.getOption().dataZoom[0];
      const unitPercentage = 100 / this.dataLabels.length;

      const start = Math.min(100 - unitPercentage * this.visibleItems, dataZoom.start + unitPercentage);
      const end = Math.min(dataZoom.end + unitPercentage, 100);

      chartInstance.dispatchAction({ type: "dataZoom", start, end });
    },
    handleLeftClick() {
    if (!this.$refs.chart?.chart) return;
      const chartInstance = this.$refs.chart.chart;
      const dataZoom = chartInstance.getOption().dataZoom[0];
      const unitPercentage = 100 / this.dataLabels.length;

      const start = Math.max(0, dataZoom.start - unitPercentage);
      const end = Math.max(dataZoom.end - unitPercentage, unitPercentage * this.visibleItems);

      chartInstance.dispatchAction({ type: "dataZoom", start, end });
    }
  },
};
</script>

<style scoped>
.chart {
  min-height: 300px;
}
.parent-card {
  min-width: fit-content;
  border: solid 1px #e5e5ea;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.legend-container {
  display: flex;
  align-items: center;
  overflow: auto;
  height: 65px;
  margin-inline: 5%;
}

.legend-item {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.legend-color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border-radius: 0.5rem;
}

.legend-label {
  font-size: 0.75rem;
  width: fit-content;
}

.edge-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}
.left { left: 10px; }
.right { right: 10px; }

.load-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  display: grid;
  place-items: center;
}
</style>
